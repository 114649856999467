import React from 'react';
import { Button, Header, Segment } from 'semantic-ui-react';
import BF from '../../bfcore/bfconst1';

const segmentStyle = {
  float: 'right',
  opacity: 0.86,
};


export default class GuiVictory extends React.Component {
  state = {
    open: true,
    game: {},
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.
    this.state.game = props.game;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.game !== this.state.game) {
      this.setState({
        store: nextProps.game,
      });
    }
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  handleOpen = (e) => {
    e.stopPropagation();
    this.setState({ open: true })
  }

  handleClose = (e) => {
    e.stopPropagation();
    this.setState({ open: false })
  }

  render() {
    const { open, game } = this.state;

    if(!game._id || !game.replay || !game.replay.victory ) {
      // Wait for all game data to load before attempting to render any victory stuff.
      return null;
    }

    if( !open ) {
      return (
        <div key='VB' onPointerDown={this.stopPropagation} style={{float: 'right'}} >
          <Button color='black' style={{ opacity: 0.86 }} onPointerUp={ this.handleOpen }>
            Show Victory
          </Button>
          <br />
        </div>
      );
    }

    const { reason = 0, winner = [], winnerTeam = 0 } = game.replay.victory;

    let reasonRender = [];
    if(reason & BF.VICTORY_GAME_CANCELLED ) {
      reasonRender.push(<p key='r1'>Game was canceled.</p>);
    }
    if(reason & BF.VICTORY_TURN_LIMIT ) {
      reasonRender.push(<p key='r2'>Maximum game turns was reached.</p>);
    }
    if(reason & BF.VICTORY_TIME_LIMIT ) {
      reasonRender.push(<p key='r4'>Maximum game time was reached.</p>);
    }
    if(reason & BF.VICTORY_AUTOPLAY_LIMIT ) {
      reasonRender.push(<p key='r8'>All human players autoplayed consecutively too many times.</p>);
    }
    if(reason & BF.VICTORY_NO_HUMANS_LEFT ) {
      reasonRender.push(<p key='r16'>All human players have been eliminated.</p>);
    }
    if(reason & BF.VICTORY_CAPITALS ) {
      reasonRender.push(<p key='r32'>Early victory condition of captured capitals was met.</p>);
    }
    if(reason & BF.VICTORY_ZONES ) {
      reasonRender.push(<p key='r64'>Early victory condition of zones controlled was met.</p>);
    }
    if(reason & BF.VICTORY_INCOME ) {
      reasonRender.push(<p key='r128'>Early victory condition of income was met.</p>);
    }
    if(reason & BF.VICTORY_CASH ) {
      reasonRender.push(<p key='r256'>Early victory condition of cash was met.</p>);
    }
    if(reason & BF.VICTORY_ELIMINATION ) {
      reasonRender.push(<p key='r512'>All opponents have been eliminated.</p>);
    }

    let winnersRender = 'There were no absolute victors.';
    let title = 'Game Over';
    if(winnerTeam) {
      title = 'Team Victory!!!';
      winnersRender = 'Victorious Team: ' + game.teamName[winnerTeam] + ' (';
      for(let i = 0; i < winner.length; i++) {
        winnersRender += ' ' + game.playerName[winner[i]];
      }
      winnersRender += ' )';
    }
    else if(winner.length > 0) {
      title = 'Victory!!!';
      if(winner.length === 1) {
        winnersRender = 'Winner:';
      }
      else {
        winnersRender = 'Winners:';
      }
      for(let i = 0; i < winner.length; i++) {
        winnersRender += ' ' + game.playerName[winner[i]];
      }
    }

    return (
      <Segment inverted compact style={ segmentStyle }
          onPointerDown={ this.stopPropagation }
          onPointerUp={ this.handleClose }
      >
        <Header>{ title }</Header>
        { reasonRender }
        <p key='wr'>{ winnersRender }</p>
      </Segment>
    );
  }
}
