import React from 'react';
import actions from '../../reducers/actions';
import { Button, Dropdown, Flag, Step } from 'semantic-ui-react';
import { store } from '../../app/store';
import GuiForce from './GuiForce';
import BF from '../../bfcore/bfconst1';

const MAX_INCOME = 20;
const MAX_STARTING_FORCES = 100;

function GuiScenarioEditZoneLine (props) {
  const { enabled, forceTypeIndex, game, scenarioEdit, zoneID } = props;

  function plus1 (e) {
    const startingForces = [...scenarioEdit.startingForces[zoneID]];

    if(game.forceFlags[forceTypeIndex] & BF.FORCE_FLAG_PROD_MAX_ONE_PER_ZONE) {
      startingForces[forceTypeIndex] = 1;
    }
    else {
      if(startingForces[forceTypeIndex] < MAX_STARTING_FORCES) {
        startingForces[forceTypeIndex]++;
      }
    }

    store.dispatch({
      type: actions.SCENARIO_EDIT_STARTING_FORCES,
      game,
      scenarioEdit,
      zoneID,
      startingForces,
    });
  };

  function minus1 (e) {
    const startingForces = [...scenarioEdit.startingForces[zoneID]];

    if(startingForces[forceTypeIndex] > 0) {
      startingForces[forceTypeIndex]--;
    }

    store.dispatch({
      type: actions.SCENARIO_EDIT_STARTING_FORCES,
      game,
      scenarioEdit,
      zoneID,
      startingForces,
    });
  };

  return (
    <div>
      <b>{ scenarioEdit.startingForces[zoneID][forceTypeIndex] }</b>&nbsp;&nbsp;
      <GuiForce forceType={ scenarioEdit.forceID[forceTypeIndex] } />&nbsp;
      { enabled &&
        <Button key='minus1' onPointerUp={ minus1 } size="mini" compact>
          &nbsp;&nbsp;&nbsp;-&nbsp;1&nbsp;&nbsp;&nbsp;
        </Button>
      }
      { enabled &&
        <Button key='plus1' onPointerUp={ plus1 } size="mini" compact>
          &nbsp;&nbsp;&nbsp;+&nbsp;1&nbsp;&nbsp;&nbsp;
        </Button>
      }
    </div>
  );
}

export default function GuiScenarioEditZone (props) {
  const { game, scenarioEdit, zoneID } = props;

  function stopPropagation (e) {
    e.stopPropagation();
  }

  function navyZoneChange (e, { value }) {
    store.dispatch({
      type: actions.SCENARIO_EDIT_NAVY_ZONE,
      game,
      scenarioEdit,
      zoneID,
      navyZone: value,
    });
  }

  function ownerChange (e, { value }) {
    store.dispatch({
      type: actions.SCENARIO_EDIT_ZONE_OWNER,
      game,
      scenarioEdit,
      zoneID,
      owner: value,
    });
  }

  function incomeChange (e, { value }) {
    store.dispatch({
      type: actions.SCENARIO_EDIT_ZONE_INCOME,
      game,
      scenarioEdit,
      zoneID,
      income: value,
    });
  }

  if(!(game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE)) {
    return (
      <Step.Group onPointerDown={ stopPropagation }>
        <Step>
          <Step.Content>
            <Step.Title>{ game.zoneName[zoneID] }</Step.Title>
            <Step.Description>
              Water Zone<br />
            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }

  const buyLine = [];
  for(let f = 0; f < game.forceID.length; f++) {
    let enabled = false;
    if((game.forceFlags[f] & BF.FORCE_FLAG_STARTS_ON_LAND) ||
        ((game.forceFlags[f] & BF.FORCE_FLAG_STARTS_ON_WATER) && scenarioEdit.navyZone[zoneID]) ) {
      enabled = true;
    }
    buyLine.push(
      <GuiScenarioEditZoneLine key={ 'f' + f } forceTypeIndex={ f } game={ game }
                               zoneID={ zoneID } scenarioEdit={ scenarioEdit }
                               enabled={ enabled }
      />
    );
  }

  const navyZoneOptions = [{ key: 0, text: 'None', value: 0 }];
  for(let b = 0; b < game.bordersWater[zoneID].length; b++) {
    const z = game.bordersWater[zoneID][b];
    let available = true;
    for(let z2 = 1; z2 <= scenarioEdit.zones; z2++) {
      if(z2 !== zoneID && scenarioEdit.navyZone[z2] === z) {
        available = false;
        break;
      }
    }
    if(available) {
      navyZoneOptions.push( { key: z, text: game.zoneName[z], value: z } );
    }
  }

  const ownerOptions = [{ key: 0, text: 'Picking Round', value: 0 }];
  for(let p = 1; p <= scenarioEdit.players; p++) {
    ownerOptions.push({
     key: p,
     text:<label>
            <Flag name={ scenarioEdit.playerFlag[p] } />
            { scenarioEdit.playerName[p] ?
              scenarioEdit.playerName[p] :
              ('Player' + p)
            }
          </label>,
     value: p,
    });
  }

  const incomeOptions = [];
  for(let i = 0; i <= MAX_INCOME; i++) {
    incomeOptions.push({
      key: i, text: <b>&nbsp;&nbsp;&nbsp;{ i }&nbsp;&nbsp;&nbsp;</b>, value: i,
    });
  }

  return (
    <Step.Group onPointerDown={ stopPropagation }>
      <Step>
        <Step.Content>
          <Step.Title>{ game.zoneName[zoneID] }</Step.Title>
          <Step.Description>
            Income:&nbsp;&nbsp;
            <Dropdown
              onChange={ incomeChange }
              options={ incomeOptions }
              placeholder='Select'
              selection
              value={ scenarioEdit.income[zoneID] }
            />
            <br />

            Starting Owner:&nbsp;&nbsp;
            <Dropdown
              onChange={ ownerChange }
              options={ ownerOptions }
              placeholder='Select'
              selection
              value={ scenarioEdit.owner[zoneID] }
            />
            <br />

            { buyLine }
            <br />

            Navy Zone:&nbsp;&nbsp;
            <Dropdown
              onChange={ navyZoneChange }
              options={ navyZoneOptions }
              placeholder='Select'
              selection
              value={ scenarioEdit.navyZone[zoneID] }
            />
          </Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}
