/*
  BaseScenarioDetails.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, List } from 'semantic-ui-react';

import BaseAdSense from './BaseAdSense';
import BaseCamoBackScroll from './BaseCamoBackScroll';
import BaseLeaderboardPlayerPlays from './BaseLeaderboardPlayerPlays';
import BaseMenuBasic from './BaseMenuBasic';
import BaseScenarioDetailStartGame from './BaseScenarioDetailStartGame';
import BaseScenarioDetailCreate from './BaseScenarioDetailCreate';
import GuiForce from '../GameGui/GuiForce';
import actions from '../../reducers/actions';
import { store } from '../../app/store';
import mainModes from '../../reducers/main-modes'
import BF from '../../bfcore/bfconst1';
import { sendPacket } from '../../network/network';
import { userCanCreateScenario, userCanCreateSpecificGame } from '../../helper/user';
import { linkText } from '../../helper/style';
import { log } from '../../helper/log';

import { loadHitmapIntoCache } from '../../map/hitmapCache';
import { createGamePreviewFromScenario } from '../../helper/scenario';


function secondsToString (s) {
  if( s === 600 )
    return '10 minutes (Extremely Fast!)';
  if( s === 1800 )
    return '30 minutes (Very Fast!)';
  if( s === 3600 )
    return '1 hour (Fast)';
  if( s === 7200 )
    return '2 hours (Fast)';
  if( s === 10800 )
    return '3 hours (Fast)';
  if( s === 14400 )
    return '4 hours (Fast)';
  if( s === 21600 )
    return '6 hours (Fast)';
  if( s === 43200 )
    return '12 hours (Fast)';
  if( s === 24*3600 )
    return '24 hours (Normal)';
  if( s === 25*3600 )
    return '25 hours (Standard)';
  if( s === 48*3600 )
    return '48 hours (Slow)';
  if( s === 7*24*3600 )
    return '7 days (Slowest)';
  if( s === 14*24*3600 )
    return '14 days (Lots of Time)';
  return s + ' seconds';
}

function secondsToDurationString (s) {
  let str = '';
  if(s >= 48*3600) {
    str += Math.floor(s / (24*3600)) + ' days ';
    s -= Math.floor(s / (24*3600)) * (24*3600);
  }
  else if(s >= 24*3600) {
    str += '1 day ';
    s -= 24*3600;
  }

  if(s >= 2*3600) {
    str += Math.floor(s / 3600) + ' hours ';
    s -= Math.floor(s / 3600) * 3600;
  }
  else if(s >= 3600) {
    str += '1 hour ';
    s -= 3600;
  }

  if(s >= 2*60) {
    str += Math.floor(s / 60) + ' minutes ';
    s -= Math.floor(s / 60) * 60;
  }
  else if(s >= 60) {
    str += '1 minute ';
    s -= 60;
  }

  if(s > 0) {
    str += s + ' seconds.';
  }

  return str;
}

export default class BaseScenarioDetail extends React.Component {
  state = {
    store: {},
    showStartGameOptions: false,
    showCreateScenarioOptions: false,
    forceNew: true,
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.
    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store !== this.state.store) {
      this.setState({
        store: nextProps.store,
        showStartGameOptions: false,
        showCreateScenarioOptions: false,
        forceNew: true,
      });
    }
  }

  closeStartGame = () => {
    this.setState({ showStartGameOptions: false });
  }

  closeCreateScenario = () => {
    this.setState({ showCreateScenarioOptions: false });
  }

  upgradeRequired = (body) => {
    store.dispatch({
      type: actions.NOTIFY_MODAL_PUSH,
      title: 'Membership Upgrade Required',
      body,
    });
    store.dispatch({type: actions.NAVIGATE_TO, where: {mode: mainModes.MEMBERSHIP_OPTIONS}});
  }

  startNewGame = (e) => {
    e.stopPropagation();
    const { membershipLevel, scenarioID, scenario } = this.state.store;

    if(!userCanCreateSpecificGame(scenario)) {
      this.upgradeRequired('To start a game of this scenario, please upgrade your membership.');
      return;
    }

    if(scenarioID && scenario._id === scenarioID) {
      if(scenario.humanPlayers === 1) {
        // Start solo game.
        sendPacket('bf_gameCreate', {scenarioID, flags: 0});
      }
      else {
        // Start multiplayer game, show options first.
        if(membershipLevel < 2) {
          // Must be at least a Premium Member.
          this.upgradeRequired('To start a game of this scenario, please upgrade your membership.');
          return;
        }
        this.setState({
          showStartGameOptions: true,
        });
      }
    }
  };

  previewScenario = (e) => {
    e.stopPropagation();
    const { map, scenarioID, scenario, userSession } = this.state.store;
    const { uid, uName } = userSession;

    const mapMeta = map[scenario.mapID];
    if(scenarioID && scenario._id === scenarioID && mapMeta) {
      loadHitmapIntoCache(mapMeta._id, mapMeta.flags, mapMeta.xs, mapMeta.ys, mapMeta.mapData,
                          function (err) {
        if(err) {
          log('Hitmap loading for map failed.');
          store.dispatch({
            type: actions.NOTIFY_MODAL_PUSH,
            title: 'Load Scenario Error',
            body: 'Hitmap loading for map failed.',
          });
          // if(store.getState().mainMode === mainModes.GAME_LOADING) {
          //   store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.GAME_LOADING_ERROR});
          // }
          return;
        }

        const game = createGamePreviewFromScenario(scenario, mapMeta, uid, uName, true);

        const localPlayerIndex = 0;
        store.dispatch({type: actions.SET_GAME, game, localPlayerIndex});
        const gameID = game._id;

        store.dispatch({
          type: actions.NAVIGATE_TO,
          where: {mode: mainModes.SCENARIO_PREVIEW, gameID}
        });
      });
    }
  };

  createNewScenario = (e) => {
    e.stopPropagation();

    if(!userCanCreateScenario()) {
      this.upgradeRequired('To create your own scenarios, please upgrade your membership.');
      return;
    }

    this.setState({ showCreateScenarioOptions: true, forceNew: true });
  };

  editScenario = (e) => {
    e.stopPropagation();

    if(!userCanCreateScenario()) {
      this.upgradeRequired('To create your own scenarios, please upgrade your membership.');
      return;
    }

    const { scenario, userSession } = this.state.store;

    if(scenario.gameFlags & BF.GAME_FLAG_LOCKED) {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'This Scenario is Locked to prevent changes.',
        body: 'This scenario has already been used for a scenario or game so it can no longer be modified. To make a variation of this scenario, create a new scenario based off of this scenario instead.',
      });
      return;
    }

    if(scenario.uid !== userSession.uid) {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Only the creator of a scenario may edit it.',
        body: 'Create a new scenario based off of this scenario instead.',
      });
      return;
    }

    this.setState({ showCreateScenarioOptions: true, forceNew: false });
  };

  mapTitle() {
    const mapName = this.state.store.scenario.mapName;
    const mapID = this.state.store.scenario.mapID;
    return(
      <Link to={'/map/' + mapID}>
        <span style={ linkText } >
          { mapName }
        </span>
      </Link>
    );
  }

  mapPlayerTitle() {
    const userName = this.state.store.scenario.mapUName;
    const playerID = this.state.store.scenario.mapUID;
    return(
      <Link to={'/player/' + playerID}>
        <span style={ linkText } >
          { userName }
        </span>
      </Link>
    );
  }

  scenarioPlayerTitle() {
    const userName = this.state.store.scenario.uName;
    const playerID = this.state.store.scenario.uid;
    return(
      <Link to={'/player/' + playerID}>
        <span style={ linkText } >
          { userName }
        </span>
      </Link>
    );
  }

  renderScenarioDetailsHTML (scenario) {
    if(!scenario || !scenario._id) {
      return null;
    }
    let tempStr;
    let s = [<h5 key='div0'>Scenario Details</h5>];

    s.push(
      <div key='div1'>
        Created by: { this.scenarioPlayerTitle() }
      </div>
    );

    s.push(
      <div key='div2'>
        Map: { this.mapTitle() } by: { this.mapPlayerTitle() }
      </div>
    );

    s.push(
      <div key='div3'>
        Zones: { scenario.zones } ( { scenario.zonesLand } land, { scenario.zonesWater } water)
      </div>
    );

    s.push(
      <div key='div4'>
        Total Income: { scenario.incomeTotal }
      </div>
    );

    let forceImages = [];
    if( Array.isArray(scenario.forceID) ) {
      for(let f = 0; f < scenario.forceID.length; f++) {
        forceImages.push(<GuiForce key={'fk' + f} forceType={scenario.forceID[f]} />);
      }
    }
    s.push(
      <div key='div5' className='flex flex-row'>
        Forces:
        <div className='flex flex-row'>
          {tempStr}
          {forceImages}
        </div>
      </div>
    );
    s.push(
      <div key='div6'>
        Players: { scenario.players } ( { scenario.humanPlayers } human, { scenario.computerPlayers } computer)
      </div>
    );

    if(scenario.gameFlags & BF.GAME_FLAG_LOSE_CAPITAL_LOSE)
      tempStr = 'Immediate elimination if capital is lost.';
    else if(scenario.gameFlags & BF.GAME_FLAG_MUST_RECAPTURE_CAPITAL)
      tempStr = 'Players must retake their lost capital or be eliminated.';
    else tempStr = 'Players are not eliminated until all their forces are destroyed.';
    s.push(
      <div key='div7'>
        { tempStr }
      </div>
    );
    if( scenario.timeTurn ) {
      s.push(
        <div key='div8'>
          Turn Time Limit: { secondsToString(scenario.timeTurn) }
        </div>
      );
    }
    if(scenario.maxDuration)
    s.push(
      <div key='div9'>
        Max Duration: { secondsToDurationString(scenario.maxDuration) }
      </div>
    );

    if(scenario.maxTurns) {
      s.push(
        <div key='div10'>
          Max Turns: { scenario.maxTurns }
        </div>
      );
    }

    if(scenario.victoryCapitals > 0 || scenario.victoryLandZones > 0 ||
        scenario.victoryIncome > 0 || scenario.victoryCash > 0) {
      s.push(
        <div key='div11a'>Early Victory Conditions:</div>
      );
      if( scenario.victoryCapitals > 0 ) {
        s.push(
          <div key='div11b'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Capitals: { scenario.victoryCapitals }
          </div>
        );
      }
      if( scenario.victoryLandZones > 0 ) {
        s.push(
          <div key='div11c'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Land Zones: { scenario.victoryLandZones }
          </div>
        );
      }
      if( scenario.victoryIncome > 0 ) {
        s.push(
          <div key='div11d'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Income: { scenario.victoryIncome }
          </div>
        );
      }
      if( scenario.victoryCash > 0 ) {
        s.push(
          <div key='div11e'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Cash: { scenario.victoryCash }
          </div>
        );
      }
    }
    else {
      s.push(<div key='div11'>No Early Victory Conditions.</div>);
    }
    if(scenario.gameFlags & BF.GAME_FLAG_FACTORY_AT_CAPITAL) {
      s.push(
        <div key='div12'>All player capitals will automatically start with a factory.</div>
      );
    }
    if(scenario.gameFlags & BF.GAME_FLAG_DO_NOT_SCORE) {
      s.push(
        <div key='div13'>Non-Scoring game.  It will not affect player scores.</div>
      );
    }
    if( scenario.state !== BF.GAME_STATE_REGISTERING &&
        !(scenario.gameFlags & BF.GAME_FLAG_CAN_CHOOSE_PLAYER_INDEX) ) {
      s.push(
        <div key='div14'>Player slots are randomly reordered when the game starts.</div>
      );
    }
    return s;
  };

  render() {
    const { forceNew, showCreateScenarioOptions, showStartGameOptions, store } = this.state;
    const { leaderboardScenarioPlays, playerStrings, scenario, scenarioID, userSession } = store;
    const { membershipLevel } = userSession.bf;

    if(!scenario || scenario._id !== scenarioID) {
      return (
        <BaseCamoBackScroll>
          <BaseMenuBasic pageName={ 'Loading...' } leftText={ 'Loading...' } />
          <center>
            <Grid centered columns={1} style={{ padding: '6px' }}>
              <Grid.Column>Loading...</Grid.Column>
            </Grid>
            <br /><br /><br />
            <br /><br /><br />
          </center>
        </BaseCamoBackScroll>
      );
    }

    const { name, description } = scenario;

    const leaderboard = [];
    if(leaderboardScenarioPlays.scenarioID === scenarioID &&
        Array.isArray(leaderboardScenarioPlays.data)) {
      for(let i = 0; i < leaderboardScenarioPlays.data.length / 2; i++) {
        const uid = leaderboardScenarioPlays.data[i * 2];
        leaderboard.push(<BaseLeaderboardPlayerPlays
                           key={ i + 1 }
                           rank={ i + 1 }
                           uid={ uid }
                           plays={ leaderboardScenarioPlays.data[i * 2 + 1] }
                           playerString={ playerStrings[uid] } />);
      }
    }

    return (
      <BaseCamoBackScroll>
        <BaseMenuBasic pageName={ name } leftText={ '#' + scenarioID } />
        <center>
          <Grid centered columns={ 2 } style={{ padding: '6px' }}>
            <Grid.Column>
              <br /><br /><BaseAdSense />
              <h3>{ name }</h3>
              <h5>{ description }</h5>
              <Button color='green' size='large' onPointerUp={ this.previewScenario } >
                Preview<br />this Scenario
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button color='green' size='huge' onPointerUp={ this.startNewGame } >
                Start a New Game<br />of this Scenario
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button color='olive' size='small' onPointerUp={ this.createNewScenario } >
                Create a New Scenario<br />based on this Scenario
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button color='olive' size='small' onPointerUp={ this.editScenario } >
                Edit<br />this Scenario
              </Button>
            </Grid.Column>
            <Grid.Column style={{ border: '1px solid #666' }}>
              { this.renderScenarioDetailsHTML(scenario) }
            </Grid.Column>
            { leaderboard.length > 0 &&
              <Grid.Column style={{ border: '1px solid #666' }}>
                <h4>Most Active Players for this Scenario</h4>
                <List selection>
                  { leaderboard }
                </List>
              </Grid.Column>
            }
          </Grid>
          <br /><br /><br />
          <br /><br /><br />
        </center>
        { showStartGameOptions &&
          <BaseScenarioDetailStartGame
            closeHandle={ this.closeStartGame }
            membershipLevel={ membershipLevel }
            scenarioID={ scenarioID } />
        }
        { showCreateScenarioOptions &&
          <BaseScenarioDetailCreate
            closeHandle={ this.closeCreateScenario }
            forceNew={ forceNew }
            store={ store } />
        }
      </BaseCamoBackScroll>
    );
  }
}
