/*
  GuiChat.js
  (c) Human Cube Inc.
*/

import React, { useRef } from 'react';
import { Button, Flag, Icon } from 'semantic-ui-react';

import { sendPacket } from '../../network/network';
import { parseChatString, chatFetchMoreIfNeeded, chatFetchOlder, chatTotalMessages,
         chatMarkReadIfApplicable, chatAnyUnreadsForGame, chatUnreadsForGame }
       from '../../helper/chat';
import BF from '../../bfcore/bfconst1';
import bfH from '../../bfcore/bf_helper';


const InputWidget = ({ game, selectedPlayer }) => {

  const inputRef = useRef(null);

  const sendChat = (e) => {
    e.stopPropagation();
    if(inputRef) {
      const chat = inputRef.current.value.trim();
      if (chat) {
        const gameID = game._id;
        if (gameID) {
          if (selectedPlayer === 0) {
            sendPacket('bf_get', { e: 'chatSendPublic', gameID, chat });
          }
          else if (game.teams >= 2) {
            sendPacket('bf_get', { e: 'chatSendTeam', gameID, chat });
          }
          else {
            sendPacket('bf_get', { e: 'chatSendPlayer', gameID, chat, playerIndex: selectedPlayer });
          }
        }
      }
      inputRef.current.value = '';
    }
  };

  return (
    <div>
      <input
        ref={inputRef}
        style={{ width: 320, height: 32 }}
      />
      <Button
        basic inverted
        onClick={ sendChat }
      >
        Send
      </Button>
    </div>
  );
}

class GuiChatPlayerButton extends React.Component {
  state = {
    flag: '',
    index: 0,
    name: '',
    selected: false,
    teamGame: false,
    unread: false,
    selectPlayer: null,
  };

  constructor(props) {
    super(props);  // Call the parent class' constructor.

    this.state.flag = props.flag;
    this.state.gameID = props.gameID;
    this.state.index = props.index;
    this.state.name = props.name;
    this.state.selected = props.selected;
    this.state.teamGame = props.teamGame;
    this.state.unread = props.unread;
    this.state.selectPlayer = props.selectPlayer;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.flag !== this.state.flag || nextProps.gameID !== this.state.gameID ||
        nextProps.index !== this.state.index || nextProps.name !== this.state.name ||
        nextProps.selected !== this.state.selected || nextProps.teamGame !== this.state.teamGame ||
        nextProps.unread !== this.state.unread ||
        nextProps.selectPlayer !== this.state.selectPlayer) {
      this.setState(nextProps);
    }
  }

  stopPropagation (e) {
    e.stopPropagation();
  }

  selectPlayer = (e) => {
    this.stopPropagation(e);
    if(this.state.selectPlayer) {
      this.state.selectPlayer(this.state.index);
    }
    chatFetchMoreIfNeeded(this.state.gameID, this.state.teamGame, this.state.index);
    chatMarkReadIfApplicable(this.state.gameID, this.state.teamGame, this.state.index);
  };

  render() {
    let notification = null;
    if(this.state.unread) {
      notification = <Icon corner='top right' name='circle' color='red' />;
    }

    if(this.state.selected) {
      if(this.state.index === 0) {
        return <Button color='green' onPointerUp={ this.stopPropagation } size='small'>
                 <Icon.Group>
                   ALL
                   { notification }
                 </Icon.Group>
               </Button>;
      }
      if(this.state.index === 1 && this.state.teamGame) {
        return <Button color='green' onPointerUp={ this.stopPropagation } size='small'>
                 <Icon.Group>
                   TEAM
                   { notification }
                 </Icon.Group>
               </Button>;
      }
      return <Button color='green' onPointerUp={ this.stopPropagation } size='small'>
               <Icon.Group>
                 <Flag name={ this.state.flag } />
                 { notification }
               </Icon.Group>
             </Button>;
    }

    if(this.state.index === 0) {
      return <Button inverted onPointerUp={ this.selectPlayer } size='small'>
                <Icon.Group>
                  ALL
                  { notification }
                </Icon.Group>
              </Button>;
    }
    if(this.state.index === 1 && this.state.teamGame) {
      return <Button inverted onPointerUp={ this.selectPlayer } size='small'>
               <Icon.Group>
                 TEAM
                 { notification }
               </Icon.Group>
             </Button>;
    }
    return <Button inverted onPointerUp={ this.selectPlayer } size='small'>
             <Icon.Group>
               <Flag name={ this.state.flag } />
               { notification }
             </Icon.Group>
           </Button>;
  }

}

export default class GuiChat extends React.Component {

  CHAT_SCROLL_AMOUNT = 3;
  CHAT_DISPLAY_LINES = 10;
  CHAT_BUTTON_PULSE_MS = 500;

  state = {
    // external state:
    gameID: 0,
    game: {},
    working: {},
    gameStatus: {},
  //  gameChat: {},
    gameChatData: {},

    // internal state:
    pulse: false,
    show: false,
    selectedPlayer: 0,  // chat channel, 0 for public channel.
    scrollOffset: 0,
  };

  constructor(props) {
    super(props);  // Call the parent class' constructor.

    this.state.gameID = props.store.gameID;
    this.state.game = props.store.game;
    this.state.working = props.store.working;
    this.state.gameStatus = props.store.gameStatus;
    this.state.gameChatData = props.store.gameChatData;

    // this.chatInputRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store.gameID !== this.state.gameID ||
        nextProps.store.game !== this.state.game ||
        nextProps.store.working !== this.state.working ||
        nextProps.store.gameStatus !== this.state.gameStatus ||
        nextProps.store.gameChatData !== this.state.gameChatData) {

      let { show, selectedPlayer, scrollOffset } = this.state;
      if(nextProps.store.gameID !== this.state.gameID) {
        // Different game so reset chat display.
        show = false;
        selectedPlayer = 0;
        scrollOffset = 0;
      }

      this.setState({
        gameID: nextProps.store.gameID,
        game: nextProps.store.game,
        working: nextProps.store.working,
        gameStatus: nextProps.store.gameStatus,
        gameChatData: nextProps.store.gameChatData,
        show,
        selectedPlayer,
        scrollOffset,
      });
    }
  }

  componentDidMount() {
    this.pulseInterval = setInterval(() => {
      this.setState(state => {
        return({
          pulse: state.pulse ? false : true,
        });
      });
    }, this.CHAT_BUTTON_PULSE_MS);
  }

  componentWillUnmount() {
    clearInterval(this.pulseInterval);
  }

  selectPlayer = (selectedPlayer) => {
    this.setState({ selectedPlayer, scrollOffset: 0 });
  };

  renderPlayerChat (chatData, scrollOffset, selectedPlayer, playerName, teamGame, playerFlags,
                    baseYear) {
    const messages = [];
    if(Array.isArray(chatData) && chatData.length > 0) {
      let lastTurnIndex = 0;
      for(let i = this.CHAT_DISPLAY_LINES; i > 0; i--) {
        const index = chatData.length - i - scrollOffset;
        if(index >= 0 && index < chatData.length) {
          const chat = parseChatString(chatData[index][1]);
          if(lastTurnIndex !== chat.turnIndex) {
            lastTurnIndex = chat.turnIndex;
            messages.push(
              <p key={ 'kt' + i } style={{margin: '0px 0px', color: '#FFFFFF'}}>
                <small>{ bfH.turnIndexAsString(chat.turnIndex, baseYear) }</small>
              </p>
            );
          }
          messages.push(
            <p key={ 'k' + i } style={{margin: '3px 0px'}}>
              <Flag name={ playerFlags[chat.playerIndex]} /> { chat.uName } : { chat.text }
            </p>
          );
        }
        else { // Placeholder for loading...
          messages.push(<p key={ 'k' + i } style={{margin: '3px 0px'}}> &nbsp; </p>);
        }
      }
    }
    else {
      for(let i = this.CHAT_DISPLAY_LINES; i > 0; i--) {
        // Placeholder for empty chat.
        messages.push(<p key={ 'k' + i } style={{margin: '3px 0px'}}> &nbsp; </p>);
      }
    }
    return messages;
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  showChat = (e) => {
    this.stopPropagation(e);

    const { gameID, game, selectedPlayer } = this.state;
    let teamGame = false;
    if(game.teams > 1) {
      teamGame = true;
    }
    chatFetchMoreIfNeeded(gameID, teamGame, selectedPlayer);
    chatMarkReadIfApplicable(gameID, teamGame, selectedPlayer);

    this.setState({show: true});
  }

  hideChat = (e) => {
    this.stopPropagation(e);
    this.setState({show: false});
  }

  scrollUp = (e) => {
    this.stopPropagation(e);
    const { gameID, game, scrollOffset, selectedPlayer } = this.state;
    if(scrollOffset + this.CHAT_DISPLAY_LINES < chatTotalMessages(gameID, selectedPlayer) + 1) {
      // Does not have to be exact, at least scroll to or a little past.
      this.setState({ scrollOffset: scrollOffset + this.CHAT_SCROLL_AMOUNT });
      chatFetchOlder(gameID, (game.teams > 1), selectedPlayer);
    }
  }

  scrollDown = (e) => {
    this.stopPropagation(e);
    let scrollOffset = this.state.scrollOffset;
    if(scrollOffset === 0) {
      return;
    }
    if(scrollOffset > this.CHAT_SCROLL_AMOUNT) {
      scrollOffset -= this.CHAT_SCROLL_AMOUNT;
    }
    else {
      scrollOffset = 0;
    }
    this.setState({ scrollOffset });
  }

  render() {
    const { pulse, gameID, game, working, gameStatus, show, selectedPlayer, scrollOffset,
            gameChatData } = this.state;
    if(!gameID || game._id !== gameID || !gameStatus[gameID]) {
      // Wait for all game data to load before attempting to render any chat stuff.
      return null;
    }
    if(game.humanPlayers < 2) {
      // Solo game, so no chat.
      return null;
    }

    if(!show) {
      const unreadFlag = chatAnyUnreadsForGame(gameID) && pulse;
      return (
        <Button style={{opacity: unreadFlag ? 1 : 0.86,
                        position: 'absolute',
                        left: 0,
                        bottom: 0}}
                color={ unreadFlag ? 'green' : null }
                onPointerUp={ this.showChat }
                onPointerDown={ this.stopPropagation }
                size='small' >
          Chat
        </Button>
      );
    }

    const localPlayerIndex = working.localPlayerIndex;

    let teamGame = false;
    if(game.teams > 1) {
      teamGame = true;
    }

    const { players, playerName, turn, year } = gameStatus[gameID];
    let allowPrivateChat = false;
    if(localPlayerIndex > 0 && turn >= BF.TURN_PICK_ZONE) {
      // Only allow non-public chat if player in game and after the game has begun because player
      // order might change.
      allowPrivateChat = true;
    }

    const unreads = chatUnreadsForGame(gameID);

    const playerButtons = [
      <GuiChatPlayerButton
        key={ 'pb0' }
        flag={ '' }
        gameID={ gameID }
        index={ 0 }
        name={ 'ALL' }
        selected={ selectedPlayer === 0 ? true : false }
        teamGame={ teamGame }
        unread={ unreads[0] ? true : false }
        selectPlayer={ this.selectPlayer }
      />
    ];
    if(allowPrivateChat) {
      if(teamGame) {
        playerButtons.push(
          <GuiChatPlayerButton
            key={ 'pb1' }
            flag={ '' }
            gameID={ gameID }
            index={ 1 }
            name={ 'TEAM' }
            selected={ selectedPlayer === 1 ? true : false }
            teamGame={ teamGame }
            unread={ unreads[1] ? true : false }
            selectPlayer={ this.selectPlayer }
          />
        );
      }
      else {
        for(let p = 1; p <= players; p++) {
          if(p !== localPlayerIndex) {
            playerButtons.push(
              <GuiChatPlayerButton
                key={ 'pb' + p }
                flag={ game.playerFlag[p] }
                gameID={ gameID }
                index={ p }
                name={ playerName[p] }
                selected={ selectedPlayer === p ? true : false }
                teamGame={ teamGame }
                unread={ unreads[p] ? true : false }
                selectPlayer={ this.selectPlayer }
              />
            );
          }
        }
      }
    }

    let infoText = null;
    if(selectedPlayer === 0) {
      infoText = <p key='k' style={{margin: '3px 0px'}}>Public chat with everyone in this game.</p>;
    }
    else if(teamGame) {
      infoText = <p key='k' style={{margin: '3px 0px'}}>Private chat for your team only.</p>;
    }
    else {
      infoText = <p key='k' style={{margin: '3px 0px'}}>
          Private chat just between you and { playerName[selectedPlayer] }.
        </p>;
    }

    const messages = this.renderPlayerChat(gameChatData[gameID + '|' + selectedPlayer],
      scrollOffset, selectedPlayer, playerName[selectedPlayer], teamGame, game.playerFlag,
      year);

    return (
      <div
          style={{opacity: 0.86,
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '100%',
                }}
          onPointerDown={ this.stopPropagation } >
        <div style={{position: 'absolute',
                     left: '70px',
                     bottom: 0,
                     marginRight: '6px',
                     paddingLeft: '6px',
                     paddingRight: '6px',
                     backgroundColor: '#AAAAAA',
                   }}>
          { messages }
          <InputWidget game={this.state.game} selectPlayer={this.state.selectedPlayer} />
          { infoText }
          { playerButtons }
        </div>

        <div style={{display: 'flex', flexWrap: 'wrap'}}
             onPointerDown={ this.stopPropagation } >
          <Button onPointerUp={ this.hideChat } size='small'>
            Chat
          </Button>
        </div>

        <div style={{opacity: (scrollOffset + this.CHAT_DISPLAY_LINES <
                              chatTotalMessages(gameID, selectedPlayer) + 1) ? 0.96 : 0.33,
                     position: 'absolute',
                     left: 10,
                     bottom: 220,
                     width: '100%',
                   }}
             onPointerDown={ this.stopPropagation } >
          <Button onPointerUp={ this.scrollUp } size='tiny'>
            <br /><Icon size='large' name='angle double up' /><br />&nbsp;
          </Button>
        </div>

        <div style={{opacity: scrollOffset > 0 ? 0.96 : 0.33,
                     position: 'absolute',
                     left: 10,
                     bottom: 140,
                     width: '100%',
                   }}
             onPointerDown={ this.stopPropagation } >
          <Button onPointerUp={ this.scrollDown } size='tiny'>
            <br /><Icon size='large' name='angle double down' /><br />&nbsp;
          </Button>
        </div>
      </div>
    );
  }
}
