/*
  BaseAdSense.js
  (c) Human Cube Inc.
*/

import React, { Component } from 'react';

class BaseAdSense extends Component {

	componentDidMount() {
		(window.adsbygoogle = window.adsbygoogle || []).push({})
	}

	render() {

/*
    const style = {
      display: 'inline-block',
      width: '468px',
      height: '60px',
		};

    // This is for the BattleForcesLowerBanner ad unit:
    return(
      <ins className='adsbygoogle'
           style={ style }
           data-ad-client='ca-pub-0451671765608072'
           data-ad-slot='6477705320'></ins>
    );
*/

		const style = {
      display: 'inline-block',
      width: '728px',
      height: '90px',
		};

    // This is for the BFHorizontal728x90 ad unit:
    return(
      <ins className='adsbygoogle'
           style={ style }
           data-ad-client='ca-pub-0451671765608072'
           data-ad-slot='4471784202'></ins>
    );

/*
    // This is for the BFHorizontalResponsive ad unit:
    const style = {
			display: 'block',
		};
		return(
      <ins className='adsbygoogle'
           style={ style }
           data-ad-client='ca-pub-0451671765608072'
           data-ad-slot='2691798673'
           data-ad-format='auto'
           data-full-width-responsive='true'>
      </ins>
		);
*/
	}
}

export default BaseAdSense;
