import React from 'react';
import GuiForce from './GuiForce';

function GuiForceTransport(props) {
  const game = props.game;
  const count = props.count;
  const cargo = [];
  let loadPointsDisplay = null;
  const cargoParts = props.transportCargoKey.split(':');
  const forceType = game.forceID[parseInt(cargoParts[0])];
  const range = cargoParts[1];
  loadPointsDisplay = <small>LP:{cargoParts[2]}&nbsp;</small>;
  for(let i = 3; i < cargoParts.length; i++) {
    if(cargoParts[i].length > 0) {
      cargo.push(
        <GuiForce key={'c' + i} forceType={game.forceID[parseInt(cargoParts[i])]} half />
      );
    }
  }
  return (
    <div className='flex flex-row'>
      {count &&
        <div key='count' className='mt-1'><b>{count}</b></div>
      }
      <div className='flex flex-row'>
        <GuiForce forceType={forceType} />
        {cargo}
      </div>
      <div key='points' className='mt-1'>
        {loadPointsDisplay}
        &nbsp;<small>range:{range}</small>
      </div>
    </div>
  );
}

export default GuiForceTransport;
