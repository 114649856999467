/*
  GuiLayer.js
  (c) Human Cube Inc.
*/

import React from 'react';

import { updateMusic } from '../helper/music';
import mainModes from '../reducers/main-modes';
import BaseMain from './BaseGui/BaseMain';
import GuiGame from './GameGui/GuiGame';
import GuiMapEdit from './GameGui/GuiMapEdit';
import BaseGameLoading from './BaseGui/BaseGameLoading';
import BaseGameLoadingError from './BaseGui/BaseGameLoadingError';

export default class GuiLayer extends React.Component {

  state = {
    store: {},
    xCenter: 0,
    yCenter: 0,
  };

  constructor(props) {
    super(props);  // Call the parent class' constructor.

    updateMusic(
      props.store.mainMode,
      props.store.game.turn,
      props.store.musicOn,
      props.store.musicVolume,
    );

    this.state.store = props.store;
    this.state.xCenter = props.xCenter;
    this.state.yCenter = props.yCenter;

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    updateMusic(
      nextProps.store.mainMode,
      nextProps.store.game.turn,
      nextProps.store.musicOn,
      nextProps.store.musicVolume,
    );

    if(nextProps.store !== this.state.store ||
      nextProps.xCenter !== this.state.xCenter ||
      nextProps.yCenter !== this.state.yCenter) {
      this.setState({
        store: nextProps.store,
        xCenter: nextProps.xCenter,
        yCenter: nextProps.yCenter,
      });
    }
  }

  render() {
    // const { game, mainMode, musicOn, musicVolume, /* soundOn, soundVolume, */ } = this.state.store;

    switch(this.state.store.mainMode) {
      case mainModes.GAME_LOADING:
        return <BaseGameLoading store={this.state.store} />;
      case mainModes.GAME_LOADING_ERROR:
        return <BaseGameLoadingError store={this.state.store} />;
      case mainModes.GAME_REPLAY:
      case mainModes.GAME_DOING_TURN:
      case mainModes.GAME_SUBMITTING_TURN:
      case mainModes.SCENARIO_PREVIEW:
      case mainModes.SCENARIO_EDIT:
        return (
          <>
            {/* <GuiMusic on={musicOn}
              mainMode={mainMode}
              turnIndex={game.turn}
              volume={musicVolume}
            /> */}
            <GuiGame store={this.state.store} />;
          </>
        );
      case mainModes.MAP_EDIT:
        return <GuiMapEdit store={ this.state.store }
                           xCenter={ this.state.xCenter }
                           yCenter={ this.state.yCenter } />;
      default:
        return <BaseMain store={this.state.store} />;
    }
  }
}
