/*
  BaseFlag.js
  (c) Human Cube Inc.
*/

export default function BaseFlag ({ cc }) {
  const texture = process.env.PUBLIC_URL + '/img/flags/' + cc + '.png';
  return <img src={ texture }
              width='70px'
              height='45px'
              alt={ 'Flag ' + cc }
         />;
}
