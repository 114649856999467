/*
  forceType.ts
  (c) Human Cube Inc.
*/

export interface ForceTypeCache {
  [Key: number]: any;
}

export const forceTypeCache: ForceTypeCache = {};  // Array of all individual force types indexed by _id.

forceTypeCache[1] = {
_id: 1,
uid: 2,
uName: 'GameAdmin',
name: 'Troop',
namePlural: 'Troops',
description: 'Standard Troop',
flags: 1+4+16+64+256+512+1024+65536+131072,
cost: 10,
range: 1,
attack: 15,
defense: 30,
cargoCapacity: 0,
cargoSpace: 1,
carrierCapacity: 0,
carrierSpace: -1,
liftCapacity: 0,
liftSpace: 1,
production: 0,
version: 0,
created: new Date(),
updated: new Date()
};

forceTypeCache[2] = {
_id: 2,
uid: 2,
uName: 'GameAdmin',
name: 'Tank',
namePlural: 'Tanks',
description: 'Standard Tank',
flags: 1+4+16+64+256+512+1024+65536+131072,
cost: 15,
range: 2,
attack: 50,
defense: 30,
cargoCapacity: 0,
cargoSpace: 1,
carrierCapacity: 0,
carrierSpace: -1,
liftCapacity: 0,
liftSpace: -1,
production: 0,
version: 0,
created: new Date(),
updated: new Date()
};

forceTypeCache[3] = {
_id: 3,
uid: 2,
uName: 'GameAdmin',
name: 'SAM Launcher',
namePlural: 'SAM Launchers',
description: 'Standard SAM Launcher',
flags: 1+4+16+64+128+256+512+1024+4096+32768+65536+131072,
cost: 15,
range: 1,
attack: 0,
defense: 20,
cargoCapacity: 0,
cargoSpace: 1,
carrierCapacity: 0,
carrierSpace: -1,
liftCapacity: 0,
liftSpace: -1,
production: 0,
version: 0,
created: new Date(),
updated: new Date()
};

forceTypeCache[4] = {
_id: 4,
uid: 2,
uName: 'GameAdmin',
name: 'Fighter',
namePlural: 'Fighters',
description: 'Standard Fighter',
flags: 1+4+8+32+64+128+512+1024+8192+65536,
cost: 30,
range: 4,
attack: 50,
defense: 64,
cargoCapacity: 0,
cargoSpace: -1,
carrierCapacity: 0,
carrierSpace: 1,
liftCapacity: 0,
liftSpace: -1,
production: 0,
version: 0,
created: new Date(),
updated: new Date()
};

forceTypeCache[5] = {
_id: 5,
uid: 2,
uName: 'GameAdmin',
name: 'Bomber',
namePlural: 'Bombers',
description: 'Standard Bomber',
flags: 1+4+8+32+64+128+512+1024+8192+65536,
cost: 35,
range: 6,
attack: 64,
defense: 15,
cargoCapacity: 0,
cargoSpace: -1,
carrierCapacity: 0,
carrierSpace: -1,
liftCapacity: 0,
liftSpace: -1,
production: 0,
version: 0,
created: new Date(),
updated: new Date()
};

forceTypeCache[6] = {
_id: 6,
uid: 2,
uName: 'GameAdmin',
name: 'Submarine',
namePlural: 'Submarines',
description: 'Standard Submarine',
flags: 2+8+16+64+1024+2048,
cost: 20,
range: 2,
attack: 30,
defense: 30,
cargoCapacity: 0,
cargoSpace: -1,
carrierCapacity: 0,
carrierSpace: -1,
liftCapacity: 0,
liftSpace: -1,
production: 0,
version: 0,
created: new Date(),
updated: new Date()
};

forceTypeCache[7] = {
_id: 7,
uid: 2,
uName: 'GameAdmin',
name: 'Transport',
namePlural: 'Transports',
description: 'Standard Transport',
flags: 2+8+16+64+256+512+1024+2097152,
cost: 20,
range: 2,
attack: 10,
defense: 10,
cargoCapacity: 2,
cargoSpace: -1,
carrierCapacity: 0,
carrierSpace: -1,
liftCapacity: 0,
liftSpace: -1,
production: 0,
version: 0,
created: new Date(),
updated: new Date()
};

forceTypeCache[8] = {
_id: 8,
uid: 2,
uName: 'GameAdmin',
name: 'Carrier',
namePlural: 'Carriers',
description: 'Standard Aircraft Carrier',
flags: 2+8+16+64+256+512+1024,
cost: 35,
range: 2,
attack: 34,
defense: 34,
cargoCapacity: 0,
cargoSpace: -1,
carrierCapacity: 4,
carrierSpace: -1,
liftCapacity: 0,
liftSpace: -1,
production: 0,
version: 0,
created: new Date(),
updated: new Date()
};

forceTypeCache[9] = {
_id: 9,
uid: 2,
uName: 'GameAdmin',
name: 'Destroyer',
namePlural: 'Destroyers',
description: 'Standard Destroyer',
flags: 2+8+16+64+256+512+1024,
cost: 25,
range: 2,
attack: 50,
defense: 50,
cargoCapacity: 0,
cargoSpace: -1,
carrierCapacity: 0,
carrierSpace: -1,
liftCapacity: 0,
liftSpace: -1,
production: 0,
version: 0,
created: new Date(),
updated: new Date()
};

forceTypeCache[10] = {
_id: 10,
uid: 2,
uName: 'GameAdmin',
name: 'Factory',
namePlural: 'Factories',
description: 'Standard Factory',
flags: 1+8388608+16777216+33554432+67108864+134217728,
cost: 45,
range: 0,
attack: 0,
defense: 0,
cargoCapacity: 0,
cargoSpace: -1,
carrierCapacity: 0,
carrierSpace: -1,
liftCapacity: 0,
liftSpace: -1,
production: 1,
version: 0,
created: new Date(),
updated: new Date()
};
