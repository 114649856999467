import BF from '../bfcore/bfconst1';
import bfH from '../bfcore/bf_helper';
import { doSkatteredAutoPick, doDistributedAutoPick, do123123123AutoPick, do123321321AutoPick }
  from '../bfcore/bf_gameautopick';
import { forceTypeCache } from './forceType';
import { gameEnhanceData } from './game';
import { getNewZoneXY } from '../bfcore/bf_hitmap';

const playerCountryCode = [
  '', 'us', 'sa', 'gb', 'cn', 'in', 'kr', 'br',
  'de', 'fr', 'za', 'pk', 'es', 'fi', 'gr', 'hu',
  'jm', 'it', 'jp', 'ph', 'nz', 'qa', 'ru', 'se',
  'tw', 'cr', 'pl', 'tr', 'ug', 'ye', 'no', 'ca',
];

const ASCII_a = 'a'.charCodeAt(0);
const ASCII_z = 'z'.charCodeAt(0);
const ASCII_A = 'A'.charCodeAt(0);
const ASCII_Z = 'Z'.charCodeAt(0);
const ASCII_0 = '0'.charCodeAt(0);
const ASCII_9 = '9'.charCodeAt(0);

function isString (v) {
	return (typeof v === 'string' || v instanceof String);
}

export function sanitizeScenarioName (rawScenarioName) {
	// Returns a valid name (1 to BF.MAX_SCENARIO_NAME chars).
	if( !isString(rawScenarioName) ) {
    rawScenarioName = '';
  }
  let scenarioName = '';
	let k = rawScenarioName.length;
	let i;
	for(i = 0; i < k; i++)
		if( rawScenarioName.charAt(i) !== ' ' )
			break;
	let lastspace = 0;
	for(; i < k; i++) {
		const c = rawScenarioName.charAt(i);
		const cc = c.charCodeAt(0);
		if( (cc >= ASCII_a && cc <= ASCII_z) || (cc >= ASCII_A && cc <= ASCII_Z) ||
				(cc >= ASCII_0 && cc <= ASCII_9) || c === '-' || c === ':' || c === '.' ) {
      if( lastspace && scenarioName !== ' ' )
          scenarioName += ' ';
      lastspace = 0;
	    scenarioName += c;
		}
		else if( c === ' ' )
  		lastspace = 1;
	}
	if(!scenarioName) {
		scenarioName = 'Untitled';
  }
  return scenarioName.substr(0, BF.MAX_SCENARIO_NAME);
}

export function sanitizeScenarioDescription (rawScenarioDescription) {
	// Returns a valid description (0 to BF.MAX_SCENARIO_DESCRIPTION chars).
	if( !isString(rawScenarioDescription) )
		rawScenarioDescription = '';
  let scenarioDescription = '';
	let k = rawScenarioDescription.length;
	let i;
	for(i = 0; i < k; i++)
		if( rawScenarioDescription.charAt(i) !== ' ' )
			break;
	let lastspace = 0;
	for(; i < k; i++) {
		const c = rawScenarioDescription.charAt(i);
		const cc = c.charCodeAt(0);
		if( (cc >= ASCII_a && cc <= ASCII_z) || (cc >= ASCII_A && cc <= ASCII_Z) ||
				(cc >= ASCII_0 && cc <= ASCII_9) || c === '-' || c === ':' || c === '.' ||
				c === ',' ) {
			scenarioDescription += c;
			lastspace = 0;
		}
		else if( c === ' ' ) {
			if( lastspace === 0 ) {
				lastspace = 1;
				scenarioDescription += ' ';
			}
		}
	}
 	return scenarioDescription.substr(0, BF.MAX_SCENARIO_DESCRIPTION);
}

export function fillScenarioTotals (scenario) {
  // Cleans some values and calculates the aggregate data in the scenario object.
  // Modifies the object passed in.
  const players = scenario.players;

  // Clean up some values first:
  if(!Array.isArray(scenario.owner)) {
    scenario.owner = [];
  }
  scenario.owner[0] = 0;
  for(let z = 1; z <= scenario.zones; z++) {
    if(!scenario.owner[z] || scenario.owner[z] < 1 || scenario.owner[z] > players) {
      scenario.owner[z] = 0;
    }
  }

  if(!Array.isArray(scenario.capital) || scenario.capital.length !== players + 1) {
    scenario.capital = Array(players + 1).fill(0);
  }
  if(!Array.isArray(scenario.cash) || scenario.cash.length !== players + 1) {
    scenario.cash = Array(players + 1).fill(0);
  }
  if(!Array.isArray(scenario.playerFlag)) {
    scenario.playerFlag = [];
  }
  scenario.playerFlag[0] = '';
  let setFlag = false;
  for(let p = 1; p <= players; p++) {
    if(!scenario.playerFlag[p]) {
      setFlag = true;
    }
  }
  if(setFlag || scenario.playerFlag.length !== players + 1) {
    for(let p = 1; p <= players; p++) {
      scenario.playerFlag[p] = playerCountryCode[p];
    }
  }

  // Calculate the helper totals:
  scenario.humanPlayers = 0;
  scenario.computerPlayers = 0;
  for(let p = 1; p <= players; p++) {
    if(scenario.playerFlags[p] & BF.PLAYER_FLAG_HUMAN) {
      scenario.humanPlayers++;
    }
    if(scenario.playerFlags[p] & BF.PLAYER_FLAG_COMPUTER) {
      scenario.computerPlayers++;
    }
  }

  scenario.factories = 0;
  for(let f = 0; f < scenario.forceID.length; f++) {
    if(forceTypeCache[scenario.forceID[f]].production > 0) {
      for(let z = 1; z <= scenario.zones; z++) {
        if(Array.isArray(scenario.startingForces[z]) && scenario.startingForces[z][f] > 0) {
          scenario.factories += scenario.startingForces[z][f];
        }
      }
    }
  }

  scenario.incomeTotal = scenario.income.slice(1).reduce((a, b) => a + b, 0);
  scenario.cashTotal = scenario.cash.slice(1).reduce((a, b) => a + b, 0);
}

export function createScenarioFromMap (map, uid, uName) {
  // Returns a new scenario object based on the map and user info.
  const now = new Date();
  const income = [0];
  let incomeTotal = 0;
  for(let i = 1; i <= map.zones; i++) {
    if(map.zoneFlags[i] & BF.ZONE_FLAG_LAND_ZONE) {
      income.push(1);
      incomeTotal++;
    }
    else {
      income.push(0);
    }
  }
  return {
    _id: 0,

    uid: uid,
    uName: uName,

    name: uName + ' Scenario',
    description: 'New scenario by ' + uName,

    mapID: map._id,
    mapName: map.name,
    zones: map.zones,
    zonesLand: map.zonesLand,
    zonesWater: map.zonesWater,

    gameFlags: 0,  // Filled later.

    forceID: [],  // Filled later.

    startingForces: [],  // Filled later.
    navyZone: Array(map.zones + 1).fill(0),
    income,
    owner: Array(map.zones + 1).fill(0),

    zoneBonus: [],

    factories: 0,   // Filled later.
    incomeTotal,
    cashTotal: 0,  // Filled later.

    players: 0,          // Filled later.
    humanPlayers: 0,     // Filled later.
    computerPlayers: 0,  // Filled later.
    teams: 0,            // Filled later.
    team: [],            // Filled later.
    teamName: [''],      // Filled later.
    pickingOrder: 1,     // Filled later.

    forceBuyBits: [],    // Filled later.
    capital: [],         // Filled later.
    cash: [],            // Filled later.
    playerName: [],      // Filled later.
    playerFlag: [],      // Filled later.
    playerFlags: [],     // Filled later.
    victoryCapitals: 0,  // Filled later.
    victoryLandZones: 0, // Filled later.
    victoryIncome: 0,    // Filled later.
    victoryCash: 0,      // Filled later.

    year: 2000,

    maxDuration: 8640000,  // 100 days.
    maxTurns: 1000,        // 1000 turns.
    turnTime: 90000,       // 25 hours.

    plays: 0,
    playedLast: now,
    created: now,
  };
}

export function createGamePreviewFromScenario (scenario, map, uid, uName, doAutoPick) {
  // Returns a new game object.
  // All values are deep copied.
  // uid and uName are optional.
  const game = {};

  game._id = 'GP-' + scenario._id; // make up a game preview ID.

  game.state = BF.GAME_STATE_HIDDEN;
  game.flags = scenario.gameFlags;
  game.name = scenario.name;
  game.uid = uid;
  if(!game.uid) {
    game.uid = 0;
  }
  game.uName = uName;
  if(!game.uName) {
    game.uName = '';
  }

  game.scenarioID = scenario._id;
  game.scenarioName = scenario.name;
  game.scenarioUID = scenario.uid;
  game.scenarioUName = scenario.uName;
  game.factories = scenario.factories;

  game.mapID = scenario.mapID;

  game.mapName = map.name;
  game.mapUID = map.uid;
  game.mapUName = map.uName;
  game.mapFlags = map.flags;
  game.mapXS = map.xs;
  game.mapYS = map.ys;
  game.mapData = map.mapData;
  game.zoneKey = [];
  if( Array.isArray(map.zoneKey) ) {
    for(let i = 0; i < map.zoneKey.length; i++)
      game.zoneKey[i] = map.zoneKey[i];
  }

  game.zones = scenario.zones;
  game.zonesLand = scenario.zonesLand;
  game.zonesWater = scenario.zonesWater;
  game.zoneBonus = [];

  game.teams = scenario.teams;

  game.players = scenario.players;
  game.humanPlayers = scenario.humanPlayers;
  game.computerPlayers = scenario.computerPlayers;

  game.pickingOrder = scenario.pickingOrder;

  game.victoryCapitals = scenario.victoryCapitals;
  game.victoryIncome = scenario.victoryIncome;
  game.victoryLandZones = scenario.victoryLandZones;
  game.victoryCash = scenario.victoryCash;

  game.maxDuration = scenario.maxDuration;
  if( !game.maxDuration )
    game.maxDuration = BF.GAME_MAX_DURATION;
  if( game.maxDuration < BF.GAME_MIN_DURATION )
    game.maxDuration = BF.GAME_MIN_DURATION;
  if( game.maxDuration > BF.GAME_MAX_DURATION )
    game.maxDuration = BF.GAME_MAX_DURATION;

  game.maxTurns = scenario.maxTurns;
  if( !game.maxTurns )
    game.maxTurns = BF.GAME_MAX_TURNS;
  if( game.maxTurns < BF.GAME_MIN_TURNS )
    game.maxTurns = BF.GAME_MIN_TURNS;
  if( game.maxTurns > BF.GAME_MAX_TURNS )
    game.maxTurns = BF.GAME_MAX_TURNS;

  game.regScore = [0, 1000000];
  game.regMembership = [0, 10];   // TODO:  this setting is in limbo...
  game.playTimes = [];  // Empty for anytime.

  game.timeTurn = scenario.turnTime;
  if( !game.timeTurn )
    game.timeTurn = BF.GAME_DEFAULT_TURN_TIME;
  if( game.timeTurn < BF.GAME_MIN_TURN_TIME )
    game.timeTurn = BF.GAME_MIN_TURN_TIME;
  if( game.timeTurn > BF.GAME_MAX_TURN_TIME )
    game.timeTurn = BF.GAME_MAX_TURN_TIME;
  game.timeZonePicking = game.timeTurn;
  game.timeCapitalPicking = game.timeTurn;

  game.sourceGameID = 0;  // Not an auto-respawn.

  game.privatePIN = 0;
  // if( game.flags & BF.GAME_FLAG_PRIVATE )   // Generate a 6 digit PIN.
  //     game.privatePIN = Math.floor(Math.random()*900000) + 100000;

  game.turn = 100000000;  // Registering.
  game.timeLimit = '';  // no limit.
  game.required = 0;
  game.noWait = 0;
  game.submitted = 0;

  game.autoPlay = 0;
  game.teammatesSubmit = 0;

  // All player data also includes a 0 player.
  game.autoSpend = [];
  game.autoMove = [];
  game.slotName = [];
  game.slotFlag = [];
  game.playerID = [];
  game.playerName = [];
  game.playerFlags = [];
  game.playerFlag = [];
  game.playerColor = [];
  game.playerScore = [];
  game.canBuy = [];
  game.maxBuy = [];
  game.showBuy = [];
  game.finished = [];
  game.playerAutoTotal = [];
  game.playerAutoCurrent = [];
  game.playerMissedTotal = [];
  game.playerMissedCurrent = [];
  game.capital = [];
  game.capitalX = [];
  game.capitalY = [];
  game.cash = [];
  game.team = [];
  game.fixedAlly = [];
  game.declaredAlly = [];
  game.force = [];
  game.losses = [];

  for(let i = 0; i <= game.players; i++) {   // Includes extra one for player 0.
    game.autoSpend[i] = 100;
    game.autoMove[i] = 100;

    game.playerScore[i] = 0;
    if( scenario.playerName.length > 0 )
      game.slotName[i] = scenario.playerName[i] ? scenario.playerName[i] : '';
    if( scenario.playerFlag.length > 0 )
      game.slotFlag[i] = scenario.playerFlag[i] ? scenario.playerFlag[i] : '';

    if(i === 0 || (scenario.playerFlags[i] & BF.PLAYER_FLAG_HUMAN)) {
      // Empty Human Player Slot:
      game.playerID.push(0);
    }
    else {
      // Computer Player:
      game.playerID.push(-i);
    }
    game.playerFlags.push(scenario.playerFlags[i]);
    game.playerName.push(i === 0 ? '' : (game.slotName[i] ? game.slotName[i] : ('Player' + i)));
    game.playerFlag.push(game.slotFlag[i] ? game.slotFlag[i] : playerCountryCode[i]);

    if( i === 0 ) {
      game.canBuy.push(0);
      game.showBuy.push(0);
    }
    else {
      // Default to able purchase any force types in this scenario:
      game.canBuy.push((1<<scenario.forceID.length) - 1);
      // Default show all forces types in this scenario in purchase window:
      game.showBuy.push((1<<scenario.forceID.length) - 1);
    }
    game.maxBuy.push(0);

    game.playerColor.push(0);
    game.finished.push([0, 0]);
    game.playerAutoTotal.push(0);
    game.playerAutoCurrent.push(0);
    game.playerMissedTotal.push(0);
    game.playerMissedCurrent.push(0);
    if( i > 0 && scenario.capital[i] ) {
      const capitalID = scenario.capital[i];
      const pos = getNewZoneXY(game, capitalID);
      game.capital.push(capitalID);
      game.capitalX.push(pos.x);
      game.capitalY.push(pos.y);
    }
    else {
      game.capital.push(0);
      game.capitalX.push(0);
      game.capitalY.push(0);
    }
    if( i > 0 && scenario.cash[i] )
      game.cash.push(scenario.cash[i]);
    else game.cash.push(0);

    if( game.teams > 0 ) {
      if( scenario.team.length === game.players ) {
        if( i === 0 )
          game.team.push(0);
        else game.team.push(scenario.team[i]);
      }
      else {// Default to 0,1,2,1,2 or 0,1,2,3,1,2,3.
        if( i === 0 )
          game.team.push(0);
        else game.team.push(Math.floor((i - 1) % game.teams) + 1);
      }
    }

    game.fixedAlly.push(0);   // Filled in further below.
    game.declaredAlly.push(0);
    game.force.push([]);
    game.losses.push([]);
    for(let j = 0; j <= game.players; j++)  // Extra one for player 0.
      game.losses[i].push(0);
  }

  // Uses team data, so needs to happen after teams are set:
  for(let i = 0; i <= game.players; i++) {   // Extra one for player 0.
    game.fixedAlly[i] |= (1 << i);   // Always an ally of oneself.
    if( game.teams > 0 ) {
      const team = game.team[i];
      if( team && team > 0 )
        for(let j=0; j <= game.players; j++)   // Extra one for player 0.
          if( team === game.team[j] )
            game.fixedAlly[i] |= (1 << j);
    }
  }

  game.missedAllowed = 2;
  game.autoTotal = 0;
  game.autoCurrent = 0;
  game.autoAllowed = 2;

  game.teamName = [''];   // Non-team name first.
  for(let i = 1; i <= game.teams; i++) {
    if( Array.isArray(scenario.teamName) && scenario.teamName.length === game.teams + 1 &&
        scenario.teamName[i] ) {
      game.teamName.push(scenario.teamName[i]);
    }
    else game.teamName.push('Team ' + String.fromCharCode('A'.charCodeAt() + i));
  }

  game.zoneFlags = [];
  game.zoneName = [];
  game.zoneIncome = [];
  game.bordersLand = [];
  game.bordersWater = [];
  game.zoneOwner = [];
  game.zoneOwnerPrev = [];

  let unpicked = 0;
  for(let i = 0; i <= game.zones; i++) {  // [0 to zones]
    game.zoneFlags[i] = map.zoneFlags[i] ? map.zoneFlags[i] : 0;
    game.zoneName[i] = map.zoneName[i] ? map.zoneName[i] : '';
    game.zoneIncome[i] = scenario.income[i] ? scenario.income[i] : 0;

    game.bordersLand[i] = [];
    game.bordersWater[i] = [];
    for(let j = 0; j < map.bordersLand[i].length; j++)
      game.bordersLand[i].push(map.bordersLand[i][j]);
    for(let j = 0; j < map.bordersWater[i].length; j++)
      game.bordersWater[i].push(map.bordersWater[i][j]);

    var owner = 0;
    if( i > 0 ) {
      if( bfH.isLand(game, i) ) {
        owner = scenario.owner[i];
        if( !owner ) {
          owner = 0;
          unpicked++;
        }
      }
    }
    game.zoneOwner.push(owner);
    game.zoneOwnerPrev.push(owner);
  }

  game.forceID = [];
  game.forceName = [];
  game.forceNamePlural = [];
  game.forceDescription = [];
  game.forceFlags = [];
  game.forceCost = [];
  game.forceRange = [];
  game.forceAttack = [];
  game.forceDefense = [];
  game.forceCargoCapacity = [];
  game.forceCargoSpace = [];
  game.forceCarrierCapacity = [];
  game.forceCarrierSpace = [];
  game.forceLiftCapacity = [];
  game.forceLiftSpace = [];
  game.forceProduction = [];
  game.forceZ = [];
  game.forceScale = [];
  game.forceSfxMake = [];
  game.forceSfxMove = [];
  game.forceSfxDie = [];
  game.forceVersion = [];
  for(let i = 0; i < scenario.forceID.length; i++) {
    const forceTypeID = scenario.forceID[i];
    game.forceID.push(forceTypeCache[forceTypeID]._id);
    game.forceName.push(forceTypeCache[forceTypeID].name);
    game.forceNamePlural.push(forceTypeCache[forceTypeID].namePlural);
    game.forceDescription.push(forceTypeCache[forceTypeID].description);
    game.forceFlags.push(forceTypeCache[forceTypeID].flags);
    game.forceCost.push(forceTypeCache[forceTypeID].cost);
    game.forceRange.push(forceTypeCache[forceTypeID].range);
    game.forceAttack.push(forceTypeCache[forceTypeID].attack);
    game.forceDefense.push(forceTypeCache[forceTypeID].defense);
    game.forceCargoCapacity.push(forceTypeCache[forceTypeID].cargoCapacity);
    game.forceCargoSpace.push(forceTypeCache[forceTypeID].cargoSpace);
    game.forceCarrierCapacity.push(forceTypeCache[forceTypeID].carrierCapacity);
    game.forceCarrierSpace.push(forceTypeCache[forceTypeID].carrierSpace);
    game.forceLiftCapacity.push(forceTypeCache[forceTypeID].liftCapacity);
    game.forceLiftSpace.push(forceTypeCache[forceTypeID].liftSpace);
    game.forceProduction.push(forceTypeCache[forceTypeID].production);
    game.forceZ.push(forceTypeCache[forceTypeID].z);
    game.forceScale.push(forceTypeCache[forceTypeID].scale);
    game.forceSfxMake.push(forceTypeCache[forceTypeID].sfxMake);
    game.forceSfxMove.push(forceTypeCache[forceTypeID].sfxMove);
    game.forceSfxDie.push(forceTypeCache[forceTypeID].sfxDie);
    game.forceVersion.push(forceTypeCache[forceTypeID].version);
  }

  if(scenario.year) {
    game.year = scenario.year;
  }
  else {
    game.year = 2000;
  }

  if(doAutoPick && unpicked) {
    // We do all the auto-picking here if specified.  As it will not change based on
    // registered players, and will give a better pre-registration preview for the players.
    if( game.pickingOrder === BF.PICKING_ORDER_SKATTERED )
      doSkatteredAutoPick(game, scenario);
    else if( game.pickingOrder === BF.PICKING_ORDER_DISTRIBUTED )
      doDistributedAutoPick(game, scenario);
    else if( game.pickingOrder === BF.PICKING_ORDER_AUTO_123321321 )
      do123321321AutoPick(game, scenario);
    else if( game.pickingOrder === BF.PICKING_ORDER_AUTO_123123123 )
      do123123123AutoPick(game, scenario);
    else{
      // We do not currently support manual picking, so force to auto here:
      doSkatteredAutoPick(game, scenario);
    }
  }

  game.nextForceID = 1;
  // Create all the initial forces:
  for(let i = 1; i <= game.zones; i++) {
    if(bfH.isLand(game, i)) {
      let owner = game.zoneOwner[i];
      if(!owner)
        owner = 0;
      let len = scenario.startingForces[i].length;
      for(let j = 0; j < len; j++) {
        const count = scenario.startingForces[i][j];
        if(count) {
          if(game.forceFlags[j] & BF.FORCE_FLAG_STARTS_ON_LAND) {
            for(let k = 0; k < count; k++) {  // Add the force to land.
              const pos = getNewZoneXY(game, i);
              bfH.createNewForce(game, owner, j, i, i, pos.x, pos.y);
            }
          }
          else if(game.forceFlags[j] & BF.FORCE_FLAG_STARTS_ON_WATER) {
            if(scenario.navyZone[i]) {
              for(let k = 0; k < count; k++) {  // Add the force to water.
                const pos = getNewZoneXY(game, scenario.navyZone[i]);
                bfH.createNewForce(game, owner, j, scenario.navyZone[i],
                                   scenario.navyZone[i], pos.x, pos.y);
              }
            }
          }
        }
      }
    }
  }

  game.replay = {};
  game.created = new Date();
  game.started = '';
  game.ended = '';

  game.state = BF.GAME_STATE_REGISTERING;

  gameEnhanceData(game);
  return game;
}
