import React from 'react';
import { Button, Input, Modal, Segment } from 'semantic-ui-react';
import actions from '../../reducers/actions';
import { store } from '../../app/store';
import { sendPacket } from '../../network/network';
import { autoGenerateMapBorders, sanitizeMapDescription, sanitizeMapName } from '../../helper/map';


export default class GuiMapEditSave extends React.Component {

  state = {
    closeHandle: null,
    store: {},
  };

  constructor(props) {
    super(props);  // Call parent class' constructor.
    this.state.closeHandle = props.closeHandle;
    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.closeHandle !== this.state.closeHandle ||
        nextProps.store !== this.state.store) {
      this.setState({
        closeHandle: nextProps.closeHandle,
        store: nextProps.store,
      });
    }
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  cancelClose = (e) => {
    e.stopPropagation();
    if(this.state.closeHandle) {
      this.state.closeHandle();
    }
  }

  createNewMapClose = (e) => {
    e.stopPropagation();
    if(this.state.closeHandle) {
      this.state.closeHandle();
    }
		const { mapEdit } = this.state.store;

		const map = Object.assign({}, mapEdit);
		const message = autoGenerateMapBorders(map);

		if(message) {
			store.dispatch({
				type: actions.NOTIFY_MODAL_PUSH,
				title: 'New Map Needs Adjustment Before Saving',
				body: message,
			});
			return;
		}

		sendPacket('bf_mapSave', { map });
  };

  nameChange = e => {
    const name = sanitizeMapName(e.target.value);
    if(name) {
      store.dispatch({ type: actions.MAP_EDIT_NAME, name });
    }
  };

  descriptionChange = e => {
    const description = sanitizeMapDescription(e.target.value);
    if(description) {
      store.dispatch({ type: actions.MAP_EDIT_DESCRIPTION, description });
    }
  };

  render() {
    const { mapEdit } = this.state.store;

    return (
      <Modal
          onPointerDown={ this.stopPropagation }
          closeOnDimmerClick={ false }
          closeOnEscape={ false }
          dimmer='inverted'
          open={ true }
          size='small'>
        <Modal.Header>Save Map</Modal.Header>
        <Modal.Description>
          <div>
            <Segment key='mapName' compact style={{ float:'left' }} >
              <span>
                Map Name (maximum 47 characters):<br />
                <Input placeholder='Map Name...'
                       defaultValue={ mapEdit.name ? mapEdit.name : ' ' }
                       onChange={ this.nameChange }
                       style={{ width: '666px' }}
                />
              </span>
            </Segment>

            <Segment key='mapDescription' compact style={{ float:'left' }} >
              <span>
                Map Description (maximum 999 characters):<br />
                <Input placeholder='Map Description...'
                       defaultValue={ mapEdit.description ? mapEdit.description : ' ' }
                       onChange={ this.descriptionChange }
                       style={{ width: '666px' }}
                />
              </span>
            </Segment>

            <Segment key='cancelok' compact style={{ float:'left' }} >
              &nbsp;&nbsp;&nbsp;
              <Button onClick={ this.cancelClose } negative >
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={ this.createNewMapClose } positive >
                &nbsp;&nbsp;Save&nbsp;&nbsp;
              </Button>
            </Segment>

          </div>
        </Modal.Description>
      </Modal>
    );
  }
}
