/*
  ai/index.ts
  (c) Human Cube Inc.

  Interface between UI and standard BATTLE FORCES AI code.
*/

import cloneDeep from 'clone-deep';

import actions from '../reducers/actions';
import BF from '../bfcore/bfconst1';
import { Game } from '../bfcore/gameTypes';
import { WorkingData } from '../bfworking/workingTypes';
import { pickCapital, pickZone } from '../bfai/defaultpick';
import { aiTurn } from '../bfai/ai_turn';
// import { defaultturn } from './bfai/defaultturn';


const storeBuyForces = (
  storeDispatch: any,
  game: any,
  playerIndex: number,
  zoneID: number, forceTypeIndex: number, count: number,
) => {
  storeDispatch({
    type: actions.PURCHASE_SOME_FORCES,
    game,
    playerIndex,
    zoneID, forceTypeIndex, count,
  });
};

const storeMoveOne = (
  storeDispatch: any,
  game: any,
  playerIndex: number,
  forceID: number, zoneID: number, x: number, y: number,
) => {
  storeDispatch({
    type: actions.MOVE_FORCE_BY_ID,
    game,
    playerIndex,
    forceID, zoneID, x, y,
  });
}

const storeLoadOne = (
  storeDispatch: any,
  game: any,
  playerIndex: number,
  forceID: number, cargoForceID: number,
) => {
  storeDispatch({
    type: actions.LOAD_FORCE_BY_ID,
    game,
    playerIndex,
    forceID, cargoForceID,
  });
}

const storeUnloadOne = (
  storeDispatch: any,
  game: Game,
  playerIndex: number,
  forceID: number, zoneID: number, x: number, y: number, cargoForceID: number,
) => {
  storeDispatch({
    type: actions.UNLOAD_FORCE_BY_ID,
    game,
    playerIndex,
    forceID, zoneID, x, y, cargoForceID,
  });
}

const storeSetWorkingAI = (storeDispatch: any, ai: any) => {
  storeDispatch({
    type: actions.SET_WORKING_AI,
    ai,
  });
}

export const aiMyTurn = (storeDispatch: any, working: WorkingData, game: Game) => {
  const playerIndex = working.localPlayerIndex;
  console.log('AI My Turn for', playerIndex, 'has begun.');

  const turnID = game.turn;
  let turn: any = {};

  if (turnID >= BF.TURN_PICK_ZONE && turnID < BF.TURN_PICK_CAPITAL) {  // pick nation
    turn = pickZone(game, playerIndex);
  }
  else if (turnID === BF.TURN_PICK_CAPITAL) {  // pick capital
    turn = pickCapital(game, playerIndex);
  }
  else if (turnID >= BF.TURN_NORMAL) {  // normal turn
    turn = aiTurn(cloneDeep(working), game, true);  // Set to false to do one move stage at a time.
  }

  if(Array.isArray(turn.purchase)) {
    for (let i = 0; i < turn.purchase.length; i++) {
      const item = turn.purchase[i];
      storeBuyForces(storeDispatch, game, playerIndex, item[1], item[0], 1);
    }
  }

  Object.entries(turn.move).forEach(([key, value]) => {
    const forceID = parseInt(key, 10);
    if(forceID) {
      let array = (value as number[]);
      for (let i = 0; i < array.length; i += 4) {
        const zoneID = array[i];
        const x = array[i + 1];
        const y = array[i + 2];
        const cargoID = array[i + 3];
        if(cargoID === 0) {
          storeMoveOne(storeDispatch, game, playerIndex, forceID, zoneID, x, y);
        }
        else if (cargoID === -1) {
          storeLoadOne(storeDispatch, game, playerIndex, forceID, zoneID);
        }
        else {
          storeUnloadOne(storeDispatch, game, playerIndex, forceID, zoneID, x, y, cargoID);
        }
      }
    }
  });

  storeSetWorkingAI(storeDispatch, turn.ai);

  console.log('AI My Turn for', playerIndex, 'has ended.');
};
