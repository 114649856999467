import React from 'react';
import actions from '../../reducers/actions';
import { Flag } from 'semantic-ui-react';
import { Button, Step } from 'semantic-ui-react';
import { store } from '../../app/store';
import GuiForce from './GuiForce';
import BF from '../../bfcore/bfconst1';
import bfH from '../../bfcore/bf_helper.js';

function GuiForceBuyLine (props) {
  const { game, details, zoneID, localPlayerIndex, forceTypeIndex } = props;

  function buy (e) {
    store.dispatch({
      type: actions.PURCHASE_SOME_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID: zoneID,
      forceTypeIndex: forceTypeIndex,
      count: 1,
    });
  };

  function buy5 (e) {
    store.dispatch({
      type: actions.PURCHASE_SOME_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID: zoneID,
      forceTypeIndex: forceTypeIndex,
      count: 5,
    });
  };

  return (
    <div className='flex flex-row justify-between'>
      <div className='mt-1'>
        <b>{details.buy[forceTypeIndex] ? details.buy[forceTypeIndex] : 0}</b>&nbsp;&nbsp;
      </div>
      <GuiForce forceType={ game.forceID[forceTypeIndex] } />&nbsp;
      <div className='mt-1'>
        cost: <b>{game.forceCost[forceTypeIndex]}</b>&nbsp;
      </div>
      <div className='flex flex-row mb-1'>
        <Button key="buy" onPointerUp={buy} size="mini" compact> + 1 </Button>
        {!(game.forceFlags[forceTypeIndex] & BF.FORCE_FLAG_PROD_MAX_ONE_PER_ZONE) &&
          <Button key="buy5" onPointerUp={buy5} size="mini" compact> + 5 </Button>
        }
      </div>
    </div>
  );
}

function GuiForcePurchaseWindow (props) {
  const { game, details, zoneID, localPlayerIndex } = props;

  function stopPropagation (e) {
    e.stopPropagation();
  }

  function undoThesePurchases (e) {
    store.dispatch({
      type: actions.UNPURCHASE_ZONE_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID: zoneID,
    });
  };

  function hideForcePurchaseWindow (e) {
    store.dispatch({
      type: actions.HIDE_FORCE_PURCHASE_WINDOW,
    });
  }

  const buyLine = [];
  for(let f = 0; f < game.forceID.length; f++) {
    if((!details.hasFactory && game.forceProduction[f] > 0) ||
        (details.hasFactory && game.forceProduction[f] === 0)) {
      if((bfH.isLand(game, zoneID) && (game.forceFlags[f] & BF.FORCE_FLAG_STARTS_ON_LAND)) ||
          (bfH.isWater(game, zoneID) && (game.forceFlags[f] & BF.FORCE_FLAG_STARTS_ON_WATER))) {
        buyLine.push(
          <GuiForceBuyLine key={ 'f' + f } forceTypeIndex={ f } game={ game } details={ details }
            zoneID={ zoneID } localPlayerIndex={ localPlayerIndex }
          />
        );
      }
    }
  }

  return (
    <Step.Group onPointerDown={ stopPropagation }>
      <Step>
        <Step.Content>
          <Step.Title>{game.zoneName[zoneID]}</Step.Title>
          <Step.Description>
            <Flag name={bfH.zoneOwnerFlag(game, zoneID)} />
            {bfH.zoneOwnerName(game, zoneID)}<br />
            Cash:&nbsp;<b>{ details.cash }</b><br />
            Zone Production:&nbsp;
            { details.productionPoints < 1000000 ?
               (details.productionPoints > 0 ?
                  <b>{ details.productionPoints }</b> : <b style={{ color: '#FF0000'}}>0</b>)
               : <b>unlimited</b>
            }
            {buyLine}
            <br />
            <div>
              <Button key='undoAll' onPointerUp={ undoThesePurchases } size='medium'>
                Undo These Purchases
                </Button>
            </div>
            ----------------------------------------
            <div>
              <Button key='close' onPointerUp={hideForcePurchaseWindow} size='medium'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Close
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
            </div>
          </Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}


export default GuiForcePurchaseWindow;
