import BF from '../bfcore/bfconst1';

function twoHexDigits (n) {
	n &= 255;
  const hex = Number(n).toString(16).toUpperCase();
  if(hex.length < 2) {
    return '0' + hex;
  }
  return hex;
}

const ASCII_a = 'a'.charCodeAt(0);
const ASCII_z = 'z'.charCodeAt(0);
const ASCII_A = 'A'.charCodeAt(0);
const ASCII_Z = 'Z'.charCodeAt(0);
const ASCII_0 = '0'.charCodeAt(0);
const ASCII_9 = '9'.charCodeAt(0);

function isString (v) {
	return (typeof v === 'string' || v instanceof String);
}

export function sanitizeMapName (rawMapName) {
	// Returns a valid name (1 to BF.MAX_MAP_NAME chars).
	if( !isString(rawMapName) ) {
    rawMapName = '';
  }
  let mapName = '';
	let k = rawMapName.length;
	let i;
	for(i = 0; i < k; i++)
		if( rawMapName.charAt(i) !== ' ' )
			break;
	let lastspace = 0;
	for(; i < k; i++) {
		const c = rawMapName.charAt(i);
		const cc = c.charCodeAt(0);
		if( (cc >= ASCII_a && cc <= ASCII_z) || (cc >= ASCII_A && cc <= ASCII_Z) ||
				(cc >= ASCII_0 && cc <= ASCII_9) || c === '-' || c === ':' || c === '.' ) {
      if( lastspace && mapName !== ' ' )
          mapName += ' ';
      lastspace = 0;
	    mapName += c;
		}
		else if( c === ' ' )
  		lastspace = 1;
	}
	if(!mapName) {
		mapName = 'Untitled';
  }
  return mapName.substr(0, BF.MAX_MAP_NAME);
}

export function sanitizeMapDescription (rawMapDescription) {
	// Returns a valid description (0 to BF.MAX_MAP_DESCRIPTION chars).
	if( !isString(rawMapDescription) )
		rawMapDescription = '';
  let mapDescription = '';
	let k = rawMapDescription.length;
	let i;
	for(i = 0; i < k; i++)
		if( rawMapDescription.charAt(i) !== ' ' )
			break;
	let lastspace = 0;
	for(; i < k; i++) {
		const c = rawMapDescription.charAt(i);
		const cc = c.charCodeAt(0);
		if( (cc >= ASCII_a && cc <= ASCII_z) || (cc >= ASCII_A && cc <= ASCII_Z) ||
				(cc >= ASCII_0 && cc <= ASCII_9) || c === '-' || c === ':' || c === '.' ||
				c === ',' ) {
			mapDescription += c;
			lastspace = 0;
		}
		else if( c === ' ' ) {
			if( lastspace === 0 ) {
				lastspace = 1;
				mapDescription += ' ';
			}
		}
	}
 	return mapDescription.substr(0, BF.MAX_MAP_DESCRIPTION);
}

export function fillMapZoneNames (map) {
  // Fills default names for all zones that do not have a name.
  // Modifies map object.
  map.zoneName[0] = '';
  for(let j = 1; j <= map.zones; j++) {
    if(!map.zoneName[j]) {
      if(map.zoneFlags[j] & BF.ZONE_FLAG_LAND_ZONE) {
        map.zoneName[j] = 'Land Zone ' + j;
      }
      else {
        map.zoneName[j] = 'Water Zone ' + j;
      }
    }
	}
}

export function randMap (rr, xs, ys, c, wp, mapFlags, uid, uName) {
  // Range checks input parameters.
  // Returns a new map object.

  mapFlags &= (BF.MAP_FLAG_CAN_X_PAN_BOARD | BF.MAP_FLAG_CAN_Y_PAN_BOARD);
	let w = 0;
  if(mapFlags & BF.MAP_FLAG_CAN_X_PAN_BOARD) {
    w |= 1;
  }
  if(mapFlags & BF.MAP_FLAG_CAN_Y_PAN_BOARD) {
    w |= 2;
  }

	xs = Math.floor(xs / 2) * 2;
	if(xs < 10) {  // BF.MIN_HEX_XS is 2, but we will make 10 the reasonable minimum.
		xs = 10;
  }
	if(xs > BF.MAX_HEX_XS) {
    xs = BF.MAX_HEX_XS;
  }
	ys = Math.floor(ys / 2) * 2;
	if(ys < 10) {  // BF.MIN_HEX_YS is 2, but we will make 10 the reasonable minimum.
		ys = 10;
  }
	if(ys > BF.MAX_HEX_YS) {
    ys = BF.BF.MAX_HEX_YS;
  }

	c = Math.floor(c);
	if(c > xs * ys) {
    c = xs * ys;
  }
	if(c < 2) {
    c = 2;
  }
	if(c > BF.MAX_ZONES) {
    c = BF.MAX_ZONES;
  }

	wp = Math.floor(wp);
	if(wp < 0) {
    wp = 0;
  }
	if(wp > 99) {
    wp = 99;
  }

	rr = Math.floor(rr);
	if(rr < 7) {
    rr = 7;
  }
	if(rr > 100) {
    rr = 100;
  }

	const L = ys * xs;
	if(L < c) {
    c = L;
  }

	let zonesWater = Math.floor(c * wp / 100);
	if(c - zonesWater < 2) {
    zonesWater = c - 2;
  }
  const zonesLand = c - zonesWater;

	const now = new Date();

  const map = {
    _id: 0,
    uid,
    uName,
    parentID: 0,
    parentUID: 0,
    parentUName: '',
    parentName: '',
		name: uName + ' Map',
  	description: 'New map by ' + uName,
  	flags: mapFlags | BF.MAP_FLAG_CAN_SHOW_AS_BOARD | BF.MAP_FLAG_HEXMAP,
  	zones: c,
    zonesLand,
    zonesWater,
  	xs,
  	ys,
  	mapData: '',
  	zoneFlags: Array(c + 1).fill(0),
  	zoneName: Array(c + 1).fill(''),
  	bordersLand: [],
  	bordersWater: [],
  	plays: 0,
  	playedLast: now,
  	created: now,
  };

	let mapdat = Array(L).fill(0);

	for(let j = 1; j <= c; j++) {
		if(j <= zonesLand) {
      map.zoneFlags[j] = BF.ZONE_FLAG_LAND_ZONE;
    }
		else {
      map.zoneFlags[j] = BF.ZONE_FLAG_WATER_ZONE;
    }
		for(;;) {
			const k = Math.floor(Math.random() * L);
			if(mapdat[k] === 0) {
				mapdat[k] = j;
				break;
			}
		}
	}

	let k2;
  let dc = c;
	while( dc < L ) {
		for(let y = 0; y < ys; y++) {
			for(let x = 0; x < xs; x++) {
				let k = y*xs + x;
				if( mapdat[k] === 0 ) {
					switch( Math.floor(Math.random() * rr) ) {
						case 0:
							if( (y & 1) === 0 ) {
								if( y > 0 ) {
									if( mapdat[k-xs] ) {
										mapdat[k] = mapdat[k - xs];
										dc++;
									}
								}
								else if( w & 2 ) {
									k2 = (ys-1)*xs + x;
									if( mapdat[k2] ) {
										mapdat[k] = mapdat[k2];
										dc++;
									}
								}
							}
							else {
								if( y > 0 && x > 0 ) {
									if( mapdat[k-xs-1] ) {
										mapdat[k] = mapdat[k - xs - 1];
									    dc++;
									}
								}
								else if( y > 0 ) {
									if( w & 1 ) {
										k2 = (y-1)*xs + xs-1;
										if( mapdat[k2] ) {
											mapdat[k] = mapdat[k2];
											dc++;
										}
									}
								}
								else if( x > 0 ) {
									if( w & 2 ) {
										k2 = (ys-1)*xs + x-1;
										if( mapdat[k2] ) {
											mapdat[k] = mapdat[k2];
											dc++;
										}
									}
								}
							}
							break;
						case 1:
							if( (y&1) === 0 ) {
								if( y > 0 && x < xs-1 ) {
									if( mapdat[k-xs+1] ) {
										mapdat[k] = mapdat[k-xs+1];
										dc++;
									}
								}
								else if( y > 0 ) {
									if( w & 1 ) {
										k2 = (y-1)*xs;
										if( mapdat[k2] ) {
											mapdat[k] = mapdat[k2];
											dc++;
										}
									}
								}
								else if( x < xs-1 ) {
									if( w & 2 ) {
										k2 = (ys-1)*xs + x+1;
										if( mapdat[k2] ) {
											mapdat[k] = mapdat[k2];
											dc++;
										}
									}
								}
							}
							else {
								if( y > 0 ) {
									if( mapdat[k-xs] ) {
										mapdat[k] = mapdat[k-xs];
										dc++;
									}
								}
								else if( w & 2 ) {
									k2 = (ys-1)*xs + x;
									if( mapdat[k2] ) {
										mapdat[k] = mapdat[k2];
										dc++;
									}
								}
							}
							break;
						case 2:
							if( x < xs-1 ) {
								if( mapdat[k+1] ) {
									mapdat[k] = mapdat[k+1];
									dc++;
								}
							}
							else if( w & 1 ) {
								k2 = y*xs;
								if( mapdat[k2] ) {
									mapdat[k] = mapdat[k2];
									dc++;
								}
							}
							break;
						case 3:
							if( (y&1) === 0 ) {
								if( y < ys-1 && x < xs-1 ) {
									if( mapdat[k+xs+1] ) {
										mapdat[k] = mapdat[k+xs+1];
										dc++;
									}
								}
								else if( y < ys-1 ) {
									if( w & 1 ) {
										k2 = (y+1)*xs;
										if( mapdat[k2] ) {
											mapdat[k] = mapdat[k2];
											dc++;
										}
									}
								}
								else if( x < xs-1 ) {
									if( w & 2 ) {
										k2 = x+1;
										if( mapdat[k2] ) {
											mapdat[k] = mapdat[k2];
											dc++;
										}
									}
								}
							}
							else {
								if( y < ys-1 ) {
									if( mapdat[k+xs] ) {
										mapdat[k] = mapdat[k+xs];
										dc++;
									}
								}
								else {
									if( w & 2 ) {
										k2 = x;
										if( mapdat[k2] ) {
											mapdat[k] = mapdat[k2];
											dc++;
										}
									}
								}
							}
							break;
						case 4:
							if( (y&1) === 0 ) {
								if( y < ys-1 ) {
									if( mapdat[k+xs] ) {
										mapdat[k] = mapdat[k+xs];
										dc++;
									}
								}
								else {
									if( w & 2 ) {
										k2 = x;
										if( mapdat[k2] ) {
											mapdat[k] = mapdat[k2];
											dc++;
										}
									}
								}
							}
							else {
								if( y < ys-1 && x > 0 ) {
									if( mapdat[k+xs-1] ) {
										mapdat[k] = mapdat[k+xs-1];
										dc++;
									}
								}
								else if( y < ys-1 ) {
									if( w & 1 ) {
										k2 = (y+1)*xs + xs-1;
										if( mapdat[k2] ) {
											mapdat[k] = mapdat[k2];
											dc++;
										}
									}
								}
								else if( x > 0 ) {
									if( w & 2 ) {
										k2 = x-1;
										if( mapdat[k2] ) {
											mapdat[k] = mapdat[k2];
											dc++;
										}
									}
								}
							}
							break;
						case 5:
							if( x > 0 ) {
								if( mapdat[k-1] ) {
									mapdat[k] = mapdat[k-1];
									dc++;
								}
							}
							else if( w & 1 ) {
								k2 = y*xs + xs-1;
								if( mapdat[k2] ) {
									mapdat[k] = mapdat[k2];
									dc++;
								}
							}
							break;
            default:
              break;
					}
				}
			}
		}
	}

	for(let j = 0; j < L; j++) {
    map.mapData += twoHexDigits(mapdat[j]);
  }

  fillMapZoneNames(map);
	return map;
}

export function autoGenerateMapBorders (map) {
	// Returns '' on success, or error message on error if max number of borders is exceeded for a
	// zone or a zone has no borders.
  // Modifies map.
	map.bordersLand = [];
	map.bordersWater = [];
	for(let i = 0; i <= map.zones; i++) {
		map.bordersLand[i] = [];
		map.bordersWater[i] = [];
	}

	const _addBorder = function (map, zoneID, p) {
		const borderID = parseInt(map.mapData.substr(p * 2, 2), 16);
		if(borderID > 0 && borderID <= map.zones && borderID !== zoneID) {
			if(map.zoneFlags[borderID] & BF.ZONE_FLAG_LAND_ZONE) {
				for(let i = 0; i < map.bordersLand[zoneID].length; i++)
					if(map.bordersLand[zoneID][i] === borderID)
						return;
				map.bordersLand[zoneID].push(borderID);
			}
			else if(map.zoneFlags[borderID] & BF.ZONE_FLAG_WATER_ZONE) {
				for(let i = 0; i < map.bordersWater[zoneID].length; i++)
					if(map.bordersWater[zoneID][i] === borderID)
						return;
				map.bordersWater[zoneID].push(borderID);
			}
		}
	};

	// Generate Land and Water Borders:
	let i = 0;
	for(let y = 0; y < map.ys; y++) {
		for(let x = 0; x < map.xs; x++) {
			const z = parseInt(map.mapData.substr(i * 2, 2), 16);
			if( z > 0 && z <= map.zones ) {
				// x-1:
				var p = -1;
				if( x > 0 )
					p = i - 1;
				else if( map.flags & 8 )
					p = i + map.xs - 1;
				if( p >= 0 )
					_addBorder(map, z, p);

				// x+1:
				p = -1;
				if( x < map.xs - 1 )
					p = i + 1;
				else if( map.flags & 8 )
					p = i - x;
				if( p >= 0 )
					_addBorder(map, z, p);

				// y-1:
				p = -1;
				if( y > 0 )
					p = (y - 1) * map.xs + x;
				else if( map.flags & 16 )
					p = (map.ys - 1) * map.xs + x;
				if( p >= 0 )
					_addBorder(map, z, p);

				// y+1:
				p = -1;
				if( y < map.ys - 1 )
					p = i + map.xs;
				else if( map.flags & 16 )
					p = x;
				if( p >= 0 )
					_addBorder(map, z, p);

				if((y & 1) === 0)
					{
					// y+1 x+1:
					p = -1;
					if( y < map.ys - 1 && x < map.xs - 1 )
						p = i + map.xs + 1;
					else if( y < map.ys - 1 && (map.flags & 8) )
						p = (y + 1) * map.xs;
					else if( x < map.xs - 1 && (map.flags & 16) )
						p = x + 1;
					else if( (map.flags & 24) === 24 )
						p = 0;
					if( p >= 0 )
						_addBorder(map, z, p);

					// y-1 x+1:
					p = -1;
					if( y > 0 && x < map.xs - 1 )
						p = i - map.xs + 1;
					else if( y > 0 )
						{if( map.flags & 8 )
							p = (y - 1) * map.xs;
						}
					else if( x < map.xs - 1 )
						{if( map.flags & 16 )
							p = (map.ys - 1) * map.xs + x + 1;
						}
					else if( (map.flags & 24) === 24 )
						p = (map.ys - 1) * map.xs;
					if( p >= 0 )
						_addBorder(map, z, p);
					}
				else{
					// y+1 x-1:
					p = -1;
					if( y < map.ys - 1 && x > 0 )
						p = i + map.xs - 1;
					else if( y < map.ys - 1 )
						{if( map.flags & 8 )
							p = (y + 1) * map.xs + map.xs - 1;
						}
					else if( x > 0 )
						{if( map.flags & 16 )
							p = x - 1;
						}
					else if((map.flags & 24) === 24)
						p = map.xs - 1;
					if( p >= 0 )
						_addBorder(map, z, p);

					// y-1 x-1:
					p = -1;
					if(y > 0 && x > 0)
						p = i - map.xs - 1;
					else if(y > 0)
						{if( map.flags & 8 )
							p = i - map.xs + map.xs - 1;
						}
					else if(x > 0)
						{if( map.flags & 16 )
							p = (map.ys - 1) * map.xs + x - 1;
						}
					else if((map.flags & 24) === 24)
						p = (map.ys - 1) * map.xs + map.xs - 1;
					if( p >= 0 )
						_addBorder(map, z, p);
				}
			}
			i++;
		}
	}

	for(let i = 1; i <= map.zones; i++) {
		if( map.bordersLand[i].length + map.bordersWater[i].length <= 0 )
			return 'Zone ' + i + ' (' + map.zoneName[i] + ') has no borders';
		if(	map.bordersLand[i].length > 16 )
			return 'Zone ' + i + ' (' + map.zoneName[i] + ') has too many land borders';
		if( map.bordersWater[i].length > 16 )
			return 'Zone ' + i + ' (' + map.zoneName[i] + ') has too many water borders';
	}
	return '';
}
