import { store } from '../app/store';
import actions from './actions';

export function goBack () {
  const backStack = store.getState().backStack;
  if(backStack.length >= 2) {
    store.dispatch({type: actions.NAVIGATE_BACK, where: backStack[backStack.length - 2]});
    return;
  }
  store.dispatch({type: actions.GO_HOME});
}
