/*
  index.tsx
  (c) Human Cube Inc.
*/

import ReactDOM from 'react-dom/client';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import './css/main.css';
import 'semantic-ui-css/semantic.min.css';

import { store } from './app/store';
import { networkInit } from './network/network';
import actions from './reducers/actions';
import mainModes from './reducers/main-modes'
import RootWrapper from './routes/RootWrapper';
import ErrorPage from './routes/ErrorPage';
import { sendPacket } from './network/network';
// import reportWebVitals from './reportWebVitals';


kickThingsOff();

function kickThingsOff() {
  let soundOn = true;
  if (localStorage.getItem('soundOn') !== null && localStorage.getItem('soundOn') !== '1') {
    soundOn = false;
  }
  store.dispatch({ type: actions.SET_SOUND_ON_OFF, on: soundOn });

  let musicOn = true;
  if (localStorage.getItem('musicOn') !== null && localStorage.getItem('musicOn') !== '1') {
    musicOn = false;
  }
  store.dispatch({ type: actions.SET_MUSIC_ON_OFF, on: musicOn });

  networkInit();
}

function inGameMode(): boolean {
  switch (store.getState().mainMode) {
    case mainModes.GAME_LOADING:
    case mainModes.GAME_REPLAY:
    case mainModes.GAME_DOING_TURN:
    case mainModes.GAME_SUBMITTING_TURN:
    case mainModes.SCENARIO_PREVIEW:
    case mainModes.SCENARIO_EDIT:
    case mainModes.MAP_EDIT:
      return true;
    default:
      return false;
  }
}

function handleMouseWheel(delta: number) {
  store.dispatch({ type: actions.MAP_ZOOM, delta: delta });
}

document.addEventListener('mousewheel', function (e: any) {
  if (inGameMode()) {
    handleMouseWheel(e.wheelDelta);
    return false;
  }
});
// Grrr, FireFox mousewheel is different:
document.addEventListener('DOMMouseScroll', function (e: any) {
  if (inGameMode()) {
    handleMouseWheel(e.detail);
    return false;
  }
});


const RequireLoggedIn = () => {
  // Throws a redirect to the root page if a user is not logged in.
  if (store.getState().userSession.result !== 'success') {
    throw redirect('/');
  }
};


const router = createBrowserRouter([
  {
    path: '/',
    element: <RootWrapper />,
    errorElement: <ErrorPage />,
    loader: ({ params }) => {
      if (store.getState().userSession.result === 'success') {
        // User is authenticate so show home screen details.
        store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.HOME } });
        store.dispatch({ type: actions.GO_HOME });
        if (store.getState().news.length === 0) {
          sendPacket('bf_get', { e: 'newsFetch', count: 10 });
        }
      }
      return null;
    },
  },

  {
    path: 'blocklist',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.BLOCK_LIST } });
      sendPacket('bf_get', { e: 'blocks', meta: 1 });
      return null;
    },
  },
  {
    path: 'friendsfoes',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.FRIEND_FOE_LIST } });
      sendPacket('bf_get', { e: 'friends', meta: 1 });
      return null;
    },
  },

  {
    path: 'game/:gameID',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      if(params.gameID) {
        const gameID = parseInt(params.gameID);
        if(gameID) {
          store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.GAME_DETAIL, gameID } });
          sendPacket('bf_get', { e: 'chatMeta', gameID });
          sendPacket('bf_get', { e: 'gameString', gameID });
          // sendPacket('bf_get', { e: 'gameString', gameID: [gameID] });
        }
      }
      return null;
    },
  },

  {
    path: 'games/finished',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.FINISHED_GAMES } });
      sendPacket('bf_getUserFinishedList', {});
      return null;
    },
  },

  {
    path: 'join/multi',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.JOIN_MULTI } });
      sendPacket('bf_in', { e: 'registeringGames' });
      return null;
    },
  },
  {
    path: 'join/solo',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.JOIN_SOLO } });
      sendPacket('bf_in', { e: 'soloScenarios' });
      return null;
    },
  },

  {
    path: 'leaderboard/experience',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.LEADERBOARD_XP } });
      sendPacket('bf_get', {
        e: 'leaderboard',
        key: 'bfz_xp',
        start: 0,
        count: 100,
        meta: 1
      });
      return null;
    },
  },
  {
    path: 'leaderboard/score',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.LEADERBOARD_SCORE } });
      sendPacket('bf_get', {
        e: 'leaderboard',
        key: 'bfz_score',
        start: 0,
        count: 100,
        meta: 1
      });
      return null;
    },
  },

  {
    path: 'map/:mapID',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      if (params.mapID) {
        const mapID = parseInt(params.mapID);
        if (mapID) {
          store.dispatch({
            type: actions.NAVIGATE_TO,
            where: { mode: mainModes.MAP_DETAIL, mapID },
          });
        }
      }
      return null;
    },
  },

  {
    path: 'membership',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.MEMBERSHIP_OPTIONS } });
      return null;
    },
  },

  {
    path: 'mymaps',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.MY_MAPS } });
      return null;
    },
  },
  {
    path: 'myscenarios',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.MY_SCENARIOS } });
      return null;
    },
  },

  {
    path: 'news',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.NEWS } });
      sendPacket('bf_get', { e: 'newsFetch', count: 200 });
      return null;
    },
  },

  {
    path: 'player/:playerID',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      if (params.playerID) {
        const playerID = parseInt(params.playerID);
        if (playerID) {
          store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.PLAYER_DETAIL, playerID } });
        }
      }
      return null;
    },
  },

  {
    path: 'ranks',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.RANKS } });
      return null;
    },
  },

  {
    path: 'rules/conduct',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.CONDUCT } });
      return null;
    },
  },
  {
    path: 'rules/forcetypes',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.FORCE_TYPES } });
      return null;
    },
  },
  {
    path: 'rules/landwar',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.RULES_LAND_WAR } });
      return null;
    },
  },
  {
    path: 'rules/standard',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      store.dispatch({ type: actions.NAVIGATE_TO, where: { mode: mainModes.RULES_STANDARD } });
      return null;
    },
  },

  {
    path: 'scenario/:scenarioID',
    element: <RootWrapper />,
    loader: ({ params }) => {
      RequireLoggedIn();
      if (params.scenarioID) {
        const scenarioID = parseInt(params.scenarioID);
        if (scenarioID) {
          store.dispatch({
            type: actions.NAVIGATE_TO,
            where: { mode: mainModes.SCENARIO_DETAIL, scenarioID },
          });
        }
      }
      return null;
    },
  },

]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>  NOTE: StrictMode removed as it caused double load conflict with AdSense.
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  // </React.StrictMode>
);

/*
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
*/
