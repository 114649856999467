import React from 'react';
import { Button, Step } from 'semantic-ui-react';
import actions from '../../reducers/actions';
import { store } from '../../app/store';
import GuiForce from './GuiForce';

function GuiForceUnloadLine (props) {
  const { count, forceTypeIndex, game, localPlayerIndex, zoneID, zoneID2 } = props;

  function unloadOne (e) {
    store.dispatch({
      type: actions.UNLOAD_SOME_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID,
      zoneID2,
      forceTypeIndex,
      count: 1,
    });
  };

  function unloadAll (e) {
    store.dispatch({
      type: actions.UNLOAD_SOME_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID,
      zoneID2,
      forceTypeIndex,
      count,
    });
  };

  return (
    <div>
      <GuiForce forceType={ game.forceID[forceTypeIndex] } />&nbsp;
      <b>{ count }</b>&nbsp;
      <Button key='unloadOne' onPointerUp={ unloadOne } size='small' compact> Unload 1 </Button>
      <Button key='unloadAll' onPointerUp={ unloadAll } size='small' compact> Unload All </Button>
    </div>
  );
}

export default function GuiForceUnLoadWindow (props) {
  const { details, game, localPlayerIndex, zoneID, zoneID2 } = props;

  function stopPropagation (e) {
    e.stopPropagation();
  }

  function hideUnloadTransportsWindow (e) {
    store.dispatch({
      type: actions.HIDE_UNLOAD_TRANSPORTS_WINDOW,
    });
  }

  if(!details.hasCargo) {
    return (
      <Step.Group onPointerDown={ stopPropagation }>
        <Step>
          <Step.Content>
            <b>No remaining cargo to unload.</b>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }

  let unloadLine = [];
  for(let f = 0; f < game.forceID.length; f++) {
    const count = details.cargo[f]
    if(count > 0) {
      unloadLine.push(
        <GuiForceUnloadLine key={ 'f' + f } forceTypeIndex={ f } game={ game } zoneID={ zoneID }
          zoneID2={ zoneID2 } localPlayerIndex={ localPlayerIndex } count={ count }
        />
      );
    }
  }

  let blockedText = null;
  if(details.blockedSurfaceCount) {
    blockedText =
      <div style={{ color: 'red' }}>
        <br />
        <b>
          There are opposing forces in the transport's<br />
          zone that may block the unloading of cargo.
        </b>
      </div>;
  }

  return (
    <Step.Group onPointerDown={ stopPropagation }>
      <Step>
        <Step.Content>
          { unloadLine }
          { blockedText }
          <br />
          <Button onPointerUp={ hideUnloadTransportsWindow } size='medium'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Close&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Button>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}
