/*
  BaseScenarioDetailCreate.js
  (c) Human Cube Inc.
*/

import cloneDeep from 'clone-deep';
import React from 'react';
import { Button, Checkbox, Dropdown, Form, Modal, Segment } from 'semantic-ui-react';

import GuiForce from '../GameGui/GuiForce';
import actions from '../../reducers/actions';
import { store } from '../../app/store';
import mainModes from '../../reducers/main-modes'
import { log } from '../../helper/log';
import { forceTypeCache } from '../../helper/forceType';
import { loadHitmapIntoCache } from '../../map/hitmapCache';
import { createGamePreviewFromScenario, createScenarioFromMap, fillScenarioTotals }
       from '../../helper/scenario';
import BF from '../../bfcore/bfconst1';


function scenarioCreateFinalStep (scenarioEdit, selected, players, teams, gameFlags, pickingOrder,
                                  victoryCash, victoryCapitals, victoryIncome, victoryLandZones,
                                  mapMeta, uid, uName) {
  const forceID = [];
  for(let f = 1; f <= BF.MAX_FORCE_TYPES; f++) {
    if(selected & (1 << f)) {
      forceID.push(f);
    }
  }
  const startingForces = [[]];
  for(let z = 1; z <= scenarioEdit.zones; z++) {
    startingForces[z] = [];
    for(let f = 0; f < forceID.length; f++) {
      let c = 0;
      for(let fo = 0; fo < scenarioEdit.forceID.length; fo++) {
        if(scenarioEdit.forceID[fo] === forceID[f]) {
          c = scenarioEdit.startingForces[z][fo];
          if(!c) {
            c = 0;
          }
        }
      }
      startingForces[z][f] = c;
    }
  }
  scenarioEdit.forceID = forceID;
  scenarioEdit.startingForces = startingForces;

  scenarioEdit.gameFlags = gameFlags;
  scenarioEdit.pickingOrder = pickingOrder;

  if(scenarioEdit.players !== players) {
    scenarioEdit.players = players;

    scenarioEdit.playerFlags = [0];
    scenarioEdit.playerName.length = players + 1;
    scenarioEdit.playerName[0] = '';
    scenarioEdit.playerFlag.length = players + 1;
    scenarioEdit.playerFlag[0] = '';
    for(let p = 1; p <= players; p++) {
      scenarioEdit.playerFlags[p] = BF.PLAYER_FLAG_HUMAN;
      if(!scenarioEdit.playerName[p]) {
        scenarioEdit.playerName[p] = 'Player' + p;
      }
      if(!scenarioEdit.playerFlag[p]) {
        scenarioEdit.playerFlag[p] = '';
      }
    }

    if(scenarioEdit.capital.length >= players + 1) {
      scenarioEdit.capital.length = players + 1;
    }
    else {
      scenarioEdit.capital = Array(players + 1).fill(0);
    }

    if(scenarioEdit.cash.length >= players + 1) {
      scenarioEdit.cash.length = players + 1;
    }
    else {
      scenarioEdit.cash = Array(players + 1).fill(0);
    }

    scenarioEdit.forceBuyBits = [];
  }

  if(scenarioEdit.players !== players || scenarioEdit.teams !== teams) {
    scenarioEdit.teams = teams;
    if(teams > 1) {
      scenarioEdit.team.length = teams + 1;
      scenarioEdit.team[0] = 0;
      scenarioEdit.teamName.length = teams + 1;
      scenarioEdit.teamName[0] = '';
      for(let t = 1; t <= teams; t++) {
        if(!scenarioEdit.teamName[t]) {
          scenarioEdit.teamName[t] = 'Team ' + String.fromCharCode(65 - 1 + t);
        }
      }
      for(let p = 1; p <= players; p++) {
        scenarioEdit.team[p] = ((p - 1) % teams) + 1;
      }
    }
    else {
      scenarioEdit.team = [];
      scenarioEdit.teamName = [];
    }
  }

  scenarioEdit.victoryCapitals = victoryCapitals;
  scenarioEdit.victoryCash = victoryCash;
  scenarioEdit.victoryIncome = victoryIncome;
  scenarioEdit.victoryLandZones = victoryLandZones;

  fillScenarioTotals(scenarioEdit);
  store.dispatch({ type: actions.SCENARIO_EDIT_SET_SCENARIO, scenario: scenarioEdit });
  const game = createGamePreviewFromScenario(scenarioEdit, mapMeta, uid, uName, false);

  const localPlayerIndex = 0;
  store.dispatch({type: actions.SET_GAME, game, localPlayerIndex});
  const gameID = game._id;

  store.dispatch({
    type: actions.NAVIGATE_TO,
    where: {mode: mainModes.SCENARIO_EDIT, gameID}
  });
}

const playersOptions = [
  { key: 2, text: '2 Players', value: 2 },
  { key: 3, text: '3 Players', value: 3 },
  { key: 4, text: '4 Players', value: 4 },
  { key: 5, text: '5 Players', value: 5 },
  { key: 6, text: '6 Players', value: 6 },
  { key: 7, text: '7 Players', value: 7 },
  { key: 8, text: '8 Players', value: 8 },
  { key: 9, text: '9 Players', value: 9 },
  { key: 10, text: '10 Players', value: 10 },
  { key: 11, text: '11 Players', value: 11 },
  { key: 12, text: '12 Players', value: 12 },
  // { key: 13, text: '13 Players', value: 13 },
  // { key: 14, text: '14 Players', value: 14 },
  // { key: 15, text: '15 Players', value: 15 },
  // { key: 16, text: '16 Players', value: 16 },
  // Can actually go up to 30.
];

const pickingOrderOptions = [
  { key: BF.PICKING_ORDER_NO_PICKING, text: 'No Zone Picking', value: BF.PICKING_ORDER_NO_PICKING },
  { key: BF.PICKING_ORDER_DISTRIBUTED, text: 'Auto Distributed Zone Picking', value: BF.PICKING_ORDER_DISTRIBUTED },
  { key: BF.PICKING_ORDER_123321321, text: 'Manual 123321321 Zone Picking', value: BF.PICKING_ORDER_123321321 },
  { key: BF.PICKING_ORDER_123123123, text: 'Manual 123123123 Zone Picking', value: BF.PICKING_ORDER_123123123 },
  { key: BF.PICKING_ORDER_AUTO_123321321, text: 'Auto 123321321 Zone Picking', value: BF.PICKING_ORDER_AUTO_123321321 },
  { key: BF.PICKING_ORDER_AUTO_123123123, text: 'Auto 123123123 Zone Picking', value: BF.PICKING_ORDER_AUTO_123123123 },
  { key: BF.PICKING_ORDER_SCATTERED, text: 'Auto Scattered Zone Picking', value: BF.PICKING_ORDER_SCATTERED },
];


export default class BaseScenarioDetailCreate extends React.Component {

  state = {
    closeHandle: null,
    mapID: 0,  // optional prop, if specified, scenario is created from a map not another scenario.
    forceNew: false,  // optional prop, if specified, scenario will be new, not an edit.
    store: {},
    selected: 2046,
    players: 4,
    teams: 0,
    gameFlags: BF.GAME_FLAG_FACTORY_AT_CAPITAL | BF.GAME_FLAG_MUST_RECAPTURE_CAPITAL,
    pickingOrder: BF.PICKING_ORDER_DISTRIBUTED,
    victoryCash: 0,
    victoryCapitals: 4,
    victoryIncome: 0,
    victoryLandZones: 0,
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.
    this.state.mapID = props.mapID;
    this.state.forceNew = props.forceNew;
    this.state.closeHandle = props.closeHandle;
    this.state.store = props.store;

    if(props.mapID) {
      this.state.selected = 2046;  // Default to the standard 10 force types.

      this.state.players = 4;
      this.state.teams = 0;
      this.state.gameFlags = BF.GAME_FLAG_FACTORY_AT_CAPITAL | BF.GAME_FLAG_MUST_RECAPTURE_CAPITAL;
      this.state.pickingOrder = BF.PICKING_ORDER_DISTRIBUTED;

      this.state.victoryCash = 0;
      this.state.victoryCapitals = this.state.players;
      this.state.victoryIncome = 0;
      this.state.victoryLandZones = 0;
    }
    else {
      const { scenario } = props.store;
      this.state.players = scenario.players;
      this.state.teams = scenario.teams;
      this.state.gameFlags = scenario.gameFlags;
      this.state.pickingOrder = scenario.pickingOrder;

      this.state.victoryCash = scenario.victoryCash;
      this.state.victoryCapitals = scenario.victoryCapitals;
      this.state.victoryIncome = scenario.victoryIncome;
      this.state.victoryLandZones = scenario.victoryLandZones;

      this.state.selected = 0;
      for(let i = 0; i < scenario.forceID.length; i++) {
        this.state.selected |= (1 << scenario.forceID[i]);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.closeHandle !== this.state.closeHandle ||
        nextProps.mapID !== this.state.mapID ||
        nextProps.forceNew !== this.state.forceNew ||
        nextProps.store !== this.state.store) {
      const { mapID } = nextProps;
      const { scenario } = nextProps.store;
      let selected = 2046;  // New scenario, default to the standard 10 force types.
      let players = 4;
      let teams = 0;
      let gameFlags = BF.GAME_FLAG_FACTORY_AT_CAPITAL | BF.GAME_FLAG_MUST_RECAPTURE_CAPITAL;
      let pickingOrder = BF.PICKING_ORDER_DISTRIBUTED;
      let victoryCash = 0;
      let victoryCapitals = players;
      let victoryIncome = 0;
      let victoryLandZones = 0;

      if(!mapID) {
        selected = 0;
        for(let i = 0; i < scenario.forceID.length; i++) {
          selected |= (1 << scenario.forceID[i]);
        }

        players = scenario.players;
        teams = scenario.teams;
        gameFlags = scenario.gameFlags;
        pickingOrder = scenario.pickingOrder;
        victoryCash = scenario.victoryCapitals;
        victoryCapitals = scenario.victoryCash;
        victoryIncome = scenario.victoryIncome;
        victoryLandZones = scenario.victoryLandZones;
      }

      this.setState({
        closeHandle: nextProps.closeHandle,
        mapID,
        forceNew: nextProps.forceNew,
        store: nextProps.store,
        selected,
        players,
        teams,
        gameFlags,
        pickingOrder,
        victoryCapitals,
        victoryCash,
        victoryIncome,
        victoryLandZones,
      });
    }
  }

  cancelClose = () => {
    if(this.state.closeHandle) {
      this.state.closeHandle();
    }
  }

  createNewScenarioClose = (e) => {
    const { forceNew, mapID,
            selected, players, teams, gameFlags, pickingOrder,
            victoryCash, victoryCapitals, victoryIncome, victoryLandZones } = this.state;
    const { map, scenarioID, scenario, userSession } = this.state.store;
    const { uid, uName } = userSession;

    if(!selected) {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'No Force Types Selected',
        body: 'Please ensure there is at least one type of force for the new scenario.',
      });
      return;
    }

    if(this.state.closeHandle) {
      this.state.closeHandle();
    }

    if(mapID) {
      // Generate the new scenario from a map, not another scenario.
      const mapMeta = map[mapID];

      if(!mapMeta) {
        store.dispatch({
          type: actions.NOTIFY_MODAL_PUSH,
          title: 'Create Scenario',
          body: 'Unable to create scenario, please try again later.',
        });
        return;
      }

      loadHitmapIntoCache(mapMeta._id, mapMeta.flags, mapMeta.xs, mapMeta.ys, mapMeta.mapData,
                          function (err) {
        if(err) {
          log('Hitmap loading for map failed.');
          store.dispatch({
            type: actions.NOTIFY_MODAL_PUSH,
            title: 'Load Scenario Error',
            body: 'Hitmap loading for map failed.',
          });
          return;
        }

        const scenarioEdit = createScenarioFromMap(mapMeta, uid, uName);
        scenarioCreateFinalStep(scenarioEdit, selected, players, teams, gameFlags, pickingOrder,
                                victoryCash, victoryCapitals, victoryIncome, victoryLandZones,
                                mapMeta, uid, uName);
      });

      return;
    }

    // Generate the new scenario from an existing scenario.
    const mapMeta = map[scenario.mapID];
    if(!scenarioID || scenario._id !== scenarioID || !mapMeta) {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Create Scenario',
        body: 'Unable to create scenario, please try again later.',
      });
      return;
    }

    loadHitmapIntoCache(mapMeta._id, mapMeta.flags, mapMeta.xs, mapMeta.ys, mapMeta.mapData,
                        function (err) {
      if(err) {
        log('Hitmap loading for map failed.');
        store.dispatch({
          type: actions.NOTIFY_MODAL_PUSH,
          title: 'Load Scenario Error',
          body: 'Hitmap loading for map failed.',
        });
        return;
      }
      const scenarioEdit = cloneDeep(scenario);
      if(forceNew) {
        scenarioEdit._id = 0;
      }
      scenarioCreateFinalStep(scenarioEdit, selected, players, teams, gameFlags, pickingOrder,
                              victoryCash, victoryCapitals, victoryIncome, victoryLandZones,
                              mapMeta, uid, uName);
    });

  };

  checkChange = (e, { value }) => {
    this.setState({ selected: this.state.selected ^ (1 << value) });
  };

  checkGameFlagsChange = (e, { value }) => {
    this.setState({ gameFlags: this.state.gameFlags ^ value });
  };

  playersChange = (e, { value }) => {
    const players = value;
    let { teams } = this.state;

    if(players < 3 && teams > 0) {
      teams = 0;
    }
    if(players < 4 && teams > 2) {
      teams = 0;
    }

    this.setState({ players, teams });
  }

  pickingOrderChange = (e, { value }) => this.setState({ pickingOrder: value });

  teamsChange = (e, { value }) => this.setState({ teams: value });

  victoryCapitalsChange = (e, { value }) => this.setState({ victoryCapitals: value });
  victoryCashChange = (e, { value }) => this.setState({ victoryCash: value });
  victoryIncomeChange = (e, { value }) => this.setState({ victoryIncome: value });
  victoryLandZonesChange = (e, { value }) => this.setState({ victoryLandZones: value });

  render() {
    const { gameFlags, selected, pickingOrder, players, teams,
            victoryCapitals, victoryCash, victoryIncome, victoryLandZones } = this.state;
    const { incomeTotal, zonesLand } = this.state.store.scenario;

    const checks = [];
    for(let ft = 1; ft <= 10; ft++) {  // Increase 10 value if more forcetypes added.
      checks.push(
        <Form.Field key={'ft' + ft}>
          <Checkbox name={ forceTypeCache[ft].namePlural }
                    label={ <label>
                              <div className='flex flex-row'>
                                <GuiForce forceType={ ft } />
                                &nbsp;&nbsp;
                                { forceTypeCache[ft].namePlural }
                              </div>
                            </label> }
                    value={ ft }
                    checked={ (selected & (1<<ft)) ? true : false }
                    onChange={ this.checkChange } />
        </Form.Field>
      );
    }

    const teamsOptions = [ { key: 0, text: 'No Teams', value: 0 } ];
    if(players > 2) {
      teamsOptions.push( { key: 2, text: 'Two Teams', value: 2 } );
    }
    if(players > 3) {
      teamsOptions.push( { key: 3, text: 'Three Teams', value: 3 } );
    }

    let playersPerTeam = 1;
    if(teams > 0) {
      playersPerTeam = Math.ceil(players / teams);
    }
    const victoryCapitalsOptions = [ { key: 0, text: 'N / A', value: 0 } ];
    for(let p = playersPerTeam + 1; p <= players; p++) {
      victoryCapitalsOptions.push( { key: p, text: 'Own ' + p + ' Capitals', value: p } );
    }
    const victoryCashOptions = [ { key: 0, text: 'N / A', value: 0 } ];
    for(let p = 100; p <= 2000; p += 50) {
      victoryCashOptions.push( { key: p, text: 'Have ' + p + ' Cash', value: p } );
    }
    const victoryIncomeOptions = [ { key: 0, text: 'N / A', value: 0 } ];
    for(let p = Math.floor(incomeTotal / players * playersPerTeam) + 1; p <= incomeTotal; p++) {
      victoryIncomeOptions.push( { key: p, text: 'Income of at Least ' + p, value: p } );
    }
    const victoryLandZonesOptions = [ { key: 0, text: 'N / A', value: 0 } ];
    for(let p = Math.floor(zonesLand / players * playersPerTeam) + 1; p <= zonesLand; p++) {
      victoryLandZonesOptions.push( { key: p, text: 'Own ' + p + ' Land Zones', value: p } );
    }

    return (
      <Modal
          closeOnDimmerClick={ false }
          closeOnEscape={ false }
          dimmer='inverted'
          open={ true }
          size='small'>
        <Modal.Header>Create Scenario</Modal.Header>
        <Modal.Description>
          <div>
            <Segment key='forceTypes' compact style={{ float:'left' }} >
              <p>
                Available Force Types:
              </p>
              <Form>
                { checks }
              </Form>
            </Segment>
            <Segment key='players' compact style={{ float:'left' }} >
              <Dropdown
                onChange={ this.playersChange }
                options={ playersOptions }
                placeholder='Choose an option'
                selection
                value={ players }
              />
              <Dropdown
                onChange={ this.teamsChange }
                options={ teamsOptions }
                placeholder='Choose an option'
                selection
                value={ teams }
              />
            </Segment>
            <Segment key='capitals' compact style={{ float:'left' }} >
              <Form>
                <Form.Field>
                  <Checkbox name="CapitalsStartFactory"
                            label={ <label>Capitals Start with a Factory</label> }
                            value={ BF.GAME_FLAG_FACTORY_AT_CAPITAL }
                            checked={ (gameFlags & BF.GAME_FLAG_FACTORY_AT_CAPITAL) ? true : false }
                            onChange={ this.checkGameFlagsChange } />
                </Form.Field>
              </Form>
              <Form>
                <Form.Field>
                  <Checkbox name="CapitalLoss"
                            label={ <label>Players Must Retake Capital or be Eliminated</label> }
                            value={ BF.GAME_FLAG_MUST_RECAPTURE_CAPITAL }
                            checked={ (gameFlags & BF.GAME_FLAG_MUST_RECAPTURE_CAPITAL) ? true : false }
                            onChange={ this.checkGameFlagsChange } />
                </Form.Field>
              </Form>
            </Segment>

            <Segment key='pickingOrder' compact style={{ float:'left' }} >
              <Dropdown
                onChange={ this.pickingOrderChange }
                options={ pickingOrderOptions }
                placeholder='Choose an option'
                selection
                value={ pickingOrder }
              />
            </Segment>

            <Segment key='victory' compact style={{ float:'left' }} >
              <p>
                Early Victory Conditions:
              </p>
              <Dropdown
                onChange={ this.victoryCapitalsChange }
                options={ victoryCapitalsOptions }
                placeholder='Choose an option'
                selection
                value={ victoryCapitals }
              />
              <Dropdown
                onChange={ this.victoryCashChange }
                options={ victoryCashOptions }
                placeholder='Choose an option'
                selection
                value={ victoryCash }
              />
              <br />
              <Dropdown
                onChange={ this.victoryIncomeChange }
                options={ victoryIncomeOptions }
                placeholder='Choose an option'
                selection
                value={ victoryIncome }
              />
              <Dropdown
                onChange={ this.victoryLandZonesChange }
                options={ victoryLandZonesOptions }
                placeholder='Choose an option'
                selection
                value={ victoryLandZones }
              />
            </Segment>

            <Segment key='cancelnext' compact style={{ float:'left' }} >
              &nbsp;&nbsp;&nbsp;
              <Button onClick={ this.cancelClose } negative >
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={ this.createNewScenarioClose } positive >
                Next&nbsp;&nbsp;&nbsp;&gt;
              </Button>
            </Segment>

          </div>
        </Modal.Description>
      </Modal>
    );
  }
}
