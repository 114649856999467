import React from 'react';
import actions from '../../reducers/actions';
import { Button, Dropdown, Step } from 'semantic-ui-react';
import { store } from '../../app/store';
import GuiForce from './GuiForce';
import GuiForceTransport from './GuiForceTransport';

function GuiForceLoadLine (props) {
  const {
    game, zoneID, zoneID2, localPlayerIndex, forceTypeIndex, count, selectedTransportCargoKey,
  } = props;

  function loadOne (e) {
    store.dispatch({
      type: actions.LOAD_SOME_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID: zoneID,
      transportZoneID: zoneID2,
      forceTypeIndex: forceTypeIndex,
      count: 1,
      transportCargoKey: selectedTransportCargoKey,
    });
  };

  function loadAll (e) {
    store.dispatch({
      type: actions.LOAD_SOME_FORCES,
      game,
      playerIndex: localPlayerIndex,
      zoneID: zoneID,
      transportZoneID: zoneID2,
      forceTypeIndex: forceTypeIndex,
      count: count,
      transportCargoKey: selectedTransportCargoKey,
    });
  };

  return (
    <div className='flex flex-row'>
      <GuiForce forceType={ game.forceID[forceTypeIndex] } />&nbsp;
      <div className='mt-1'>
        <b>{count}</b>&nbsp;
      </div>
      <Button key="loadOne" onPointerUp={ loadOne } size="small" compact> Load 1 </Button>
      <Button key="loadAll" onPointerUp={ loadAll } size="small" compact> Load All </Button>
    </div>
  );
}


export default class GuiForceLoadWindow extends React.Component {

  state = {
    props: {},
    selectedTransportCargoKey: '',
  };

  calculateSelectedTransportCargoKey (props) {
    const { details } = props;
    const prevSelectedTransportCargoKey = this.state.selectedTransportCargoKey;

    let suggestedTransportCargoKey = '';
    let suggestedTransportScore = 0;
    for (var key in details.transportsByCargo2) {
      // key is:  transportForceTypeIndex:range:loadPoints:cargotypeindex1:cargotypeindex2 ...
      const parts = key.split(':');
      const range = parseInt(parts[1]);
      const loadPoints = parseInt(parts[2]);
      if(loadPoints > 0) {
        const score = range * 1000000 + loadPoints;
        if(score >= suggestedTransportScore) {
          suggestedTransportCargoKey = key;
          suggestedTransportScore = score;
        }
        if(key === prevSelectedTransportCargoKey) {
          suggestedTransportCargoKey = key;
          suggestedTransportScore = 1000000000;
        }
      }
    }

    return suggestedTransportCargoKey;
  }

  constructor(props) {
    super(props);  // Always call the parent class' constructor.

    this.state.props = props;
    this.state.selectedTransportCargoKey = this.calculateSelectedTransportCargoKey(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const selectedTransportCargoKey = this.calculateSelectedTransportCargoKey(nextProps);
    this.setState({
      props: nextProps,
      selectedTransportCargoKey,
    });
  }

  stopPropagation (e) {
    e.stopPropagation();
  }

  onChangeTransport = (e, data) => {
    this.setState({
      selectedTransportCargoKey: data.value
    });
  };

  hideLoadTransportsWindow (e) {
    store.dispatch({
      type: actions.HIDE_LOAD_TRANSPORTS_WINDOW,
    });
  }

  render () {
    const { selectedTransportCargoKey, props } = this.state;
    const { game, details, zoneID, zoneID2, localPlayerIndex } = props;

    let loadLine = [];
    for(let f = 0; f < game.forceID.length; f++) {
      const r = game.forceRange[f];  // Only those with maximum range.
      const count = details.forcesByRange[localPlayerIndex][r][f];
      if(count <= 0) {
        continue;
      }
      if(game.forceCargoSpace[f] >= 0) {
        loadLine.push(
          <GuiForceLoadLine key={ 'f' + f } forceTypeIndex={ f } game={ game } zoneID={ zoneID }
            zoneID2={ zoneID2 } localPlayerIndex={ localPlayerIndex } count={ count }
            selectedTransportCargoKey={ selectedTransportCargoKey }
          />
        );
      }
    }

    if(loadLine.length < 1) {
      loadLine =
        <div>
          <b>No available loadable forces.</b><br />
          Forces must have full available<br />
          range to be able to be loaded.<br />
        </div>;
    }

    const transportOptions = [];

    for (var key in details.transportsByCargo2) {
      const count = details.transportsByCargo2[key];
      // key is:  transportForceTypeIndex:range:loadPoints:cargotypeindex1:cargotypeindex2 ...
      const parts = key.split(':');
      const loadPoints = parseInt(parts[2]);
      if(loadPoints > 0) {
        transportOptions.push({
          key: key,
          text:
            <GuiForceTransport
              key={ 'gfto' + key }
              transportCargoKey={ key }
              game={ game }
              count={ count }
            />,
          value: key,
        });
      }
    }

    if(details.zone2LoadPoints > 0) {
      return (
        <Step.Group onPointerDown={ this.stopPropagation }>
          <Step>
            <Step.Content>
              Destination Transport Force:
              <Dropdown
                fluid
                selection
                value={ selectedTransportCargoKey }
                options={ transportOptions }
                onChange={ this.onChangeTransport }
              />
              Load Points Remaining: { details.zone2LoadPoints }<br />
              Loadable Forces:<br />
              { loadLine }
              <br />
              <Button onPointerUp={ this.hideLoadTransportsWindow } size="medium">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Close&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
            </Step.Content>
          </Step>
        </Step.Group>
      );
    }

    return (
      <Step.Group onPointerDown={ this.stopPropagation }>
        <Step>
          <Step.Content>
            <b>No remaining load points.</b><br />
            These transports are unable to<br />
            load any more cargo this turn.<br />
            <br />
            <Button onPointerUp={ this.hideLoadTransportsWindow } size="medium">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Close&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }
}
