/*
  GuiZone.js
  (c) Human Cube Inc.
*/

import React from 'react';
import GuiForcePurchaseWindow from './GuiForcePurchaseWindow';
import GuiForceRetreatWindow from './GuiForceRetreatWindow';
import GuiLandingHereWindow from './GuiLandingHereWindow';
import GuiLandingListWindow from './GuiLandingListWindow';
import GuiLandingSpecifyWindow from './GuiLandingSpecifyWindow';
import GuiLoadTransportsWindow from './GuiLoadTransportsWindow';
import GuiUnloadTransportsWindow from './GuiUnloadTransportsWindow';
import GuiZoneDouble from './GuiZoneDouble';
import GuiZoneSingle from './GuiZoneSingle';
import GuiZoneMoveInfo from './GuiZoneMoveInfo';
import mainModes from '../../reducers/main-modes';
// import { log } from '../../helper/log';
import BF from '../../bfcore/bfconst1';


function forceDetailsForZone (game, working, zoneID, zoneID2, replayStage) {
  // Returns an object of info based on the current game state adjusted with local moves for the
  // given zone.
  // If in replay mode, it will gather info based on the current replay stage. Pass in a
  // replayStage of -1 if not in replay mode.
  const ret = {
    forcesByRange: [], // [playerIndex][range][forceTypeIndex]
    forcesTotal: [],   // [playerIndex][forceTypeIndex] (not to be confused with forceCount).
    forcesUndoable: 0, // Number of forces in this zone for localPlayerIndex that can undo movement.
    playersWithForces: [], // List of playerIndexes of players that have one or more forces here.
    forceCount: [],   // Number of forces per player in zone (not to be confused with forcesTotal).
    hasFactory: false,    // this zone has a factory or not.
    productionPoints: 0,  // Production points for localPlayerIndex.
    cash: 0,
    buy: {}, // {fti:0, fti:0, fti:0, ... } indexed by forceTypeIndex,
             // count of purchases by localPlayerIndex for this zone.
    zone2Transports: 0,   // Number of friendly transports in zone2 for localPlayerIndex.
    zone2LoadPoints: 0,   // Total available load points in zone2 for localPlayerIndex.
    hasCargo: false,  // localPlayerIndex has cargo in zone1.
    cargo: [],  // [forceTypeIndex] indexed by forceTypeIndex,
                // count of cargo of localPlayerIndex's on transports in zone1.
    transportsByCargo: {},  // In zone1 for local player only and not during replay:
                // [forceTypeIndex:Range:loadPoints:Cargo1TypeIndex:Cargo2TypeIndex...]: count
                // Cargo sorted type forceTypeIndex.
    transportsByCargo2: {},  // In zone2 for local player only and not during replay:
                // [forceTypeIndex:Range:loadPoints:Cargo1TypeIndex:Cargo2TypeIndex...]: count
                // Cargo sorted type forceTypeIndex.
    transportsByCargoSimple: {},  // In zone1, all players all transports and cargo if applicable.
                // Has owner, but not any range or load information.
                // [owner:forcetypeIndex:Cargo1TypeIndex:Cargo2TypeIndex...]
    moveableCount: [],   // number of forces that can move per player (excludes retreats).
    retreatableCount: [],   // number of forces that can retreat per player.
    landingCount: 0,  // number of aircraft localPlayerIndex has set to land here.
    aircraftCount: 0, // number of aircraft in this zone that need to land for localPlayerIndex.
    blockedAirCount: 0,   // number of opposing forces blocking air movement in this zone.
    blockedSurfaceCount: 0,  // number of opposing forces blocking surface movement in this zone.
  };

  if(!game || !working || !zoneID) {
    return ret;
  }

  const force = working.force;
  const localPlayerIndex = working.localPlayerIndex;

  for(let p = 0; p <= game.players; p++) {
    ret.forcesByRange[p] = [];
    ret.forcesTotal[p] = [];
    ret.forceCount[p] = 0;
    ret.moveableCount[p] = 0;
    ret.retreatableCount[p] = 0;
    for(let f = 0; f < game.forceID.length; f++) {
      ret.forcesTotal[p].push(0);
    }
    for(let r = 0; r <= BF.FORCE_MAX_MOVES; r++) {
      ret.forcesByRange[p].push([]);
      for(let f = 0; f < game.forceID.length; f++) {
        ret.forcesByRange[p][r].push(0);
      }
    }
  }

  // create cargo array.
  for(let ft = 0; ft < game.forceID.length; ft++) {
    ret.cargo.push(0);
  }

  if(replayStage < 0) {
    // Normal mode, not a replay.

    for(let f = 0; f < force.length; f++) {
      if(force[f].z === zoneID) {
        const forceOwner = force[f].owner;
        const forceType = force[f].ft;

        ret.forcesByRange[forceOwner][force[f].r][forceType]++;
        ret.forcesTotal[forceOwner][forceType]++;
        ret.forceCount[forceOwner]++;

        if(force[f].move.length > 0) {  // force has already moved, so could be undo'ed.
          ret.forcesUndoable++;
        }

        if(force[f].r > 0) {
          ret.moveableCount[forceOwner]++;
        }
        if(force[f].zr) {
          ret.retreatableCount[forceOwner]++;
        }

        if(forceOwner === localPlayerIndex) {
          const forceID = force[f].id;
          const cargoForceZoneID = BF.FORCE_ZONE_CARGO - forceID;
          for(let c = 0; c < force.length; c++) {
            if(force[c].z === cargoForceZoneID) {
              ret.cargo[force[c].ft]++;
              ret.hasCargo = true;
            }
          }

          if(game.forceFlags[forceType] & BF.FORCE_FLAG_MUST_LAND_FRIENDLY) {
            ret.aircraftCount++;
          }
        }
        else if(game.teams < 2 || game.team[localPlayerIndex] !== game.team[forceOwner]) {
          // Not a localPlayer's force nor a team member's force.

          if(game.forceFlags[forceType] & BF.FORCE_FLAG_BLOCKS_AIR) {
            ret.blockedAirCount++;
          }
          if(game.forceFlags[forceType] & BF.FORCE_FLAG_BLOCKS_SURFACE) {
            ret.blockedSurfaceCount++;
          }
        }
      }
    }
    for(let forceIndex in working.landing) {
      if( working.landing[forceIndex] === zoneID ) {
        ret.landingCount++;
      };
    }
  }
  else {
    // Replay mode, so calculate working values from replay position.

    for(let f = 0; f < force.length; f++) {
      if(force[f].rz[replayStage] === zoneID) {
        const forceOwner = force[f].owner;
        const forceType = force[f].ft;

        // ret.forcesByRange[forceOwner][force[f].r][forceType]++;
        ret.forcesTotal[forceOwner][forceType]++;
        ret.forceCount[forceOwner]++;
      }
    }
  }

  for(let p = 0; p <= game.players; p++) {
    for(let f = 0; f < game.forceID.length; f++) {
      if(ret.forcesTotal[p][f]) {
        ret.playersWithForces.push(p);
        break;
      }
    }
  }

  if(game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE) {
    if(working.hasFactory[zoneID]) {
      ret.hasFactory = true;
    }
    ret.productionPoints = working.production[zoneID];
  }
  else if(game.zoneFlags[zoneID] & BF.ZONE_FLAG_WATER_ZONE) {
    // Water zone, so sum of all potential production of land neighbours.
    for(let b = 0; b < game.bordersLand[zoneID].length; b++) {
      const borderID = game.bordersLand[zoneID][b];
      if(game.zoneOwner[borderID] === localPlayerIndex && working.hasFactory[borderID]) {
        ret.hasFactory = true;
        ret.productionPoints += working.production[borderID];
      }
    }
  }

  ret.cash = working.cash;
  if(zoneID2) {
    ret.buy = working.buy[zoneID2];
  }
  else {
    ret.buy = working.buy[zoneID];
  }
  if(!ret.buy) {
    ret.buy = {};
  }

  if(zoneID2) {
    for(let f = 0; f < force.length; f++) {
      if(force[f].z === zoneID2 && force[f].owner === localPlayerIndex) {
        const forceType = force[f].ft;
        if(game.forceCargoCapacity[forceType] > 0) {
          ret.zone2Transports++;
          ret.zone2LoadPoints += force[f].lp;
        }
      }
    }
  }

  if(replayStage < 0) {
    // Normal mode, not a replay.

    // Generate: ret.transportsByCargo: {},
    // [forceTypeIndex:Range:Cargo1TypeIndex:Cargo2TypeIndex...]: count
    // Cargo sorted type forceTypeIndex.
    for(let f = 0; f < force.length; f++) {
      if(force[f].z === zoneID && force[f].owner === localPlayerIndex) {
        const forceType = force[f].ft;
        if(game.forceCargoCapacity[forceType] > 0) {
          const cargo = [];
          const cargoZoneID = BF.FORCE_ZONE_CARGO - force[f].id;
          for(let c = 0; c < force.length; c++) {
            if(force[c].z === cargoZoneID) {
              cargo.push(force[c].ft);
            }
          }
          const key = forceType + ':' + force[f].r + ':' + force[f].lp + ':' + cargo.sort().join(':');
          if(!ret.transportsByCargo[key]) {
            ret.transportsByCargo[key] = 0;
          }
          ret.transportsByCargo[key]++;
        }
      }
    }

    // Generate: transportsByCargoSimple: {},
    // In zone1, all players all transports and cargo if applicable.
    // Has owner, but not any range or load information.
    // [owner:forcetypeIndex:Cargo1TypeIndex:Cargo2TypeIndex...]
    for(let f = 0; f < force.length; f++) {
      if(force[f].z === zoneID) {
        const owner = force[f].owner;
        const forceType = force[f].ft;
        if(game.forceCargoCapacity[forceType] > 0) {
          const cargo = [];
          const cargoZoneID = BF.FORCE_ZONE_CARGO - force[f].id;
          for(let c = 0; c < force.length; c++) {
            if(force[c].z === cargoZoneID) {
              cargo.push(force[c].ft);
            }
          }
          const key = owner + ':' + forceType + ':' + cargo.sort().join(':');
          if(!ret.transportsByCargoSimple[key]) {
            ret.transportsByCargoSimple[key] = 0;
          }
          ret.transportsByCargoSimple[key]++;
        }
      }
    }

  }
  else {
    // Replay mode.

    // Generate: transportsByCargoSimple: {},
    // In zone1, all players all transports and cargo if applicable.
    // Has owner, but not any range or load information.
    // [owner:forcetypeIndex:Cargo1TypeIndex:Cargo2TypeIndex...]
    for(let f = 0; f < force.length; f++) {
      if(force[f].rz[replayStage] === zoneID) {
        const owner = force[f].owner;
        const forceType = force[f].ft;
        if(game.forceCargoCapacity[forceType] > 0) {
          const cargo = [];
          const cargoZoneID = BF.FORCE_ZONE_CARGO - force[f].id;
          for(let c = 0; c < force.length; c++) {
            if(force[c].rz[replayStage] === cargoZoneID) {
              cargo.push(force[c].ft);
            }
          }
          const key = owner + ':' + forceType + ':' + cargo.sort().join(':');
          if(!ret.transportsByCargoSimple[key]) {
            ret.transportsByCargoSimple[key] = 0;
          }
          ret.transportsByCargoSimple[key]++;
        }
      }
    }

  }

  if(zoneID2) {
    // Generate: ret.transportsByCargo2: {},
    // [forceTypeIndex:Range:Cargo1TypeIndex:Cargo2TypeIndex...]: count
    // Cargo sorted type forceTypeIndex.
    for(let f = 0; f < force.length; f++) {
      if(force[f].z === zoneID2 && force[f].owner === localPlayerIndex) {
        const forceType = force[f].ft;
        if(game.forceCargoCapacity[forceType] > 0) {
          const cargo = [];
          const cargoZoneID = BF.FORCE_ZONE_CARGO - force[f].id;
          for(let c = 0; c < force.length; c++) {
            if(force[c].z === cargoZoneID) {
              cargo.push(force[c].ft);
            }
          }
          const key = forceType + ':' + force[f].r + ':' + force[f].lp + ':' +
                      cargo.sort().join(':');
          if(!ret.transportsByCargo2[key]) {
            ret.transportsByCargo2[key] = 0;
          }
          ret.transportsByCargo2[key]++;
        }
      }
    }
  }

  // TODO: Later we can include lift and same zone transport information so we can do lift and same
  //       zone loading and unloading.

  // log('forceDetailsForZone');
  // log(ret);

  return ret;
}


export default class GuiZone extends React.Component {
  state = {
    store: {},
    details: {},
  };

  constructor(props) {
    super(props);  // Required step: always call the parent class' constructor

    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store !== this.state.store) {
      this.setState({
        store: nextProps.store,
      });
    }
  }

  render() {
    const { game, mainMode, replayStage, showLandingSpecifyWindow, working } = this.state.store;
    let zoneID = this.state.store.selectedZoneIDs[0];
    let zoneID2 = this.state.store.selectedZoneIDs[1];

    let { localPlayerIndex } = working;
    if(mainMode !== mainModes.GAME_DOING_TURN) {
      localPlayerIndex = -1;  // Just general display unless doing turn.
    }

    if(this.state.store.showForceRetreatWindow) {
      return <GuiForceRetreatWindow zoneID={ zoneID }
                                    game={ game }
                                    localPlayerIndex={ localPlayerIndex }
                                    working={ working } />;
    }

    if(showLandingSpecifyWindow.show) {
      return <GuiLandingSpecifyWindow zoneID={ zoneID }
                                      game={ game }
                                      localPlayerIndex={ localPlayerIndex }
                                      working={ working }
                                      forceTypeIndex={ showLandingSpecifyWindow.forceTypeIndex }
                                      range={ showLandingSpecifyWindow.range }
                                      destination={ showLandingSpecifyWindow.destination }
             />;
    }
    if(this.state.store.showLandingListWindow) {
      return <GuiLandingListWindow zoneID={ zoneID }
                                   game={ game }
                                   localPlayerIndex={ localPlayerIndex }
                                   working={ working } />;
    }
    if(this.state.store.showLandingHereWindow) {
      return <GuiLandingHereWindow zoneID={ zoneID }
                                   game={ game }
                                   localPlayerIndex={ localPlayerIndex }
                                   working={ working } />;
    }

    let details = forceDetailsForZone(game, working, zoneID, zoneID2,
                                      mainMode === mainModes.GAME_REPLAY ? replayStage : -1);

    if(this.state.store.showForcePurchaseWindow) {
      return <GuiForcePurchaseWindow zoneID={ zoneID } game={ game } details={ details }
                                     localPlayerIndex={ localPlayerIndex } />;
    }

    let otherWindow = null;
    if(this.state.store.showLoadTransportsWindow) {
      otherWindow =
        <GuiLoadTransportsWindow zoneID={zoneID} zoneID2={zoneID2} game={game} details={details}
          localPlayerIndex={localPlayerIndex}
        />;
    }
    else if(this.state.store.showUnloadTransportsWindow) {
      otherWindow =
        <GuiUnloadTransportsWindow zoneID={zoneID} zoneID2={zoneID2} game={game} details={details}
          localPlayerIndex={localPlayerIndex}
        />;
    }
    else {
      otherWindow =
        <GuiZoneMoveInfo zoneID={ zoneID } zoneID2={ zoneID2 } game={ game } details={ details }
          localPlayerIndex={ localPlayerIndex }
        />;
    }

    if(zoneID2 > 0) {
      return (
        <div>
          <GuiZoneDouble zoneID={ zoneID } zoneID2={ zoneID2 } game={ game } />
          <div>{ otherWindow }</div>
        </div>
      );
    }

    if(zoneID > 0) {
      return <GuiZoneSingle zoneID={ zoneID } game={ game } details={ details }
                            localPlayerIndex={ localPlayerIndex }
                            mainMode={ mainMode } replayStage={ replayStage }
                            working={ working } />;
    }

    return null;
  }
}
