/*
  GuiCountdown.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Label } from 'semantic-ui-react';

import bfH from '../../bfcore/bf_helper.js';


class GuiCountdown extends React.Component {

  state = {
    counter: 1,
    date: null,
    serverOffsetMS: 0,
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.

    this.state.date = props.date;
    this.state.offsetMS = props.offsetMS;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.date !== this.state.date ||
        nextProps.offsetMS !== this.state.offsetMS) {
      this.setState({
        date: nextProps.date,
        offsetMS: nextProps.offsetMS,
      });
    }
  }

  componentDidMount() {
    this.setIntervalHandler = window.setInterval(function () {
      this.setState(state => ({
        ...state,
        counter: state.counter + 1,
      }));
    }.bind(this), 250);  // force gui update 4 times a second (for the countdown timer).
  }

  componentWillUnmount() {
    // Remove the setInterval function from above:
    if(this.setIntervalHandler) {
      clearInterval(this.setIntervalHandler);
      this.setIntervalHandler = null;
    }
  }

  msLeft (timeLimit, serverTimeOffsetMS) {
    if(!timeLimit) {
      return 0;
    }
    return timeLimit - (new Date().getTime() + serverTimeOffsetMS);
  }

  render() {
    const { date, offsetMS } = this.state;

    if(date === null) {
      return null;
    }

    const msTimeLimit = this.msLeft(date, offsetMS);
    let timeLimitColor = 'teal';
    if(msTimeLimit < 5*60*1000) {
      timeLimitColor = 'red';
    }
    else if(msTimeLimit < 10*60*1000) {
      timeLimitColor = 'yellow';
    }

    return <Label color={ timeLimitColor }>{ bfH.msToString(msTimeLimit) }</Label>;
  }
}


export default GuiCountdown;
