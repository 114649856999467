
const actions = {
  SET_MAIN_MODE: 1,
  NAVIGATE_TO: 2,
  NAVIGATE_BACK: 3,
  GO_HOME: 4,

  NOTIFY_MODAL_POP: 10,
  NOTIFY_MODAL_PUSH: 11,

  ADD_PLAYER_STRINGS: 40,
  ADD_PLAYER_INFOS: 41,
  ADD_PLAYER_STAT: 42,

  SUBMIT_LOGIN: 50,
  SET_LOGIN_ERROR_MESSAGE: 51,
  SET_USER_SESSION_DATA: 52,
  USER_SESSION_UPDATE_SOME_BF: 53,

  SET_LEADERBOARD_SCORE: 54,
  SET_LEADERBOARD_XP: 55,
  SET_LEADERBOARD_MAP_PLAYS: 56,
  SET_LEADERBOARD_SCENARIO_PLAYS: 57,

  SET_BLOCK_LIST: 58,
  ADD_BLOCK_ITEM: 59,
  REMOVE_BLOCK_ITEM: 60,

  SET_FRIEND_FOE_LIST: 61,
  ADD_FRIEND_FOE_ITEM: 62,
  REMOVE_FRIEND_FOE_ITEM: 63,

  MEMBERSHIP_CHANGE: 64,

  SET_SOLO_SCENARIOS: 65,
  SET_REGISTERING_GAMES: 66,
  SET_USER_FINISHED_LIST: 67,
  NEWS_ADD: 68,

  SET_SOUND_ON_OFF: 86,
  SET_SOUND_VOLUME: 87,
  SET_MUSIC_ON_OFF: 88,
  SET_MUSIC_VOLUME: 89,

  SET_SERVER_SECONDS_OFFSET: 90,

  MAP_ADD: 92,

  SET_SCENARIOID: 93,
  SET_SCENARIO: 94,

  // Game Play Actions:
  SET_REPLAY_STAGE: 100,
  SET_REPLAY_PLAY: 101,

  MAP_ZOOM: 200,
  MAP_PAN: 201,

  SET_SELECTED_ZONEIDS: 300,

  SET_GAME: 401,
  SET_GAME_STATUS: 402,
  UPDATE_GAME_STATUS: 403,

  GAME_UPDATE_AUTOPLAY: 404,
  GAME_UPDATE_TEAMMATES_SUBMIT: 405,

  HIDE_FORCE_PURCHASE_WINDOW: 500,
  SHOW_FORCE_PURCHASE_WINDOW: 501,
  PURCHASE_SOME_FORCES: 502,
  UNPURCHASE_ZONE_FORCES: 503,

  HIDE_FORCE_RETREAT_WINDOW: 510,
  SHOW_FORCE_RETREAT_WINDOW: 511,
  RETREAT_SOME_FORCES: 512,

  SHOW_LOAD_TRANSPORTS_WINDOW: 600,
  HIDE_LOAD_TRANSPORTS_WINDOW: 601,
  LOAD_SOME_FORCES: 602,
  LOAD_FORCE_BY_ID: 603,

  SHOW_UNLOAD_TRANSPORTS_WINDOW: 700,
  HIDE_UNLOAD_TRANSPORTS_WINDOW: 701,
  UNLOAD_SOME_FORCES: 702,
  UNLOAD_FORCE_BY_ID: 703,

  SET_WORKING_AI: 800,

  MOVE_SOME_FORCES: 1000,
  UNDO_ZONE_FORCE_MOVEMENTS: 1001,
  MOVE_FORCE_BY_ID: 1002,

  SHOW_LANDING_HERE_WINDOW: 1010,
  HIDE_LANDING_HERE_WINDOW: 1011,
  SHOW_LANDING_SPECIFY_WINDOW: 1012,
  HIDE_LANDING_SPECIFY_WINDOW: 1013,
  SHOW_LANDING_LIST_WINDOW: 1014,
  HIDE_LANDING_LIST_WINDOW: 1015,
  SET_LANDING_SPECIFY_ZONE: 1016,
  SPECIFY_FORCES_LANDING: 1017,

  CHAT_ADD: 2005,
  CHAT_META_ADD: 2006,
  CHAT_META_SET_SINGLE_CID: 2007,
  CHAT_META_SET_SINGLE_RCID: 2008,

  SCENARIO_EDIT_SAVED: 2999,
  SCENARIO_EDIT_SET_SCENARIO: 3000,
  SCENARIO_EDIT_STARTING_FORCES: 3001,
  SCENARIO_EDIT_NAVY_ZONE: 3002,
  SCENARIO_EDIT_ZONE_OWNER: 3003,
  SCENARIO_EDIT_ZONE_INCOME: 3004,
  SCENARIO_EDIT_CAPITAL: 3005,
  SCENARIO_EDIT_PLAYER_FLAGS: 3006,
  SCENARIO_EDIT_CASH: 3007,
  SCENARIO_EDIT_TEAM: 3008,
  SCENARIO_EDIT_TEAM_NAME: 3009,
  SCENARIO_EDIT_TURN_TIME: 3010,
  SCENARIO_EDIT_MAX_TURNS: 3011,
  SCENARIO_EDIT_MAX_DURATION: 3012,
  SCENARIO_EDIT_DESCRIPTION: 3013,
  SCENARIO_EDIT_NAME: 3014,
  SCENARIO_EDIT_PLAYER_NAME: 3015,
  SCENARIO_EDIT_PLAYER_FLAG: 3016,

  MAP_EDIT_SET_SELECTED_ZONE_ID: 3098,
  MAP_EDIT_SAVED: 3099,
  MAP_EDIT_SET_MAP: 3100,
  MAP_EDIT_ZOOM_IN: 3101,
  MAP_EDIT_ZOOM_OUT: 3102,
  MAP_EDIT_ZONE_FLAGS: 3103,
  MAP_EDIT_ZONE_NAME: 3104,
  MAP_EDIT_PIXEL: 3105,
  MAP_EDIT_NAME: 3106,
  MAP_EDIT_DESCRIPTION: 3107,

};

export default actions;
