/*
  music.js
*/

import mainModes from '../reducers/main-modes';
import BF from '../bfcore/bfconst1';

import music0 from './music/Splash_Screen_Opus.mp3';

import music1 from './music/Finland.mp3';
import music2 from './music/Helsinki_Sweden.mp3';
import music3 from './music/Cairo_Egypt.mp3';
import music4 from './music/Greece.mp3';
import music5 from './music/Naples_Italy.mp3';
import music6 from './music/Norway_Nephelim_Battle.mp3';
import music7 from './music/Jerusalem_Israel.mp3';
import music8 from './music/Rome_Italy.mp3';
import music9 from './music/Vienna_Austria.mp3';
// import music10 from './music/Nakhayb_Iraq.mp3';
// import music11 from './music/Munich_Germany.mp3';
// import music12 from './music/Al_Basrah.mp3';
// import music13 from './music/Turin_Italy.mp3';
// import music14 from './music/Zurich_Switzerland.mp3';


var audio = null;
var audio_mainMode = 0;
var audio_turnIndex = 0;
var audio_volume = 0;


export function updateMusic (mainMode, turnIndex, on, volume) {
  if(on) {
    if(!audio || mainMode !== audio_mainMode || turnIndex !== audio_turnIndex) {
      audio_volume = volume;
      audio_mainMode = mainMode;
      audio_turnIndex = turnIndex;

      let music = null;

      if (mainMode === mainModes.GAME_REPLAY ||
          mainMode === mainModes.GAME_DOING_TURN ||
          mainMode === mainModes.GAME_SUBMITTING_TURN) {
        if (mainMode === mainModes.GAME_DOING_TURN && turnIndex >= BF.TURN_NORMAL) {
          switch ((turnIndex - BF.TURN_NORMAL) % 9) {
            case 0: music = music1; break;
            case 1: music = music2; break;
            case 2: music = music3; break;
            case 3: music = music4; break;
            case 4: music = music5; break;
            case 5: music = music6; break;
            case 6: music = music7; break;
            case 7: music = music8; break;
            default: music = music9; break;
          }
        }
        else {
          music = music0;
        }
      }

      if(music) {
        if (audio) {
          audio.pause();
        }
        audio = new Audio(music);
        audio.volume = volume;
        audio.loop = true;
        audio.play();
      }
      else {
        if(audio) {
          audio.pause();
          audio = null;
        }
      }
    }
    else {
      if (audio_volume !== volume) {
        audio_volume = volume;
        if(audio) {
          audio.volume = volume;
        }
      }
    }
  }
  else {
    if (audio) {
      audio.pause();
      audio = null;
    }
  }
}
