import React from 'react';
import { Table } from 'semantic-ui-react';
import { linkTextLight } from '../../helper/style';
import GuiForce from './GuiForce';
import BF from '../../bfcore/bfconst1';


export default class GuiForceTypeRow extends React.Component {

  state = {
    forceTypeIndex: 0,
    game: {},
    select: null,
  };

  constructor(props) {
    super(props);  // Call the parent class' constructor.

    this.state.forceTypeIndex = props.forceTypeIndex;
    this.state.game = props.game;
    this.state.select = props.select;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.game !== this.state.game ||
        nextProps.forceTypeIndex !== this.state.forceTypeIndex ||
        nextProps.select !== this.state.select) {
      this.setState({
        forceTypeIndex: nextProps.forceTypeIndex,
        game: nextProps.game,
        select: nextProps.select,
      });
    }
  }

  showForce = (e) => {
    e.stopPropagation();
    const { forceTypeIndex } = this.state;
    if(this.state.select) {
      this.state.select(forceTypeIndex);
    }
  };

  render() {
    const { forceTypeIndex, game } = this.state;

    let range = '-';
    if(game.forceRange[forceTypeIndex] > 0) {
      range = game.forceRange[forceTypeIndex];
      if(game.forceFlags[forceTypeIndex] &
          (BF.FORCE_FLAG_MUST_LAND_FRIENDLY|BF.FORCE_FLAG_MUST_LAND_ANY)) {
        range += '*';
      }
    }

    let attack = '-';
    if(game.forceAttack[forceTypeIndex] > 0) {
      attack = game.forceAttack[forceTypeIndex] + '%';
      if(game.forceFlags[forceTypeIndex] & BF.FORCE_FLAG_ONESHOTPERTARGET) {
        attack += '*';
      }
    }

    let defense = '-';
    if( game.forceDefense[forceTypeIndex] > 0) {
      defense = game.forceDefense[forceTypeIndex] + '%';
      if(game.forceFlags[forceTypeIndex] & BF.FORCE_FLAG_ONESHOTPERTARGET) {
        defense += '*';
      }
    }

    return(
      <Table.Row key={ 'tr' + forceTypeIndex } >
        <Table.Cell>
          <span onPointerDown={ this.showForce } style={ linkTextLight } >
            { game.forceName[forceTypeIndex] }
          </span>
        </Table.Cell>
        <Table.Cell textAlign='center'>
          <div style={{height: 24, width: 24}}>
            <GuiForce forceType={ game.forceID[forceTypeIndex] } />&nbsp;&nbsp;&nbsp;
          </div>
        </Table.Cell>
        <Table.Cell textAlign='center'>
          { game.forceCost[forceTypeIndex] }
        </Table.Cell>
        <Table.Cell textAlign='center'>
          { range }
        </Table.Cell>
        <Table.Cell textAlign='center'>
          { attack }
        </Table.Cell>
        <Table.Cell textAlign='center'>
          { defense }
        </Table.Cell>
        <Table.Cell textAlign='center'>
          { game.forceCargoCapacity[forceTypeIndex] ?
             game.forceCargoCapacity[forceTypeIndex] : '-' }
        </Table.Cell>
        <Table.Cell textAlign='center'>
          { game.forceCarrierCapacity[forceTypeIndex] ?
             game.forceCarrierCapacity[forceTypeIndex] : '-' }
        </Table.Cell>
      </Table.Row>
    );
  }
}
