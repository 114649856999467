import React from 'react';
import { Flag, Step } from 'semantic-ui-react';
import bfH from '../../bfcore/bf_helper.js';


export default function GuiZoneDouble (props) {
  const { game, zoneID, zoneID2 } = props;

  function stopPropagation (e) {
    e.stopPropagation();
  }

  return (
    <Step.Group onPointerDown={ stopPropagation }>
      <Step>
        <Step.Content>
          <Step.Description>
            <b>Move From</b>
          </Step.Description>
          <Step.Title><center>{ game.zoneName[zoneID] }</center></Step.Title>
          <Step.Description>
            <center>
              <Flag name={ bfH.zoneOwnerFlag(game, zoneID) } />
              { bfH.zoneOwnerName(game, zoneID) }
            </center>
          </Step.Description>
        </Step.Content>
      </Step>
      <Step link>
        <Step.Content>
          <Step.Description>
            <b>Move To</b>
          </Step.Description>
          <Step.Title><center>{ game.zoneName[zoneID2] }</center></Step.Title>
          <Step.Description>
            <center>
              <Flag name={ bfH.zoneOwnerFlag(game, zoneID2) } />
              { bfH.zoneOwnerName(game, zoneID2) }
            </center>
          </Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}
