import React from 'react';
import { Segment, Table } from 'semantic-ui-react';
import GuiForce from '../GameGui/GuiForce';
import BF from '../../bfcore/bfconst1';


export default class GuiScenarioEditStats extends React.Component {

  state = {
    store: {},
		closeHandle: null,
  };

  constructor(props) {
    super(props);  // Call the parent class' constructor.

		this.state.store = props.store;
		this.state.closeHandle = props.closeHandle;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store !== this.state.store || nextProps.closeHandle !== this.state.closeHandle) {
      this.setState({
				store: nextProps.store,
				closeHandle: nextProps.closeHandle,
      });
    }
  }

  closeHandle = (e) => {
    e.stopPropagation();
		if(this.state.closeHandle) {
			this.state.closeHandle();
		}
  };



  render() {
    const { game, scenarioEdit } = this.state.store;

    const playerIncome = [];
		const playerZones = [];
		const playerForces = [];
		const playerTotalForces = Array(scenarioEdit.players + 1).fill(0);
    for(let p = 0; p <= scenarioEdit.players; p++) {
			playerIncome.push(0);
			playerZones.push(0);
			playerForces[p] = Array(scenarioEdit.forceID.length).fill(0);
		}

		let totalIncome = 0;
		let totalZones = 0;
		const totalForces = Array(scenarioEdit.forceID.length).fill(0);
		let totalTotalForces = 0;

		for(let z = 1; z <= scenarioEdit.zones; z++) {
			if(game.zoneFlags[z] & BF.ZONE_FLAG_LAND_ZONE) {
				totalZones++;
				const i = scenarioEdit.income[z];
				const owner = scenarioEdit.owner[z];
				totalIncome += i;
				playerIncome[owner] += i;
				playerZones[owner]++;
				for(let f = 0; f < scenarioEdit.forceID.length; f++) {
					const c = scenarioEdit.startingForces[z][f];
					if(c) {
						totalTotalForces += c;
						totalForces[f] += c;
						playerForces[owner][f] += c;
						playerTotalForces[owner] += c;
					}
				}
			}
    }

/*
    let teamData = null;
    if(scenarioEdit.teams > 1) {
      const teamNames = [];
      for(let t = 1; t <= scenarioEdit.teams; t++) {
        if(scenarioEdit.teamName[t]) {
          let defaultValue = scenarioEdit.teamName[t];
          if(!defaultValue) {
            defaultValue = ' ';  // This is to work around a strange disappearing component bug.
          }
          let handler = this.updateTeamName1;
          if(t === 2) {
            handler = this.updateTeamName2;
          }
          else if(t === 3) {
            handler = this.updateTeamName3;
          }
          else if(t === 4) {
            handler = this.updateTeamName4;
          }
          teamNames.push(
            <span key={ 't' + t }>
              &nbsp;&nbsp;&nbsp;Team { t }:&nbsp;&nbsp;&nbsp;
              <Input placeholder='Team Name...'
                     defaultValue={ defaultValue }
                     onChange={ handler }
              />
            </span>
          );
        }
      }
      teamData = <div key='tr'>{ teamNames }</div>;
    }
*/

		const header = [];
		if(scenarioEdit.teams > 1) {
			header.push(<Table.HeaderCell key='h0' textAlign='center'>Team</Table.HeaderCell>);
		}
		header.push(<Table.HeaderCell key='h1' textAlign='center'>Player</Table.HeaderCell>);
		// header.push(<Table.HeaderCell key='h2' textAlign='center'>Type</Table.HeaderCell>);
		header.push(<Table.HeaderCell key='h3' textAlign='center'>Income</Table.HeaderCell>);
		header.push(<Table.HeaderCell key='h4' textAlign='center'>Zones</Table.HeaderCell>);
		for(let f = 0; f < scenarioEdit.forceID.length; f++) {
			header.push(<Table.HeaderCell key={ 'hi' + f } textAlign='center'>
				<GuiForce forceType={ scenarioEdit.forceID[f] } />
			</Table.HeaderCell>);
		}
		header.push(<Table.HeaderCell key='h100' textAlign='center'>All</Table.HeaderCell>);

		const playerRows = [];
		for(let po = 1; po <= scenarioEdit.players + 1; po++) {
			const p = po > scenarioEdit.players ? 0 : po;
			const playerRow = [];
			playerRow.push(<Table.Cell key={ 'pc0' } textAlign='center'>
										   { p > 0 ? (scenarioEdit.playerName[p] ?
                                  scenarioEdit.playerName[p] :
                                  ('Player' + p))
                                 : 'AVAILABLE' }
										 </Table.Cell>);

			playerRow.push(<Table.Cell key={ 'pcpt' } textAlign='center'>
										 	 { playerIncome[p] }
										 </Table.Cell>);

			playerRow.push(<Table.Cell key={ 'pcpz' } textAlign='center'>
											 { playerZones[p] }
									   </Table.Cell>);

			for(let f = 0; f < scenarioEdit.forceID.length; f++) {
				playerRow.push(
					<Table.Cell key={ 'pc1' + f } textAlign='center'>
						{ playerForces[p][f] }
	 				</Table.Cell>
				);
			}

			playerRow.push(<Table.Cell key={ 'pctf' } textAlign='center'>
											 { playerTotalForces[p] }
										 </Table.Cell>);

			playerRows.push(
				<Table.Row key={ 'tpr' + p } textAlign='center'>
					{ playerRow }
				</Table.Row>
			);
		}

		// Final total total row:
		const playerRow = [];
		playerRow.push(<Table.Cell key={ 'pc0' } textAlign='center'>ALL TOTAL</Table.Cell>);
		playerRow.push(<Table.Cell key={ 'pcpt' } textAlign='center'>{ totalIncome }</Table.Cell>);
		playerRow.push(<Table.Cell key={ 'pcpz' } textAlign='center'>{ totalZones }</Table.Cell>);
		for(let f = 0; f < scenarioEdit.forceID.length; f++) {
			playerRow.push(
				<Table.Cell key={ 'pc1' + f } textAlign='center'>
					{ totalForces[f] }
				</Table.Cell>
			);
		}
		playerRow.push(<Table.Cell key={ 'pctf' } textAlign='center'>{ totalTotalForces }</Table.Cell>);
		playerRows.push(
			<Table.Row key={ 'tprt' } textAlign='center'>
				{ playerRow }
			</Table.Row>
		);


    return (
      <Segment compact inverted onPointerDown={ this.closeHandle }
          style={{ position: 'absolute', top: '30px', right: '0px', opacity: '90%' }}>
        <Table inverted attached='top' basic verticalAlign='top'>
          <Table.Header>
            <Table.Row>
							{ header }
            </Table.Row>
          </Table.Header>
          <Table.Body>
						{ playerRows }
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}
