import React from 'react';
import { Card, Image, Statistic } from 'semantic-ui-react';
import BaseFlag from '../BaseGui/BaseFlag';
import BaseRank from '../BaseGui/BaseRank';
import { sendPacket } from '../../network/network';
import BF from '../../bfcore/bfconst1';
import bfH from '../../bfcore/bf_helper.js';
import computerAvatar from '../BaseGui/img/avatar/computer.png';


export default class GuiPlayerDetail extends React.Component {

  state = {
    uid: 0,
    playerInfos: {},
    playerStrings: {},
  };

  constructor(props) {
    super(props);  // Call the parent class' constructor.

    this.state.uid = props.uid;
    this.state.playerInfos = props.playerInfos;
    this.state.playerStrings = props.playerStrings;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.playerInfos !== this.state.playerInfos ||
        nextProps.playerStrings !== this.state.playerStrings ||
        nextProps.uid !== this.state.uid) {
      this.setState({
        playerInfos: nextProps.playerInfos,
        playerStrings: nextProps.playerStrings,
        uid: nextProps.uid,
      });
    }
  }

  addToFriends = (e) => {
    sendPacket('bf_get', {e: 'friendAdd', friend: this.state.uid});
  };

  removeFromFriends = (e) => {
    sendPacket('bf_get', {e: 'friendRemove', friend: this.state.uid});
  };

  addToBlockList = (e) => {
    sendPacket('bf_get', {e: 'blockAdd', block: this.state.uid});
  };

  removeFromBlockList = (e) => {
    sendPacket('bf_get', {e: 'blockRemove', block: this.state.uid});
  };

  render() {
    const { uid, playerInfos, playerStrings } = this.state;

    let textDiv = null;
    let uName = null;
    let membershipText = null;
    let cc = '';
    let rank = 0;

    let avatar = null;
    let stats = null;

    if(uid < 0) {
      uName = 'COMPUTER PLAYER';
      avatar = computerAvatar;
      textDiv = null;
    }
    else if(uid > 0 && playerInfos[uid]) {
      const playerInfo = playerInfos[uid];
      uName = playerInfo.uName;
      avatar = playerInfo.avatar;
      membershipText = bfH.membershipLevelAsString(playerInfo.membershipLevel) + ' Member';
      cc = playerInfo.cc;
      rank = playerInfo.rank;
      if(uName !== 'GameAdmin') {
        textDiv =
          <div>
            <BaseRank rank={ rank } /><br/>
            { BF.PLAYER_RANK_TITLE[rank] }<br/>
          </div>;
        stats = [
          { key: 'score', label: 'Score', value: playerInfo.score },
          { key: 'xp', label: 'XP', value: playerInfo.xp },
          { key: 'plays', label: 'Plays', value: playerInfo.plays },
        ];
      }
      else {
        membershipText = 'BATTLE FORCES Administrator';
      }
    }
    else if(uid > 0 && playerStrings[uid]) {
      const d = playerStrings[uid].split('|');
      uName = d[1];
      rank = parseInt(d[2], 36);
      const membershipLevel = parseInt(d[3], 36);
      membershipText = bfH.membershipLevelAsString(membershipLevel) + ' Member';
      const score = parseInt(d[4], 36);
      const xp = parseInt(d[5], 36);
      const plays = parseInt(d[6], 36);
      if(d[7]) {
        cc = d[7];
      }
      if(uName !== 'GameAdmin') {
        textDiv =
          <div>
            <BaseRank rank={ rank } /><br/>
            { BF.PLAYER_RANK_TITLE[rank] }<br/>
          </div>;
        stats = [
          { key: 'score', label: 'Score', value: score },
          { key: 'xp', label: 'XP', value: xp },
          { key: 'plays', label: 'Plays', value: plays },
        ];
      }
      else {
        membershipText = 'BATTLE FORCES Administrator';
      }
    }

    return (
      <center>
        <Card>
          <Image src={ avatar } wrapped ui={ false } />
          <Card.Content>
            <Card.Header>{ uName }</Card.Header>
            <Card.Meta>
              { membershipText }
            </Card.Meta>
            <Card.Description>
              <center>
                <div style={{display: 'flex',
                             alignItems: 'center',
                             justifyContent: 'center',
                           }}>
                  { textDiv }
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  { (cc && uName !== 'GameAdmin') &&
                    <BaseFlag cc={ cc } />
                  }
                </div>
              </center>
              { (uid > 0 && uName !== 'GameAdmin') &&
                <Statistic.Group size='mini' widths='three' items={ stats } />
              }
            </Card.Description>
          </Card.Content>
        </Card>
      </center>
    );
  }
}
