/*
  BaseRulesLandWar.js
  (c) Human Cube Inc.
*/

import BaseCamoBackScroll from './BaseCamoBackScroll';
import BaseFooter from './BaseFooter';
import BaseMenuBasic from './BaseMenuBasic';
import GuiForce from '../GameGui/GuiForce';


export default function BaseRulesLandWar () {
  return (
    <BaseCamoBackScroll>
      <BaseMenuBasic pageName='Land War Game Rules' />
      <center>
        <br />
        <br />
        <br />
        <div>
          <h1>Land War Game Rules</h1>
        </div>
        <div style={{margin: '12px'}} >
            <h3>Object of the Game</h3>

            The object of BATTLE FORCES is to conquer the world through the use of strategy and negotiation.

            <h3><br />Game Flow</h3>

            BATTLE FORCES progresses through three different types of turns as follows:<br />
            <br />
            Land Zone Picking (optional)<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|<br />
            Capital Selection<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|<br />
            Year Turn <br />
            <br />
            After Capital Selection, play repeats through increasing years until the game is complete.


            <h3><br />Land Zone Picking Turns</h3>

            If the game is configured for Auto-Picking, land zones are automatically distributed to all the players and play begins immediately with the Capital Selection Turn. Otherwise, each player takes turns picking the land zones they wish to make up their empire with.<br />
            <br />
            Land Zone Picking Turns happen sequentially. Picking will cycle through all players in a pattern like: 1 2 3 3 2 1 3 2 1 3 2 1...  This diminishes any advantage the players who picked first may have.<br />
            <br />
            Each player selects a land zone during their picking turn until all of the land zones have been picked.<br />
            <br />
            Once a player picks a land zone, all of its forces become that player's.<br />
            <br />
            Once all land zones are picked, the game progresses to the Capital Selection Turn.

            <h3><br />Capital Selection Turn</h3>

            After all the land zones are picked, each player must then select one of their land zones to be their capital. Capital selection is very important; care should be taken to select a capital that can be satisfactorily defended. If a player loses their capital, they do not collect any income until it is recaptured.  In certain game configurations, the loss of your capital will result in your instant elimination from the game.<br />
            <br />
            All Capital Selection Turns are done simultaneously. Once everyone has submitted their selection, the results will be displayed to all players and the game will progress to the first year turn, 'Year 2000'.<br />
            <br />
            Some scenarios may provide preselected capitals for the players. In which case, play begins with the first Year Turn.

            <h3><br />Year Turns</h3>

            The actions that you perform during your turn will not be revealed to other players until the turn is complete.
            The results of the turn will only be calculated once all players have submitted their turns to the server.

            <h3><br />Purchasing and Placing New Forces</h3>

            There are ten standard force types available for purchase and use in BATTLE FORCES, however Land War games only use three of these.<br />
            <br />
            <table border="1" align="center">
              <tbody>
                <tr>
                  <th>Force Type</th><th>Cost in Credits</th>
                </tr>
                <tr><td><GuiForce forceType={ 1 } />&nbsp;&nbsp;Troop</td><td align="center">10</td></tr>
                <tr><td><GuiForce forceType={ 2 } />&nbsp;&nbsp;Tank</td><td align="center">15</td></tr>
                <tr><td><GuiForce forceType={ 10 } />&nbsp;&nbsp;Factory</td><td align="center">45</td></tr>
              </tbody>
            </table>
            <br />
            Using available credits, new forces may be purchased.
            Newly purchased forces are placed at the end of the turn.
            New forces can only be placed at locations where the player has a Factory that they own.
            Forces cannot be placed at Factories that have just been captured or built.<br />
            <br />
            A maximum number (not value) of forces equal to the resource value of the land zone containing
            the factory can be produced at it. For example, a factory on a land zone with an income of 3
            can only have a maximum of 3 units placed at it per turn. The total value of these units does
            not matter, so 3 tanks or 2 tanks and 1 troop can be placed at the factory.  A factory located
            on ones own capital has unlimited production and does not have this restriction.<br />
            <br />
            Care must be taken that the land zone selected to produce the new Force is not in the process of
            falling into enemy hands. If a hostile location is selected, the Force will not be produced.<br />
            <br />
            New Factories can only be placed on land zones that you already owned at the beginning of the year.
            Only one factory is permitted per land zone.  Factories cannot be placed on water zones.<br />
            <br />
            New forces cannot move until the next turn.


            <h3><br />Forces Movement</h3>

            Movement types for different forces:<br />
            <br />
            <table border="1" align="center">
              <tbody>
                <tr><th>Force Type</th><th>Movement Points</th></tr>
                <tr><td><GuiForce forceType={ 1 } />&nbsp;&nbsp;Troop</td><td align="center">1</td></tr>
                <tr><td><GuiForce forceType={ 2 } />&nbsp;&nbsp;Tank</td><td align="center">2</td></tr>
                <tr><td><GuiForce forceType={ 10 } />&nbsp;&nbsp;Factory</td><td align="center">0</td></tr>
              </tbody>
            </table>
            <br />
            If a force is in or enters a land zone with a force of another player that can block it, it will be unable to move further regardless of any additional movement points remaining for that turn.<br />

            <h3><br />Combat</h3>

            If at then end of movement, there are forces of more than one player in any land zone, combat ensues.<br />
            <br />
            A random dice roll is generated for each force. For each successful roll, a casualty is scored upon an opponent.
            Only one round of combat will happen during each turn.
            If all of the opposing forces in a land zone are not destroyed, combat continues again during the next turn,
            allowing the opportunity for reinforcements to be brought in.<br />

            <br />
            <table border="1" align="center">
              <tbody>
                <tr><th>Force Type</th><th>Attack Odds</th><th>Defense Odds</th></tr>
                <tr><td><GuiForce forceType={ 1 } />&nbsp;&nbsp;Troop</td><td align="center">15 %</td><td align="center">30 %</td></tr>
                <tr><td><GuiForce forceType={ 2 } />&nbsp;&nbsp;Tank</td><td align="center">50 %</td><td align="center">30 %</td></tr>
                <tr><td><GuiForce forceType={ 10 } />&nbsp;&nbsp;Factory</td><td align="center">0 % ***</td><td align="center">0 % ***</td></tr>
              </tbody>
            </table>
            *** Factories can neither attack nor defend and cannot be destroyed.<br />

            <h3><br />Land Zone Takeovers</h3>

            Land Zone Takeovers are calculated at the end of the turn.
            A land zone may only be taken over if all forces of the owner have been destroyed and that there is at least one
            land based attacking force such as a Troop or Tank.<br />
            <br />
            If there are no defending forces but two or more attacking player forces, then the land zone is set to neutral
            and will remain such until only single player remain on it.<br />
            <br />
            Factories do not assist in defense and are not counted as a defending force. If a land zone is captured with a
            Factory on it, the victor will control the Factory the following turn.

            <h3><br />Income Collection</h3>

            Income is collected at the end of each turn.
            For each land zone a player owns, they will receive credits equal to the income value of that land zone.
            However, if a player does not own their capital, they will not earn income from any of their land zones
            until they recapture it.

            <h3><br />Victory Conditions</h3>

            The standard victory condition for all games is conquering the world by destroying all other players. Some games have additional early victory conditions that can change and sometimes speed up the game dynamics. Four possible early victory conditions (in order of priority if more than one configured) are:<br />
            <div style={{margin: '0 auto', width: '300px'}}>
              <ul style={{textAlign: 'left'}}>
                  <li>number of capitals controlled</li>
                  <li>total income</li>
                  <li>number of land zones to control</li>
                  <li>total cash</li>
              </ul>
            </div>
            A game may have one or more of the victory conditions selected. For example, a given game may have an early victory condition of 'must control 3 capitals' or 'have a total income of 200' to win. The first player to meet one of these conditions would be declared the winner.<br />
            <br />
            To view the victory conditions for the current game, just click on the Victory button located at the top of the screen. Clicking on this button will display all early conditions for victory in the current game, if any.<br />

            <h3><br />Looking For More?</h3>

            After mastering Land War games, you can move on to other games that have additional forces like Fighters, Bombers, Submarines and more.
        </div>
        <br />
        <br />
        <br />
        <BaseFooter />
        <br /><br /><br />
        <br /><br /><br />
      </center>
    </BaseCamoBackScroll>
  );
}
