import React from 'react';
import { Button, Flag } from 'semantic-ui-react';
import actions from '../../reducers/actions';
import { store } from '../../app/store';
import GuiForce from './GuiForce';


export default class GuiForceMoveLine extends React.Component {
  state = {
    props: {},
  };

  constructor(props) {
    super(props);
    this.state.props = props;
    this._onPointerUpMoveOne = this._onPointerUpMoveOne.bind(this);
    this._onPointerUpMoveAll = this._onPointerUpMoveAll.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      props: nextProps,
    });
  }

  _onPointerUpMoveOne() {
    store.dispatch({
      type: actions.MOVE_SOME_FORCES,
      game: this.state.props.game,
      forceTypeIndex: this.state.props.forceTypeIndex,
      range: this.state.props.range,
      transportCargoKey: this.state.props.transportCargoKey,
      count: 1,
      playerIndex: this.state.props.localPlayerIndex,
      zoneIDFrom: this.state.props.zoneIDFrom,
      zoneIDTo: this.state.props.zoneIDTo,
    });
  }
  _onPointerUpMoveAll() {
    store.dispatch({
      type: actions.MOVE_SOME_FORCES,
      game: this.state.props.game,
      forceTypeIndex: this.state.props.forceTypeIndex,
      range: this.state.props.range,
      transportCargoKey: this.state.props.transportCargoKey,
      count: this.state.props.count,
      playerIndex: this.state.props.localPlayerIndex,
      zoneIDFrom: this.state.props.zoneIDFrom,
      zoneIDTo: this.state.props.zoneIDTo,
    });
  }

  render() {
    const game = this.state.props.game;
    const cargo = [];
    let loadPointsDisplay = null;
    if(this.state.props.transportCargoKey) {
      const cargoParts = this.state.props.transportCargoKey.split(':');
      loadPointsDisplay = <small>&nbsp;&nbsp;LP:&nbsp;{cargoParts[2]}&nbsp;</small>;
      for(let i = 3; i < cargoParts.length; i++) {
        if(cargoParts[i].length > 0) {
          cargo.push(
            <GuiForce key={'c' + i} forceType={game.forceID[parseInt(cargoParts[i])]} half />
          );
        }
      }
    }

    return (
      <div className='flex flex-row justify-between'>
        <div className='mt-1'>
          <Flag name={this.state.props.flag} />&nbsp;
          <b>{this.state.props.count}</b>&nbsp;
          </div>
        <GuiForce forceType={this.state.props.forceType} />
        <div className='flex flex-col'>
          {cargo}
        </div>
        <div className='mt-1'>
          {loadPointsDisplay}
          &nbsp;<small>range&nbsp;{this.state.props.range}</small>&nbsp;
        </div>
        <div className='flex flex-row mb-1'>
          <Button onPointerUp={this._onPointerUpMoveOne} size='mini' compact>
            Move&nbsp;1
          </Button>
          <Button onPointerUp={this._onPointerUpMoveAll} size='mini' compact>
            Move&nbsp;ALL
          </Button>
        </div>
      </div>
    );
  }
}
