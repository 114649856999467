/*
  BaseGameDetailDetails.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Link } from "react-router-dom";
import { Table } from 'semantic-ui-react';

import GuiForce from '../GameGui/GuiForce';
import BF from '../../bfcore/bfconst1';
import { linkText } from '../../helper/style';


function secondsToString (s) {
  if( s === 600 )
    return '10 minutes (Extremely Fast!)';
  if( s === 1800 )
    return '30 minutes (Very Fast!)';
  if( s === 3600 )
    return '1 hour (Fast)';
  if( s === 7200 )
    return '2 hours (Fast)';
  if( s === 10800 )
    return '3 hours (Fast)';
  if( s === 14400 )
    return '4 hours (Fast)';
  if( s === 21600 )
    return '6 hours (Fast)';
  if( s === 43200 )
    return '12 hours (Fast)';
  if( s === 24*3600 )
    return '24 hours (Normal)';
  if( s === 25*3600 )
    return '25 hours (Standard)';
  if( s === 48*3600 )
    return '48 hours (Slow)';
  if( s === 7*24*3600 )
    return '7 days (Slowest)';
  if( s === 14*24*3600 )
    return '14 days (Lots of Time)';
  return s + ' seconds';
}

function secondsToDurationString (s) {
  let str = '';
  if(s >= 48*3600) {
    str += Math.floor(s / (24*3600)) + ' days ';
    s -= Math.floor(s / (24*3600)) * (24*3600);
  }
  else if(s >= 24*3600) {
    str += '1 day ';
    s -= 24*3600;
  }

  if(s >= 2*3600) {
    str += Math.floor(s / 3600) + ' hours ';
    s -= Math.floor(s / 3600) * 3600;
  }
  else if(s >= 3600) {
    str += '1 hour ';
    s -= 3600;
  }

  if(s >= 2*60) {
    str += Math.floor(s / 60) + ' minutes ';
    s -= Math.floor(s / 60) * 60;
  }
  else if(s >= 60) {
    str += '1 minute ';
    s -= 60;
  }

  if(s > 0) {
    str += s + ' seconds.';
  }

  return str;
}

export default class BaseGameDetailDetails extends React.Component {

  state = {
    game: {},
    map: {},
  };

  constructor(props) {
    super(props);  // Required step: always call the parent class' constructor
    this.state.game = props.game;
    this.state.map = props.map;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.game !== this.state.game || nextProps.game !== this.state.map) {
      this.setState({
        game: nextProps.game,
        map: nextProps.map,
      });
    }
  }

  mapTitle () {
    const { mapID, mapName } = this.state.game;
    return(
      <Link to={'/map/' + mapID}>
        <span style={ linkText } >
          { mapName }
        </span>
      </Link>
    );
  }

  scenarioTitle () {
    const { scenarioID, scenarioName } = this.state.game;
    return(
      <Link to={'/scenario/' + scenarioID}>
        <span style={ linkText } >
          {scenarioName }
        </span>
      </Link>
    );
  }

  gamePlayerTitle () {
    const userName = this.state.game.uName;
    const playerID = this.state.game.uid;
    return(
      <Link to={'/player/' + playerID}>
        <span style={ linkText } >
          { userName }
        </span>
      </Link>
    );
  }

  mapPlayerTitle() {
    const userName = this.state.game.mapUName;
    const playerID = this.state.game.mapUID;
    return(
      <Link to={'/player/' + playerID}>
        <span style={ linkText } >
          { userName }
        </span>
      </Link>
    );
  }

  scenarioPlayerTitle() {
    const userName = this.state.game.scenarioUName;
    const playerID = this.state.game.scenarioUID;
    return(
      <Link to={'/player/' + playerID}>
        <span style={ linkText } >
          { userName }
        </span>
      </Link>
    );
  }

  render() {
    const game = this.state.game;
    if(!game._id) {
      return null;
    }

    const s = [];

    if(game.flags & BF.GAME_FLAG_PRIVATE) {
      s.push(
        <div key='gd1'>
          This is a private game. A PIN is required to join.
        </div>
      );
    }

    if(game.flags & BF.GAME_FLAG_PREMIUM_MEMBERS_ONLY) {
      s.push(
        <div key='gd2'>
          Premium or Extreme Membership required to join this game.
        </div>
      );
    }
    else if(game.flags & BF.GAME_FLAG_EXTREME_MEMBERS_ONLY) {
      s.push(
        <div key='gd3'>
          Extreme Membership required to join this game.
        </div>
      );
    }

    s.push(
      <div key='gd10'>
        Started by: { this.gamePlayerTitle() }
        { (game.flags & BF.GAME_FLAG_AUTORESPAWN) === BF.GAME_FLAG_AUTORESPAWN &&
           <small> (auto)</small>
        }
      </div>
    );

    s.push(
      <div key='gd11'>
        Scenario: { this.scenarioTitle() } by: { this.scenarioPlayerTitle() }
      </div>
    );

    s.push(
      <div key='gd12'>
        Map: { this.mapTitle() } by: { this.mapPlayerTitle() }
      </div>
    );

    if((game.mapFlags & (BF.MAP_FLAG_CAN_X_PAN_BOARD|BF.MAP_FLAG_CAN_Y_PAN_BOARD)) ===
        (BF.MAP_FLAG_CAN_X_PAN_BOARD|BF.MAP_FLAG_CAN_Y_PAN_BOARD)) {
      s.push(
        <div key='gd12a'>
          Map wraps both East-West and North-South.
        </div>
      );
    }
    else if((game.mapFlags & BF.MAP_FLAG_CAN_X_PAN_BOARD) === BF.MAP_FLAG_CAN_X_PAN_BOARD) {
      s.push(
        <div key='gd12a'>
          Map wraps East-West.
        </div>
      );
    }
    else if((game.mapFlags & BF.MAP_FLAG_CAN_Y_PAN_BOARD) === BF.MAP_FLAG_CAN_Y_PAN_BOARD) {
      s.push(
        <div key='gd12a'>
          Map wraps North-South.
        </div>
      );
    }
    else {
      s.push(
        <div key='gd12a'>
          Map does not wrap.
        </div>
      );
    }

    s.push(
      <div key='gd13'>
        Zones: { game.zones } ( { game.zonesLand } land, { game.zonesWater } water )
      </div>
    );

    let totalIncome = 0;
    for(let j = 1; j <= game.zones; j++)
        totalIncome += game.zoneIncome[j];
    s.push(
      <div key='gd14'>
        Total Income: { totalIncome }
      </div>
    );

    let forceImages = [];
    if( Array.isArray(game.forceID) ) {
      for(let f = 0; f < game.forceID.length; f++) {
        forceImages.push(<GuiForce key={'fk' + f} forceType={game.forceID[f]} />);
      }
    }
    s.push(
      <div key='gd15' className='flex flex-row'>
        Forces: { forceImages }
      </div>
    );
    s.push(
      <div key='gd16'>
        Players: { game.players } ( { game.humanPlayers } human, { game.computerPlayers } computer )
      </div>
    );

    let tempStr;
    if(game.flags & BF.GAME_FLAG_LOSE_CAPITAL_LOSE)
      tempStr = 'Immediate elimination if capital is lost.';
    else if(game.flags & BF.GAME_FLAG_MUST_RECAPTURE_CAPITAL)
      tempStr = 'Players must retake their lost capital or be eliminated.';
    else tempStr = 'Players are not eliminated until all their forces are destroyed.';
    s.push(
      <div key='gd17'>
        { tempStr }
      </div>
    );
    if( game.timeTurn ) {
      s.push(
        <div key='gd18'>
          Turn Time Limit: { secondsToString(game.timeTurn) }
        </div>
      );
    }
    if(game.maxDuration)
    s.push(
      <div key='gd19'>
        Max Duration: { secondsToDurationString(game.maxDuration) }
      </div>
    );

    if(game.maxTurns) {
      s.push(
        <div key='gd20'>
          Max Turns: { game.maxTurns }
        </div>
      );
    }

    if(game.victoryCapitals > 0 || game.victoryLandZones > 0 ||
        game.victoryIncome > 0 || game.victoryCash > 0) {
      s.push(<div key='gd21'>Early Victory Conditions:</div>);
      if( game.victoryCapitals > 0 )
        s.push(<div key='gd21vc'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Control <b>
          { game.victoryCapitals }</b> Capitals</div>);
      if( game.victoryLandZones > 0 )
        s.push(<div key='gd21vz'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Control <b>
          { game.victoryLandZones }</b> Land Zones</div>);
      if( game.victoryIncome > 0 )
        s.push(<div key='gd21vi'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Have an Income of at least <b>
          { game.victoryIncome }</b></div>);
      if( game.victoryCash > 0 )
        s.push(<div key='gd21vh'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Have at least <b>
          { game.victoryCash }</b> Cash</div>);
    }
    else {
      s.push(<div key='gd21'>No Early Victory Conditions.</div>);
    }

    if(game.flags & BF.GAME_FLAG_FACTORY_AT_CAPITAL) {
      s.push(
        <div key='gd22'>
          All player capitals will automatically start with a factory.
        </div>
      );
    }
    if(game.flags & BF.GAME_FLAG_DO_NOT_SCORE) {
      s.push(
        <div key='gd23'>
          Non-Scoring game.  It will not affect player scores.
        </div>
      );
    }
    if(!(game.flags & BF.GAME_FLAG_CAN_CHOOSE_PLAYER_INDEX)) {
      s.push(
        <div key='gd24'>
          Player slots are randomly reordered when the game starts.
        </div>
      );
    }

    if(s.length === 0) {
      return null;
    }

    return (
      <Table attached='top' basic verticalAlign='top'>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {s}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}
