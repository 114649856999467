/*
  game.js
*/

import LZString from '../lib/lz-string.min.js';
import { store } from '../app/store';
import BF from '../bfcore/bfconst1.js';
import bfH from '../bfcore/bf_helper.js';
import { sendPacket } from '../network/network';
import { getNewZoneXY } from '../bfcore/bf_hitmap.js';


const potentialPlayerCountryCode = [
  '', 'us', 'sa', 'gb', 'cn', 'in', 'kr', 'br',
  'de', 'fr', 'za', 'pk', 'es', 'fi', 'gr', 'hu',
  'jm', 'it', 'jp', 'ph', 'nz', 'qa', 'ru', 'se',
  'tw', 'cr', 'pl', 'tr', 'ug', 'ye', 'no', 'ca',
];

function fillerPlayerCountryCode (excludeList, playerIndex) {
  // Returns a country code that is not in the excludeList array for the given slot.
  // Tries to use the default initial value for the playerIndex, else chooses another random one.
  let cc = '';
  if(playerIndex > 0 && playerIndex < potentialPlayerCountryCode.length) {
    cc = potentialPlayerCountryCode[playerIndex];
  }
  else {
    cc = potentialPlayerCountryCode[
      Math.floor(Math.random() * potentialPlayerCountryCode.length) + 1
    ];

  }
  do {
    if(!cc) {
      cc = potentialPlayerCountryCode[
        Math.floor(Math.random() * potentialPlayerCountryCode.length) + 1
      ];
    }
    if(Array.isArray(excludeList)) {
      for(let i = 0; i < excludeList.length; i++) {
        if(excludeList[i] && excludeList[i].toUpperCase() === cc) {
          cc = '';
          break;
        }
      }
    }
  } while(!cc);
  return cc;
}

function convertString36ToInt (v) {
  // Safely convert any value to an integer.
  // 0 for undefined and 0 for NaN.
  // Assumes base 36 string encoding.
  v = parseInt(v, 36);
  if(!v) {
    return 0;
  }
  return v;
}

function colorSwapRB (c) {
  // Convert RGR (bmp format) color to RGB.
  return ((c & 0xFF0000) >> 16) |
         (c & 0xFF00) |
         ((c & 0xFF) << 16);
}

export function decodeGameString (gameString) {
  // returns an object containing all the decoded game fields from the gameString.
  // returns null on error.

  if(!gameString) {
    return null;
  }

  const parts = gameString.split('|');
  if(parts.length < 18) {
    return null;
  }

  const playerID = [];
  const playerParts = parts[6].split(',');
  for(let i = 0; i < playerParts.length; i++) {
    playerID.push(convertString36ToInt(playerParts[i]));
  }

  const playerName = parts[7].split(',');

  const team = [];
  const teamParts = parts[9].split(',');
  for(let i = 0; i < teamParts.length; i++) {
    team.push(convertString36ToInt(teamParts[i]));
  }

  const teamName = parts[10].split(',');

  return {
    _id: convertString36ToInt(parts[0]),
    flags: convertString36ToInt(parts[1]),
    name: parts[2],
    scenarioID: convertString36ToInt(parts[3]),
    mapID: convertString36ToInt(parts[4]),
    players: convertString36ToInt(parts[5]),
    playerID,
    playerName,
    teams: convertString36ToInt(parts[8]),
    team,
    teamName,
    state: convertString36ToInt(parts[11]),
    turn: convertString36ToInt(parts[12]),
    year: convertString36ToInt(parts[13]),
    timeLimit: convertString36ToInt(parts[14]),
    required: convertString36ToInt(parts[15]),
    noWait: convertString36ToInt(parts[16]),
    submitted: convertString36ToInt(parts[17]),
  };
}

export function parseGameCore (gameCore) {
  // returns game object or null on error.
  try {
    return JSON.parse(LZString.decompressFromUTF16(gameCore));
  }
  catch(error) {
    return null;
  }
}

export function gameEnhanceData (game) {
  // Adds additional fields and missing values in game object needed for this react client.
  // Also fixes colors to be RGB not BGR.
  // Modifies the game object passed in.
  const zones = game.zones;

  // Add the new ReactClient fields:
  game.zoneBoxXSize = 16;
  game.zoneBoxYSize = 16;
  game.mapXScale = 1;
  game.mapYScale = 1;

  if(game.mapXS <= 0) {
    game.mapXS = 512;
  }
  if(game.mapYS <= 0) {
    game.mapYS = 512;
  }

  if(game.mapFlags & BF.MAP_FLAG_BITMAP) {
    switch (game.mapID) {
      case 1:
        // Make Standard World Map display a little wider.
        game.mapXScale = 1.5;
        break;
      case 4:
        // Make WWII Map display a little wider.
        game.mapXScale = 1.75;
        break;
      default:
    }
  }

  game.zoneBoxX = [0];
  game.zoneBoxY = [0];
  game.navyZone = [0];
  game.startingForces = [[]];

  for(let zone = 1; zone <= zones; zone++) {
    // Provide a default name for any zone without a specific name.
    if(!game.zoneName[zone]) {
      if(bfH.isWater(game, zone)) {
        game.zoneName[zone] = 'Water Zone ' + zone;
      }
      else {
        game.zoneName[zone] = 'Land Zone ' + zone;
      }
    }

    // For now we will just grab a random location in a zone for its zoneBox location,
    // actually we grab 100 locations and average it out.
    // TODO: later improve this to be a center location.
    let pos = {x: 0, y: 0};
    for(let i = 0; i < 100; i++) {
      const p = getNewZoneXY(game, zone);
      pos.x += p.x;
      pos.y += p.y;
    }
    pos.x /= 100;
    pos.y /= 100;
    // pos is now approximate center of zone, now we find a point that is nearest to the center but
    // still actually in the zone.  This handles crecent shaped and other concave zones outliers.
    let inZonePos = {x: pos.x, y: pos.y};
    let bestDelta = 1000000;
    for(let i = 0; i < 100; i++) {
      const p = getNewZoneXY(game, zone);
      const delta = Math.abs(pos.x - p.x) + Math.abs(pos.y - p.y);
      if(delta < bestDelta) {
        inZonePos.x = p.x;
        inZonePos.y = p.y;
        bestDelta = delta;
      }
    }
    game.zoneBoxX.push(Math.floor(inZonePos.x / BF.WORLD_WIDTH * 512));
    game.zoneBoxY.push(Math.floor(inZonePos.y / BF.WORLD_HEIGHT * 512));

    // TODO: Need to generate this:
    game.navyZone.push(0);

    game.startingForces.push([]);
  }

  const playerColour1 = [ // BGR format since it is from a BMP file. :-)
    0xffffff, 0x536bbf, 0x7c7c7c, 0xb1f9f9, 0x1bcfff, 0x82b081, 0xf0405b, 0x43ffff,
    0xffbfff, 0xffffac, 0x3fd188, 0x3c14dc, 0x943fac, 0x3f3fff, 0xe93fac, 0x94d188,
    0xe9ac88, 0xbfbf7f, 0xfff563, 0xe59ded, 0x2f92ff, 0x5defd9, 0xaceeff, 0xe9f5ac,
    0x94ff63, 0x3fbfbf, 0x3fffff, 0xf0ffff, 0xfff4e4, 0xffc0ff, 0x666666, 0xff3399,
  ];
  let allHaveColors = true;
  for(let p = 0; p <= game.players; p++) {
    if(!game.playerColor[p]) {
      allHaveColors = false;
      break;
    }
    game.playerColor[p] = colorSwapRB(game.playerColor[p]);
  }
  if(!allHaveColors) {
    // Even if just one is missing a color, map over all colors to avoid duplicates.
    for(let p = 0; p <= game.players; p++) {
      game.playerColor[p] = colorSwapRB(playerColour1[p]);
    }
  }

  // Fill empty playerFlag[] values so it is possible to render capital locations before start.
  // Skipping player 0 of course.
  for(let p = 1; p <= game.players; p++) {
    if(!game.playerFlag[p]) {
      // Try to fill with the slot flag first.
      game.playerFlag[p] = game.slotFlag[p];
    }
    if(!game.playerFlag[p]) {
      // Then just fill with the usual flag.
      game.playerFlag[p] = fillerPlayerCountryCode(game.playerFlag, p).toLowerCase();
    }
  }
}

export function sendSawFinishGameIfNeeded (gameID, gameState, localPlayerIsEliminated) {
  if(localPlayerIsEliminated ||
      gameState === BF.GAME_STATE_FINISHED || gameState === BF.GAME_STATE_ERROR ||
      gameState === BF.GAME_STATE_CANCELLED || gameState === BF.GAME_STATE_EXPIRED) {
    const userSessionBF = store.getState().userSession.bf;
    if(userSessionBF && Array.isArray(userSessionBF.game)) {
      for(let i = 0; i < userSessionBF.game.length; i++) {
        if(userSessionBF.game[i] === gameID) {
          sendPacket('bf_sawFinishedGame', {gameID});
          return;
        }
      }
    }
  }
}

export function calculateIncomeAndZones (game) {
  const playerIncome = [0];
  const playerZones = [0];
  if(game.players) {  // Confirm not empty game object.
    for(let i = 1; i <= game.players; i++) {
      playerIncome.push(0);
      playerZones.push(0);
      if(game.state === BF.GAME_STATE_REGISTERING ||
          (game.capital[i] > 0 && game.zoneOwner[game.capital[i]] === i)) {
        // Game has not started yet, or player owns their capital.
        // Collect income for all the zones they own:
        for(let j = 1; j <= game.zones; j++)
          if(game.zoneOwner[j] === i) {
            playerZones[i]++;
            playerIncome[i] += game.zoneIncome[j];
          }
      }
      else {
        for(let j = 1; j <= game.zones; j++) {
          if(game.zoneOwner[j] === i) {
            playerZones[i]++;
          }
        }
      }
    }
  }
  return { playerIncome, playerZones };
}
