import React from 'react';
import { Input, Segment, Table } from 'semantic-ui-react';
import GuiScenarioEditPlayerRow from './GuiScenarioEditPlayerRow';
import actions from '../../reducers/actions';
import { store } from '../../app/store';
import { calculateIncomeAndZones } from '../../helper/game';
import BF from '../../bfcore/bfconst1';


const ASCII_a = 'a'.charCodeAt(0);
const ASCII_z = 'z'.charCodeAt(0);
const ASCII_A = 'A'.charCodeAt(0);
const ASCII_Z = 'Z'.charCodeAt(0);
const ASCII_0 = '0'.charCodeAt(0);
const ASCII_9 = '9'.charCodeAt(0);

function isString (v) {
	return (typeof v === 'string' || v instanceof String);
}

function sanitizeScenarioTeamName (teamName) {
  // returns a cleaned team name string.
	let s = teamName;
	if( !isString(s) )
		s = '';
	let s2 = '';
	let k = s.length;
	// clean the names here:
	let i;
	for(i=0; i < k; i++)
		if( s.charAt(i) !== ' ' )
			break;
	let lastspace = 0;
	for(; i < k; i++) {
		let c = s.charAt(i)
		let cc = c.charCodeAt(0);
		if( (cc >= ASCII_a && cc <= ASCII_z) || (cc >= ASCII_A && cc <= ASCII_Z) ||
				(cc >= ASCII_0 && cc <= ASCII_9) ) {
			s2 += c;
			lastspace = 0;
		}
		else if( c === ' ' ) {
			if( lastspace === 0 ) {
				lastspace = 1;
				s2 += ' ';
			}
		}
	}

	k = s2.length;
	for(i = k - 1; i >= 0; i--)
		if( s2.charAt(i) !== ' ' )
			break;
	if( i >= 0 )
		s = s2.substr(0,i+1);
	else s = '';

	if( s.substr(0, 4).toLowerCase() === 'team' )
		s = '';
	return s.substr(0, BF.MAX_TEAM_NAME);
}

export default class GuiScenarioEditPlayers extends React.Component {

  state = {
    store: {},
  };

  constructor(props) {
    super(props);  // Call the parent class' constructor.

    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store !== this.state.store) {
      this.setState({
        store: nextProps.store,
      });
    }
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  updateTeamName (teamIndex, teamName) {
    const { game, scenarioEdit } = this.state.store;
    teamName = sanitizeScenarioTeamName(teamName);
    if(teamName) {
      store.dispatch({
        type: actions.SCENARIO_EDIT_TEAM_NAME,
        game,
        scenarioEdit,
        teamIndex,
        teamName,
      });
    }
  }

  updateTeamName1 = e => {
    this.updateTeamName(1, e.target.value);
  };

  updateTeamName2 = e => {
    this.updateTeamName(2, e.target.value);
  };

  updateTeamName3 = e => {
    this.updateTeamName(3, e.target.value);
  };

  updateTeamName4 = e => {
    this.updateTeamName(4, e.target.value);
  };

  render() {
    const { store } = this.state;
    const { game, scenarioEdit } = store;
    const { playerIncome, playerZones } = calculateIncomeAndZones(game);

    const playerRows = [];
    for(let p = 1; p <= scenarioEdit.players; p++) {
      playerRows.push(
        <GuiScenarioEditPlayerRow
          key={ 'p' + p }
          store={ store }
          playerIndex={ p }
          playerIncome={ playerIncome[p] }
          playerZones={ playerZones[p] }
        />
      );
    }

    let teamData = null;
    if(scenarioEdit.teams > 1) {
      const teamNames = [];
      for(let t = 1; t <= scenarioEdit.teams; t++) {
        if(scenarioEdit.teamName[t]) {
          let defaultValue = scenarioEdit.teamName[t];
          if(!defaultValue) {
            defaultValue = ' ';  // This is to work around a strange disappearing component bug.
          }
          let handler = this.updateTeamName1;
          if(t === 2) {
            handler = this.updateTeamName2;
          }
          else if(t === 3) {
            handler = this.updateTeamName3;
          }
          else if(t === 4) {
            handler = this.updateTeamName4;
          }
          teamNames.push(
            <span key={ 't' + t }>
              &nbsp;&nbsp;&nbsp;Team { t }:&nbsp;&nbsp;&nbsp;
              <Input placeholder='Team Name...'
                     defaultValue={ defaultValue }
                     onChange={ handler }
              />
            </span>
          );
        }
      }
      teamData = <div key='tr'>{ teamNames }</div>;
    }

    return (
      <Segment compact inverted onPointerDown={ this.stopPropagation }
          style={{ position: 'absolute', top: '30px', right: '0px', opacity: '90%' }}>
        { teamData }
        <Table inverted attached='top' basic verticalAlign='top'>
          <Table.Header>
            <Table.Row>
              { scenarioEdit.teams > 1 &&
                <Table.HeaderCell textAlign='center'>Team</Table.HeaderCell>
              }
              <Table.HeaderCell textAlign='center'>Player</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Type</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Cash</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Capital</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Income</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Forces</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Zones</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { playerRows }
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}
