import React from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import actions from '../../reducers/actions';
import { store } from '../../app/store';
import mainModes from '../../reducers/main-modes';


export default class BaseHomeJumpToMap extends React.Component {

  state = {
    closeHandle: null,
    number: '',
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.
    this.state.closeHandle = props.closeHandle;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.closeHandle !== this.state.closeHandle) {
      this.setState({
        closeHandle: nextProps.closeHandle,
        number: '',
      });
    }
  }

  jumpAndClose = () => {
    const mapID = parseInt(this.state.number, 10);
    if(mapID) {
      store.dispatch({type: actions.NAVIGATE_TO, where: {mode: mainModes.MAP_DETAIL, mapID}});
      if(this.state.closeHandle) {
        this.state.closeHandle();
      }
    }
  };

  justClose = () => {
    if(this.state.closeHandle) {
      this.state.closeHandle();
    }
  };

  handleChange = (e) => {
    let value = parseInt(e.target.value, 10);
    if(!value) {
      value = '';
    }
    this.setState({number: value});
  }

  render() {
    return (
      <Modal
          closeOnDimmerClick={ false }
          closeOnEscape={ false }
          dimmer='inverted'
          open={ true }
          size='small'>
        <Modal.Content>
          <Modal.Description>
            <p>
              Enter the ID # of the Map You Would Like to View
            </p>
            <Form.Input autoFocus={ true } fluid iconPosition='left' placeholder='ID Number'
              value={ this.state.number } onChange={ this.handleChange }
              style={{ width: '200px'}}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ this.justClose } negative>
            Cancel
          </Button>
          <Button
            onClick={ this.jumpAndClose }
            positive
            labelPosition='right'
            icon='checkmark'
            content='View Map'
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
