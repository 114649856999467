/*
  BaseRulesStandard.js
  (c) Human Cube Inc.
*/

import BaseCamoBackScroll from './BaseCamoBackScroll';
import BaseFooter from './BaseFooter';
import BaseMenuBasic from './BaseMenuBasic';
import GuiForce from '../GameGui/GuiForce';


export default function BaseRulesStandard () {
  return (
    <BaseCamoBackScroll>
      <BaseMenuBasic pageName='Standard Game Rules' />
      <center>
        <br />
        <br />
        <br />
        <div>
          <h1>Standard Game Rules</h1>
        </div>
        <div style={{margin: '12px'}} >

            <h3>Object of the Game</h3>

            The object of BATTLE FORCES is to conquer the world through the use of strategy and negotiation.

            <h3><br />Game Flow</h3>

            BATTLE FORCES progresses through three different types of turns as follows:<br />
            <br />
            Land Zone Picking (optional)<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|<br />
            Capital Selection<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|<br />
            Year Turn <br />
            <br />
            After Capital Selection, play repeats through increasing years until the game is complete.

            <h3><br />Land Zone Picking Turns</h3>

            If the game is configured for Auto-Picking, land zones are automatically distributed to all the players and play begins immediately with the Capital Selection Turn. Otherwise, each player takes turns picking the land zones they wish to make up their empire.  Water zones are not picked.<br />
            <br />
            Land Zone Picking Turns happen sequentially.  Picking will cycle through all players in a patter like this: 1 2 3 3 2 1 3 2 1 3 2 1...  This diminishes any advantage the players who picked first may have.<br />
            <br />
            Each player selects a land zone during their picking turn until all of the land zones have been picked. Water zones cannot be owned and are not picked.<br />
            <br />
            Once a player picks a land zone, all of its forces become that player's. Some land zones also have naval forces in a neighboring water zone; these will also become that player's forces if they are associated with the picked land zone.<br />
            <br />
            Once all land zones are picked, the game progresses to the Capital Selection Turn.

            <h3><br />Capital Selection Turn</h3>

            After all the land zones are picked, each player must then select one of their land zones to be their capital.
            Capital selection is very important; care should be taken to select a capital that can be satisfactorily defended.
            If a player loses their capital, they do not collect any income until it is recaptured.
            In certain game configurations, the loss of your capital will result in your instant elimination from the game.<br />
            <br />
            All Capital Selection Turns choices are revealed simultaneously. Once everyone has submitted their selection, the results will be displayed to all players and the game will progress to the first year turn, 'Year 2000'.

            <h3><br />Year Turns</h3>

            The actions that you perform during your turn will not be revealed to other players until everyone finishes their turn.
            The results of the turn will only be calculated once all players have submitted their turns to the server.<br />
            <br />
            New forces can be bought each turn, but are only available for use on the next turn.
            Please see the Purchasing New forces section for more information.<br />
            <br />
            After a player has completed their purchases and movement, they will submit their turn.
            Once all players have submitted their turns, all purchases and movements will be
            processed and any combat will be calculated. See the Combat section for more information.
            This happens simultaneously.

            <h3><br />Purchasing New Forces</h3>

            There are ten different force types available for purchase and use in BATTLE FORCES. The <a href="#forceTypes">Force Types</a> section describes each force type in more detail.<br />
            <br />
            <table border="1" align="center">
               <tbody>
                <tr><th>Force Type</th><th>Cost in Credits</th></tr>
                <tr><td><GuiForce forceType={ 1 } />&nbsp;&nbsp;Troop</td><td align="center">10</td></tr>
                <tr><td><GuiForce forceType={ 2 } />&nbsp;&nbsp;Tank</td><td align="center">15</td></tr>
                <tr><td><GuiForce forceType={ 3 } />&nbsp;&nbsp;SAM Launcher</td><td align="center">15</td></tr>
                <tr><td><GuiForce forceType={ 4 } />&nbsp;&nbsp;Fighter</td><td align="center">30</td></tr>
                <tr><td><GuiForce forceType={ 5 } />&nbsp;&nbsp;Bomber</td><td align="center">35</td></tr>
                <tr><td><GuiForce forceType={ 6 } />&nbsp;&nbsp;Submarine</td><td align="center">20</td></tr>
                <tr><td><GuiForce forceType={ 7 } />&nbsp;&nbsp;Transport Ship</td><td align="center">20</td></tr>
                <tr><td><GuiForce forceType={ 8 } />&nbsp;&nbsp;Aircraft Carrier</td><td align="center">35</td></tr>
                <tr><td><GuiForce forceType={ 9 } />&nbsp;&nbsp;Destroyer</td><td align="center">25</td></tr>
                <tr><td><GuiForce forceType={ 10 } />&nbsp;&nbsp;Factory</td><td align="center">45</td></tr>
              </tbody>
            </table>
            <br />
            Using available credits, new forces may be purchased. The new forces purchased will be built and available for placement at the end of the turn. See New Forces Placement.

            <h3><br />Forces Movement</h3>

            Movement types for different forces:<br />
            <br />
            <table border="1" align="center">
             <tbody>
                <tr><th>Force Type</th><th>Movement Points</th><th>Blocked By</th><th>Blocks</th><th>Must Land Safely</th></tr>
                <tr><td><GuiForce forceType={ 1 } />&nbsp;&nbsp;Troop</td><td align="center">1</td><td align="center">Any</td><td align="center">Surface</td><td align="center">No</td></tr>
                <tr><td><GuiForce forceType={ 2 } />&nbsp;&nbsp;Tank</td><td align="center">2</td><td align="center">Any</td><td align="center">Surface</td><td align="center">No</td></tr>
                <tr><td><GuiForce forceType={ 3 } />&nbsp;&nbsp;SAM Launcher</td><td align="center">1</td><td align="center">Any</td><td align="center">Surface and Air</td><td align="center">No</td></tr>
                <tr><td><GuiForce forceType={ 4 } />&nbsp;&nbsp;Fighter</td><td align="center">4</td><td align="center">Air</td><td align="center">Surface and Air</td><td align="center">Yes</td></tr>
                <tr><td><GuiForce forceType={ 5 } />&nbsp;&nbsp;Bomber</td><td align="center">6</td><td align="center">Air</td><td align="center">Surface and Air</td><td align="center">Yes</td></tr>
                <tr><td><GuiForce forceType={ 6 } />&nbsp;&nbsp;Submarine</td><td align="center">2</td><td align="center">None</td><td align="center">Surface</td><td align="center">No</td></tr>
                <tr><td><GuiForce forceType={ 7 } />&nbsp;&nbsp;Transport Ship</td><td align="center">2</td><td align="center">Any</td><td align="center">Surface</td><td align="center">No</td></tr>
                <tr><td><GuiForce forceType={ 8 } />&nbsp;&nbsp;Aircraft Carrier</td><td align="center">2</td><td align="center">Any</td><td align="center">Surface</td><td align="center">No</td></tr>
                <tr><td><GuiForce forceType={ 9 } />&nbsp;&nbsp;Destroyer</td><td align="center">2</td><td align="center">Any</td><td align="center">Surface</td><td align="center">No</td></tr>
                <tr><td><GuiForce forceType={ 10 } />&nbsp;&nbsp;Factory</td><td align="center">0</td><td align="center">None</td><td align="center">None</td><td align="center">No</td></tr>
              </tbody>
            </table>
            <br />
            If a force is in or enters a land or water zone with a force of another player that can block it, it will be unable to move further regardless of any additional movement points that turn.<br />
            <br />
            It is useful to note that aircraft block surface forces, but surface forces (except the SAM Launcher) do not block aircraft. This gives aircraft the ability to disrupt surface movement patterns while still being able to continue moving to their final given destination.<br />
            <br />
            Submarines are never blocked but they block other ships and can disrupt shipping movement by blocking them.<br />
            <br />
            Transport ships can carry up to two units of cargo.  Troops, Tanks and SAM Launchers each require one unit of cargo space.
            Forces are only allowed to load onto a Transport Ship if they have not yet moved in the current turn, regardless of whether they
            become blocked.  However, if the force is blocked from combat in the previous turn, they will be unable to load.<br />
            <br />
            If a Transport Ship has been blocked, it will be prevented from unloading.
            Transport ships cannot move or load after unloading.
            Any transport ships destroyed while carrying cargo, will result in the cargo also being destroyed.
            Any forces that are carried as cargo cannot assist in combat until they are unloaded onto land.<br />

            <h3><br />Combat</h3>

            If at then end of any turn movement, there are forces of more than one player in any land or water zone, combat ensues.<br />
            <br />
            A random dice roll is generated for each force. For each successful roll, a casualty is scored upon an opponent.
            Only one round of combat will happen during each turn.  If all of the opposing forces in a land or water zone are
            not destroyed, combat continues again during the next turn, allowing the opportunity for reinforcements to be
            brought in.<br />

            <br />
            <table border="1" align="center">
              <tbody>
                <tr><th>Force Type</th><th>Attack Odds</th><th>Defense Odds</th></tr>
                <tr><td><GuiForce forceType={ 1 } />&nbsp;&nbsp;Troop</td><td align="center">15 %</td><td align="center">30 %</td></tr>
                <tr><td><GuiForce forceType={ 2 } />&nbsp;&nbsp;Tank</td><td align="center">50 %</td><td align="center">30 %</td></tr>
                <tr><td><GuiForce forceType={ 3 } />&nbsp;&nbsp;SAM Launcher</td><td align="center">0 %</td><td align="center">20 % *</td></tr>
                <tr><td><GuiForce forceType={ 4 } />&nbsp;&nbsp;Fighter</td><td align="center">50 %</td><td align="center">65 %</td></tr>
                <tr><td><GuiForce forceType={ 5 } />&nbsp;&nbsp;Bomber</td><td align="center">65 %</td><td align="center">15 %</td></tr>
                <tr><td><GuiForce forceType={ 6 } />&nbsp;&nbsp;Submarine</td><td align="center">30 %</td><td align="center">**</td></tr>
                <tr><td><GuiForce forceType={ 7 } />&nbsp;&nbsp;Transport Ship</td><td align="center">10 %</td><td align="center">**</td></tr>
                <tr><td><GuiForce forceType={ 8 } />&nbsp;&nbsp;Aircraft Carrier</td><td align="center">35 %</td><td align="center">**</td></tr>
                <tr><td><GuiForce forceType={ 9 } />&nbsp;&nbsp;Destroyer</td><td align="center">50 %</td><td align="center">**</td></tr>
                <tr><td><GuiForce forceType={ 10 } />&nbsp;&nbsp;Factory</td><td align="center">0 % ***</td><td align="center">0 % ***</td></tr>
              </tbody>
            </table>
            * SAM Launchers can only defend, and get a 20% chance to hit each attacking enemy aircraft.  They are unable to attack or defend against non-aircraft.<br />
            ** Since water zones cannot be owned, all naval combat is as if both sides are attacking. This also applies to aircraft when in a water zone.<br />
            *** Factories can neither attack nor defend and cannot be destroyed.<br />
            <br />
            Sometimes, there can be forces from three or more players in the same land zone. When this happens, the owner of the land zone is the defender, and all other players are the attackers. If there are no forces of the owner present in the land zone, the player with the most number of forces in the land zone is made the defender.<br />
            <br />
            If there are three or more players in the same water zone, the player with the most number of forces goes against all the other players. As always for water zones, everyone uses attack odds.

            <h3><br />Aircraft Landing</h3>

            Only Fighters and Bombers may move after combat and they must have remaining range left to do so.
            No blocking, takes place during the aircraft landing phase.<br />
            <br />
            All aircraft must land by the end of the turn. Aircraft are only considered safely landed if they
            are on a land zone that was owned by their owner in the beginning of the current turn and also at
            the end of the current turn.<br />
            <br />
            Fighters also have the option of staying in a water zone if there is an Aircraft Carrier in that zone.
            Up to four Fighters may stay in a water zone at the end of a turn per Aircraft Carrier.
            Bombers cannot use Aircraft Carriers.<br />
            <br />
            Care must be taken to ensure that the proposed landing destination is not in the process of being
            taken over by enemy forces.  Any aircraft that do not safely land at the end of the turn will be destroyed.

            <h3><br />Land Zone Takeovers</h3>

            Land Zone Takeovers are calculated after all the turns have been processed.
            A land zone may only be taken over if all forces of the owner have been destroyed and that there is at
            least one land based attacking Force such as a Troop, Tank or SAM Launcher.
            Aircraft cannot claim new territory as they are unable to land in territories that have not been previously owned.<br />
            <br />
            If there are no defending forces but two or more attacking player forces,
            then the land zone is set to neutral and will remain such until only single player remain on it.<br />
            <br />
            Factories do not assist in defense and are not counted as a defending force.
            If a land zone is captured with a Factory on it, the victor will control the Factory the following turn.

            <h3><br />Place New Forces</h3>

            Newly purchased forces are placed at the end of the turn.  New forces can only be placed at locations where
            the player has a Factory that they own.  For naval forces, they maybe placed at an adjacent water zone to a
            Factory so long as there are no enemy forces in the water zone or there is at least one force of the same
            owner as the new placement.  Forces cannot be placed at Factories that have just been captured or built.<br />
            <br />
            A maximum number (not value) of forces equal to the resource value of the land zone containing the factory can
            be produced at it. For example, a factory on a land zone with an income of 3 can only have a maximum of 3 units
            placed at it per turn. The total value of these units does not matter, so 3 bomber or 2 tanks and 1 troop can be
            placed at the factory.  A factory located on ones own capital has unlimited production and does not have this
            restriction.<br />
            <br />
            Care must be taken that the land zone or water zone selected to produce the new force is not in the process
            of falling into enemy hands. If a hostile location is selected, the force will not be produced.<br />
            <br />
            New Factories can only be placed on land zones that you already owned at the beginning of the turn.
            Only one factory is permitted per land zone.  Factories cannot be placed on water zones.<br />
            <br />
            Placed forces cannot move until the next turn.

            <h3><br />Income Collection</h3>

            Income is collected at the end of each turn.
            For each land zone a player owns, they will receive credits equal to the income value of that land zone.
            However, if a player does not own their capital, they will not earn income from any of their land zones
            until they recapture it.

            <h3><br />Victory Conditions</h3>

            The standard victory condition for all games is conquering the world by destroying all other players. Some games have additional early victory conditions that can change and sometimes speed up the game dynamics. Four possible early victory conditions (in order of priority if more than one configured) are:<br />
            <div style={{margin: '0 auto', width: '300px'}}>
              <ul style={{textAlign: 'left'}}>
                  <li>number of capitals controlled</li>
                  <li>total income</li>
                  <li>number of land zones to control</li>
                  <li>total cash</li>
              </ul>
            </div>
            A game may have one or more of the victory conditions selected. For example, a given game may have an early victory condition of 'must control 3 capitals' or 'have a total income of 200' to win. The first player to meet one of these conditions would be declared the winner.<br />
            <br />
            To view the victory conditions for the current game, just click on the Victory button located at the top of the screen. Clicking on this button will display all early conditions for victory in the current game, if any.
        </div>
        <br />
        <br />
        <br />
        <BaseFooter />
        <br /><br /><br />
        <br /><br /><br />
      </center>
    </BaseCamoBackScroll>
  );
}
