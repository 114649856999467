/*
  GuiPurchasesSummary.js
  (c) Human Cube Inc.
*/
import React from 'react';
import { Button, Segment } from 'semantic-ui-react';
import { store } from '../../app/store';
import actions from '../../reducers/actions';
import GuiForce from './GuiForce';
import mainModes from '../../reducers/main-modes';


export default class GuiPurchasesSummary extends React.Component {

  state = {
    close: null,
    store: {},
  };

  constructor(props) {
    super(props);  // Call the parent class' constructor.

    this.state.close = props.close;
    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    // TODO: this will not properly detect state changes, need to grab the desired items within store!!!

    if(nextProps.close !== this.state.close || nextProps.store !== this.state.store) {
      this.setState({
        close: nextProps.close,
        store: nextProps.store,
      });
    }
  }

  close = (e) => {
    e.stopPropagation();
    if(this.state.close) {
      this.state.close();
    }
  }

  selectZone = (e, zoneID) => {
    e.stopPropagation();
    store.dispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: [zoneID]});
    store.dispatch({type: actions.SHOW_FORCE_PURCHASE_WINDOW});
  }

  render() {
    const { store } = this.state;
    const { gameID, game, mainMode, working } = store;
    if(game._id !== gameID) {
      return null;
    }

    const rows = [];
    if(mainMode === mainModes.GAME_DOING_TURN) {
      rows.push(<p key={ 'buyline0' }>Cash: <b>{ working.cash }</b></p>);
      for(let z = 1; z <= game.zones; z++) {
        if(working.buy[z]) {
          const purchasesHere = [];
          for(const forceTypeIndex in working.buy[z]) {
            if(working.buy[z][forceTypeIndex]) {
              purchasesHere.push(
                <div key={ 'buy' + forceTypeIndex } className='flex flex-col'>
                  <GuiForce forceType={ game.forceID[forceTypeIndex] } />
                  {working.buy[z][forceTypeIndex]}
                </div>
              );
            }
          }
          if(purchasesHere.length > 0) {
            rows.push(
              <p key={ 'buyline' + z }>
                <Button onPointerDown={ (e) => this.selectZone(e, z) }>
                  { game.zoneName[z] + ':' }&nbsp;&nbsp;&nbsp;
                  <div className='flex flex-row'>{purchasesHere}</div>
                </Button>
              </p>
            );
          }
        }
      }
      if(rows.length < 2) {
        rows.push(
          <p key={ 'buyline-2' }>
            Tap a zone you own with a factory to purchase additional forces.
          </p>
        );
      }
    }
    else {
      rows.push(
        <p key={ 'buyline-1' }>
          While doing your turn, this panel will list all purchases you have made.
        </p>
      );
    }

    return (
      <Segment compact onPointerDown={ this.close }
          style={{ position: 'absolute', top: '30px', left: '250px', opacity: '90%' }}>
        <h4>Forces Purchased this Turn</h4>
        { rows }
      </Segment>
    );
  }
}
