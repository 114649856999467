import React from 'react';
import { Segment, Table } from 'semantic-ui-react';
import GuiPlayerDetail from './GuiPlayerDetail';
import GuiPlayerRow from './GuiPlayerRow';
import { sendPacket } from '../../network/network';
import { calculateIncomeAndZones } from '../../helper/game';
import BF from '../../bfcore/bfconst1';


export default class GuiPlayers extends React.Component {

  state = {
    close: null,
    store: {},
    selectedUID: 0,
  };

  constructor(props) {
    super(props);  // Call the parent class' constructor.

    this.state.close = props.close;
    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.close !== this.state.close || nextProps.store !== this.state.store) {
      this.setState({
        close: nextProps.close,
        store: nextProps.store,
      });
    }
  }

  hidePlayers = (e) => {
    e.stopPropagation();
    if(this.state.close) {
      this.state.close();
    }
  }

  selectPlayer = (playerIndex) => {
    const { gameID, gameStatus } = this.state.store;
    if(gameID && gameStatus[gameID]) {
      const { playerID } = gameStatus[gameID];
      const actualPlayerID = playerID[playerIndex];
      if(actualPlayerID) {
        sendPacket('bf_get', {e: 'playerInfo', uid: actualPlayerID});  // TODO: could check if in store before requesting again.
        this.setState({
          selectedUID: actualPlayerID,
        });
      }
    }
  };

  hidePlayer = (e) => {
    e.stopPropagation();
    this.setState({
      selectedUID: 0,
    });
  };

  render() {
    const { selectedUID, store } = this.state;
    const { gameID, game } = store;
    if(game._id !== gameID) {
      return null;
    }

    if(selectedUID) {
      return (
        <Segment compact inverted onPointerDown={ this.hidePlayer }
                 style={{ position: 'absolute', top: '50px', right: '300px'}}>
          <GuiPlayerDetail uid={ selectedUID }
                           playerInfos={ store.playerInfos }
                           playerStrings={ store.playerStrings }/>
        </Segment>
      );
    }

    const { playerIncome, playerZones } = calculateIncomeAndZones(game);

    const playerRows = [];
    for(let p = 1; p <= game.players; p++) {
      playerRows.push(
        <GuiPlayerRow
          key={ 'p' + p }
          store={ this.state.store }
          playerIndex={ p }
          playerIncome={ playerIncome[p] }
          playerZones={ playerZones[p] }
          select={ this.selectPlayer }
        />
      );
    }

    return (
      <Segment compact inverted onPointerDown={ this.hidePlayers }
          style={{ position: 'absolute', top: '30px', right: '0px', opacity: '90%' }}>
        <Table inverted attached='top' basic verticalAlign='top'>
          <Table.Header>
            <Table.Row>
              { game.state !== BF.GAME_STATE_FINISHED &&
                <Table.HeaderCell textAlign='center'>Turn</Table.HeaderCell>
              }
              { game.teams > 1 &&
                <Table.HeaderCell textAlign='center'>Team</Table.HeaderCell>
              }
              <Table.HeaderCell textAlign='center'>Player</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Capital</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Finish</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Income</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Cash</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Forces</Table.HeaderCell>
              { game.state !== BF.GAME_STATE_FINISHED &&
                <Table.HeaderCell textAlign='center'>MaxBuy</Table.HeaderCell>
              }
              <Table.HeaderCell textAlign='center'>Zones</Table.HeaderCell>
              { game.state === BF.GAME_STATE_FINISHED &&
                <Table.HeaderCell textAlign='center'>Points</Table.HeaderCell>
              }
              { game.state === BF.GAME_STATE_FINISHED &&
                <Table.HeaderCell textAlign='center'>XPs</Table.HeaderCell>
              }
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { playerRows }
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}
