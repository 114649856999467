/*
  BaseMapDetail.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Link } from "react-router-dom";
import cloneDeep from 'clone-deep';
import { Button, Grid, List } from 'semantic-ui-react';

import BaseAdSense from './BaseAdSense';
import BaseCamoBackScroll from './BaseCamoBackScroll';
import BaseLeaderboardPlayerPlays from './BaseLeaderboardPlayerPlays';
import BaseMenuBasic from './BaseMenuBasic';
import BaseScenarioDetailCreate from './BaseScenarioDetailCreate';
import actions from '../../reducers/actions';
import { store } from '../../app/store';
import mainModes from '../../reducers/main-modes'
import BF from '../../bfcore/bfconst1';
import { userCanCreateMap, userCanCreateScenario } from '../../helper/user';
import { fillMapZoneNames } from '../../helper/map';
import { linkText } from '../../helper/style';


export default class BaseMapDetail extends React.Component {
  state = {
    store: {},
    showCreateScenarioOptions: false,
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.
    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store !== this.state.store) {
      this.setState({
        store: nextProps.store,
        showCreateScenarioOptions: false,
      });
    }
  }

  playerTitle() {
    const { mapID } = this.state.store;
    const mapCache = this.state.store.map;
    const map = mapCache[mapID];
    let uName = '';
    let playerID = 0;
    if(map) {
      uName = map.uName;
      playerID = map.uid;
    }
    return(
      <Link to={'/player/' + playerID}>
        <span style={ linkText } >
          { uName }
        </span>
      </Link>
    );
  }

  closeCreateScenario = () => {
    this.setState({ showCreateScenarioOptions: false });
  }

  createNewScenario = (e) => {
    e.stopPropagation();

    if(!userCanCreateScenario()) {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Membership Upgrade Required',
        body: 'To create your own scenarios, please upgrade your membership.',
      });
      store.dispatch({type: actions.NAVIGATE_TO, where: {mode: mainModes.MEMBERSHIP_OPTIONS}});
      return;
    }

    this.setState({ showCreateScenarioOptions: true });
  };

  editMap = (e) => {
    e.stopPropagation();

    if(!userCanCreateMap()) {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Membership Upgrade Required',
        body: 'To create your own maps, please upgrade your membership.',
      });
      store.dispatch({type: actions.NAVIGATE_TO, where: {mode: mainModes.MEMBERSHIP_OPTIONS}});
      return;
    }

    const { mapID, map, userSession } = this.state.store;
    const { uid } = userSession;

    if(!map[mapID]) {
      return;  // Map not loaded yet, do nothing.
    }

    const mapEdit = cloneDeep(map[mapID]);

    if(mapEdit.flags & BF.MAP_FLAG_LOCKED) {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'This Map is Locked to prevent changes.',
        body: 'This map has already been used for a scenario or game so it can no longer be modified. To make a variation of this map, create a new map based off of this map instead.',
      });
      return;
    }

    if(mapEdit.uid !== uid) {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Only the creator of a map may edit it.',
        body: 'Create a new map based off of this map instead.',
      });
      return;
    }

    fillMapZoneNames(mapEdit);
    store.dispatch({type: actions.MAP_EDIT_SET_MAP, mapEdit, alreadySaved: true});
    store.dispatch({type: actions.NAVIGATE_TO, where: {mode: mainModes.MAP_EDIT}});
  };

  createNewMap = (e) => {
    e.stopPropagation();

    if(!userCanCreateMap()) {
      store.dispatch({
        type: actions.NOTIFY_MODAL_PUSH,
        title: 'Membership Upgrade Required',
        body: 'To create your own maps, please upgrade your membership.',
      });
      store.dispatch({type: actions.NAVIGATE_TO, where: {mode: mainModes.MEMBERSHIP_OPTIONS}});
      return;
    }

    const { mapID, map, userSession } = this.state.store;
    const { uid, uName } = userSession;

    if(!map[mapID]) {
      return;  // Map not loaded yet, do nothing.
    }

    const mapEdit = cloneDeep(map[mapID]);

    mapEdit.parentID = mapEdit._id;
    mapEdit.parentUID = mapEdit.uid;
    mapEdit.parentUName = mapEdit.uName;
    mapEdit.parentName = mapEdit.name;

    mapEdit._id = 0;
    mapEdit.uid = uid;
    mapEdit.uName = uName;
    mapEdit.flags &= ~BF.MAP_FLAG_LOCKED;

    fillMapZoneNames(mapEdit);
    store.dispatch({type: actions.MAP_EDIT_SET_MAP, mapEdit});
    store.dispatch({type: actions.NAVIGATE_TO, where: {mode: mainModes.MAP_EDIT}});
  };

  render() {
    const { showCreateScenarioOptions, store } = this.state;
    const { mapID, leaderboardMapPlays, playerStrings } = store;
    const mapCache = store.map;
    const map = mapCache[mapID];

    if(!map || map._id !== mapID) {
      return (
        <BaseCamoBackScroll>
          <BaseMenuBasic pageName={ 'Loading...' } leftText={ 'Loading...' } />
          <br /><br /><br />
          <br /><br /><br />
        </BaseCamoBackScroll>
      );
    }

    const mapDetails = [];
    mapDetails.push(<div key="div1">Created By: { this.playerTitle() }</div>);

    if( map.flags & BF.MAP_FLAG_BITMAP ) {
      mapDetails.push(<div key="div2">Type: Regular</div>);
    }
    else {
      mapDetails.push(<div key="div2">Type: Custom</div>);
    }

    mapDetails.push(
      <div key="div3">
        Zones: { map.zones } ({ map.zonesLand } land, { map.zonesWater } water)
      </div>
    );

    const leaderboard = [];
    if(leaderboardMapPlays.mapID === mapID && Array.isArray(leaderboardMapPlays.data)) {
      for(let i = 0; i < leaderboardMapPlays.data.length / 2; i++) {
        const uid = leaderboardMapPlays.data[i * 2];
        leaderboard.push(<BaseLeaderboardPlayerPlays
                           key={ i + 1 }
                           rank={ i + 1 }
                           uid={ uid }
                           plays={ leaderboardMapPlays.data[i * 2 + 1] }
                           playerString={ playerStrings[uid] } />);
      }
    }

    return (
      <BaseCamoBackScroll>
        <BaseMenuBasic pageName={ map.name } leftText={ '#' + mapID } />

        <Grid centered columns={ 2 } style={{ padding: '6px' }}>
          <Grid.Column>
            <br /><br /><BaseAdSense />
            <h3>{ map.name }</h3>
            { mapDetails }
            <br />
            { (map.flags & BF.MAP_FLAG_HEXMAP) === BF.MAP_FLAG_HEXMAP &&
              <Button color='olive' size='small' onPointerUp={ this.editMap } >
                Edit this<br />Map
              </Button>
            }
            &nbsp;&nbsp;&nbsp;
            { (map.flags & BF.MAP_FLAG_HEXMAP) === BF.MAP_FLAG_HEXMAP &&
              <Button color='olive' size='small' onPointerUp={ this.createNewMap } >
                Create a New Map<br />based on this Map
              </Button>
            }
            &nbsp;&nbsp;&nbsp;
            <Button color='olive' size='small' onPointerUp={ this.createNewScenario } >
              Create a New Scenario<br />using this Map
            </Button>
          </Grid.Column>

          { leaderboard.length > 0 &&
            <Grid.Column style={{ border: '1px solid #666' }}>
              <h4>Most Active Players for this Map</h4>
              <List selection>
                { leaderboard }
              </List>
            </Grid.Column>
          }
        </Grid>
        <br /><br /><br />
        <br /><br /><br />
        { showCreateScenarioOptions &&
          <BaseScenarioDetailCreate
            mapID={ mapID }
            closeHandle={ this.closeCreateScenario }
            store={ store } />
        }
      </BaseCamoBackScroll>
    );
  }
}
