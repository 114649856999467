/*
  GuiLandingSpecifyWindow.js
  (c) Human Cube Inc.
*/

import React from 'react';
import actions from '../../reducers/actions';
import { Button, Flag, Step } from 'semantic-ui-react';
import { store } from '../../app/store';
import GuiForce from './GuiForce';
import BF from '../../bfcore/bfconst1';
import bfH from '../../bfcore/bf_helper.js';


function GuiForceLandingLine (props) {
  const { count, forceTypeIndex, game, localPlayerIndex, landingZoneID, newLandingZoneID, range,
          zoneID } = props;

  function landOne (e) {
    store.dispatch({
      type: actions.SPECIFY_FORCES_LANDING,
      game,
      playerIndex: localPlayerIndex,
      forceTypeIndex,
      zoneID,
      oldLandingZone: landingZoneID,
      newLandingZone: newLandingZoneID,
      range,
      count: 1,
    });
  }

  function landAll (e) {
    store.dispatch({
      type: actions.SPECIFY_FORCES_LANDING,
      game,
      playerIndex: localPlayerIndex,
      forceTypeIndex,
      zoneID,
      oldLandingZone: landingZoneID,
      newLandingZone: newLandingZoneID,
      range,
      count,
    });
  }

  let changeSpan = null;
  if( newLandingZoneID && newLandingZoneID !== landingZoneID ) {
    changeSpan =
      <span>
        <Button key="landOne" onPointerUp={ landOne } size="small" compact>
          Change 1
        </Button>
        <Button key="landAll" onPointerUp={ landAll } size="small" compact>
          Change All
        </Button>
      </span>;
  }

  return (
    <div className='flex flex-row'>
      <div className='mt-1'>
        <b>{count}</b>&nbsp;&nbsp;
      </div>
      <GuiForce forceType={ game.forceID[forceTypeIndex] } />
      <div className='mt-1'>
        &nbsp;&nbsp;<small>range { range }</small>&nbsp;&nbsp;
        { 'to ' + game.zoneName[landingZoneID] }&nbsp;&nbsp;&nbsp;
        { changeSpan }
      </div>
    </div>
  );
}

export default function GuiLandingSpecifyWindow (props) {
  const { game, destination, forceTypeIndex, localPlayerIndex, range, working, zoneID } = props;
  const force = working.force;
  const forces = force.length;

  function stopPropagation (e) {
    e.stopPropagation();
  }

  function hideLandingSpecifyWindow (e) {
    store.dispatch({
      type: actions.HIDE_LANDING_SPECIFY_WINDOW,
    });
  }

  let landToZoneID = zoneID;
  if(destination > 0) {
    landToZoneID = destination;
  }
  const rangeNeeded = working.distance[zoneID][landToZoneID];

  const landingForceCounts = {};  // Indexed by 'forceType|landingZoneID|range'
  for(let f = 0; f < forces; f++) {
    if(force[f].z === zoneID && force[f].owner === localPlayerIndex && force[f].r === range) {
      const forceType = force[f].ft;
      if(forceType === forceTypeIndex) {
        let landingZoneID =  working.landing[f];
        if(!landingZoneID) {
          landingZoneID = 0;
        }
        if(game.forceFlags[forceType] & BF.FORCE_FLAG_MUST_LAND_FRIENDLY) {
          const key = forceType + '|' + landingZoneID + '|' + range;
          if(!landingForceCounts[key]) {
            landingForceCounts[key] = 0;
          }
          landingForceCounts[key]++;
        }
      }
    }
  }

  // TODO low: could sort landingForceCounts here by the actual key).

  let landingLine = [];
  for(const key in landingForceCounts) {
    const f = key.split('|');
    landingLine.push(
      <GuiForceLandingLine key={ 'f' + key }
                           forceTypeIndex={ parseInt(f[0], 10) }
                           landingZoneID={ parseInt(f[1], 10) }
                           range={ parseInt(f[2], 10) }
                           count={ landingForceCounts[key] }
                           game={ game }
                           zoneID={ zoneID }
                           localPlayerIndex={ localPlayerIndex }
                           newLandingZoneID={ rangeNeeded > range ? 0 : landToZoneID }
      />
    );
  }

  if(landingLine.length === 0) {
    landingLine = <div>
                    You do not have any forces of<br />
                    that force type and range here<br />
                    that require landing.
                  </div>;
  }

  return (
    <Step.Group onPointerDown={ stopPropagation }>
      <Step>
        <Step.Content>
          <Step.Title>{ game.zoneName[zoneID] }</Step.Title>
          <Step.Description>
            <Flag name={ bfH.zoneOwnerFlag(game, zoneID) } />
            {bfH.zoneOwnerName(game, zoneID)}<br />
            <div style={{marginBottom: '3px', marginTop: '3px'}}>
              Tap map to choose a landing zone, then tap [Change]<br />
              below to update an aircraft's landing destination.
            </div>
            <div style={{marginBottom: '3px', marginTop: '3px'}}>
              Land in: <b>{ game.zoneName[landToZoneID] }</b> (distance <b>{ rangeNeeded })</b>.
            </div>

            { rangeNeeded > range &&
              <div style={{marginBottom: '3px', marginTop: '3px'}}>
                <b>Distance too far! Choose a zone within range ({ range }).</b>
              </div>
             }

            { landingLine }
            ----------------------------------------
            <div>
              <Button key='close' onPointerUp={ hideLandingSpecifyWindow } size='medium'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Close
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
            </div>
          </Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}
