/*
  GuiForce.js
  (c) Human Cube Inc.
*/

import fi1 from '../GamePixi/img/forcetype/fi1.png';
import fi2 from '../GamePixi/img/forcetype/fi2.png';
import fi3 from '../GamePixi/img/forcetype/fi3.png';
import fi4 from '../GamePixi/img/forcetype/fi4.png';
import fi5 from '../GamePixi/img/forcetype/fi5.png';
import fi6 from '../GamePixi/img/forcetype/fi6.png';
import fi7 from '../GamePixi/img/forcetype/fi7.png';
import fi8 from '../GamePixi/img/forcetype/fi8.png';
import fi9 from '../GamePixi/img/forcetype/fi9.png';
import fi10 from '../GamePixi/img/forcetype/fi10.png';


export default function GuiForce ({forceType, half}) {
  let texture;
  switch(forceType) {
    case 1: texture = fi1; break;
    case 2: texture = fi2; break;
    case 3: texture = fi3; break;
    case 4: texture = fi4; break;
    case 5: texture = fi5; break;
    case 6: texture = fi6; break;
    case 7: texture = fi7; break;
    case 8: texture = fi8; break;
    case 9: texture = fi9; break;
    case 10: texture = fi10; break;
    default: texture = ''; break;
  }
  const size = half ? 12 : 24;
  return <img src={ texture }
              style={{ width: size, height: size }}
              alt={'fi' + forceType}
         />;
}
