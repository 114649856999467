/*
  GuiZoneSingle.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Button, Flag, Icon, Step } from 'semantic-ui-react';
import actions from '../../reducers/actions';
import mainModes from '../../reducers/main-modes';
import { store } from '../../app/store';
import GuiForce from './GuiForce';
import BF from '../../bfcore/bfconst1';
import bfH from '../../bfcore/bf_helper.js';
import { sendPacket } from '../../network/network';


function forceInfoFromForceID (game, forceID) {
  // Returns null if not found, or dictionary of info for the given force.
  for(let p = 0; p <= game.players; p++) {
    if(Array.isArray(game.force[p])) {
      for(let f = 0; f < game.force[p].length; f++) {
        const playerForce = game.force[p][f];
        if(Array.isArray(playerForce) && playerForce[BF.FORCE_INDEX_ID] === forceID) {
          return {
            id: forceID,
            owner: p,
            ft: playerForce[BF.FORCE_INDEX_TYPE],
            z: playerForce[BF.FORCE_INDEX_ZONE],
            rsz: playerForce[BF.FORCE_INDEX_ZONE_PREV],
          };
        }
      }
    }
  }
  return null;
}


export default function GuiZoneSingle(props) {
  const { details, game, localPlayerIndex, mainMode, replayStage, working, zoneID } = props;

  function stopPropagation (e) {
    e.stopPropagation();
  }

  function showForcePurchaseWindow (e) {
    store.dispatch({
      type: actions.SHOW_FORCE_PURCHASE_WINDOW,
    });
  }

  function undoForceMovements (e) {
    store.dispatch({
      type: actions.UNDO_ZONE_FORCE_MOVEMENTS,
      game: game,
      playerIndex: localPlayerIndex,
      zoneID: zoneID,
    });
  }

  function showForceRetreats (e) {
    store.dispatch({
      type: actions.SHOW_FORCE_RETREAT_WINDOW,
    });
  }

  function selectCapital (e) {
    e.stopPropagation();
    store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.GAME_SUBMITTING_TURN});
    sendPacket('bf_submitTurn', {gameID: game._id, turn: game.turn, data: {pick: zoneID}});
  }

  function landingList (e) {
    e.stopPropagation();
    store.dispatch({
      type: actions.SHOW_LANDING_LIST_WINDOW,
    });
  }

  function showLanding (e) {
    e.stopPropagation();
    store.dispatch({
      type: actions.SHOW_LANDING_HERE_WINDOW,
    });
  }

  const currentForces = [];
  for(let i = 0; i < details.playersWithForces.length; i++) {
    const playerIndex = details.playersWithForces[i];
    const items = [];
    for(let f = 0; f < game.forceID.length; f++) {
      if(details.forcesTotal[playerIndex][f]) {
        if(game.forceCargoCapacity[f] === 0) { // Non-Transports, they are handled later.
          const item =
            <div key={i + 'f:' + f}>
              <GuiForce forceType={ game.forceID[f] } />
              <b>&nbsp;{details.forcesTotal[playerIndex][f]}</b>
            </div>;
          items.push(item);
        }
      }
    }

    for (let key in details.transportsByCargoSimple) {
      const count = details.transportsByCargoSimple[key];
      // key is: owner:transportForceTypeIndex:cargotypeindex1:cargotypeindex2 ...
      const parts = key.split(':');
      const owner = parseInt(parts[0]);
      if(owner === playerIndex) {
        const f = parseInt(parts[1]);
        const cargo = [];
        for(let ci = 2; ci < parts.length; ci++) {
          if(parts[ci].length > 0) {
            cargo.push(
              <GuiForce key={ 'c' + ci } forceType={ game.forceID[parseInt(parts[ci])] } half />
            );
          }
        }
        const item =
          <div key={'ft:' + key}>
            <div className='flex flex-row'>
              <GuiForce forceType={ game.forceID[f] } />
              <div className='flex flex-col'>{cargo}</div>
            </div>
            <b>&nbsp;{count}</b>
          </div>;
        items.push(item);
      }
    }

    if(items.length) {
      let line =
        <div key={ 'cf' + i } className='flex flex-row'>
          <div key='flag' className='mt-2'>
            <Flag name={game.playerFlag[playerIndex]} />
          </div>
          { items }
        </div>;
      currentForces.push(line);
    }
  }

  let incomeRender = null;
  if((game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE) || game.zoneIncome[zoneID] > 0) {
    incomeRender =
      <div><small><b>Income:&nbsp;&nbsp;{game.zoneIncome[zoneID]}</b></small></div>;
  }

  let ownerRender = null;
  if(game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE) {
    ownerRender =
      <div>
        Owner: <Flag name={bfH.zoneOwnerFlag(game, zoneID)} />
        {bfH.zoneOwnerName(game, zoneID)}
      </div>;
  }

  const purchasesHere = [];
  let purchaseButton = null;
  let undoButton = null;
  let retreatButton = null;
  let moveableText = null;
  let blockedText = null;

  if(localPlayerIndex > 0) {
    for(const forceTypeIndex in details.buy) {
      if(details.buy[forceTypeIndex]) {
        purchasesHere.push(
          <span key={ 'buy' + forceTypeIndex }>
            <GuiForce forceType={ game.forceID[forceTypeIndex] } />
            {details.buy[forceTypeIndex]}
          </span>
        );
      }
    }

    if(game.turn === BF.TURN_PICK_CAPITAL) {
      if(game.zoneOwner[zoneID] === localPlayerIndex &&
          (game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE)) {
        purchaseButton =
          <div style={{marginBottom: '6px'}}>
            <Button key='purchaseButton' onPointerUp={ selectCapital } size='small'>
              Select this<br />Zone as Capital
            </Button>
          </div>;
      }
    }
    else if(game.zoneOwner[zoneID] === localPlayerIndex) {
      purchaseButton =
        <div style={{marginBottom: '6px'}}>
          <Button key='purchaseButton' onPointerUp={ showForcePurchaseWindow } size='small'>
            <div className='flex flex-row'>
              <Icon name='factory' size={purchasesHere.length ? 'big' : undefined} /> {purchasesHere.length ?
                purchasesHere :
                (details.hasFactory ? 'Build New Forces Here' : 'Build Factory Here')
              }
            </div>
          </Button>
        </div>;
    }
    else if(game.zoneFlags[zoneID] & BF.ZONE_FLAG_WATER_ZONE) {
      if(details.hasFactory) {  // this actually means a neighboring factory in this case.
        purchaseButton =
          <div style={{marginBottom: '6px'}}>
            <Button key='purchaseButton' onPointerUp={ showForcePurchaseWindow } size='small'>
              <div className='flex flex-row'>
                <Icon name='factory'/> { purchasesHere.length ? 
                    purchasesHere : 'Build New Forces Here' }
              </div>
            </Button>
          </div>;
      }
    }


    if(game.turn >= BF.TURN_NORMAL && details.forcesUndoable > 0) {
      undoButton =
        <div style={{ marginBottom: '6px' }}>
          <Button key='undoButton' onPointerUp={ undoForceMovements } size='small'>
            <Icon name='undo alternate' /> Undo All Forces Moved Here
          </Button>
        </div>;
    }

    if(game.turn >= BF.TURN_NORMAL && details.retreatableCount[localPlayerIndex] > 0) {
      retreatButton =
        <div style={{ marginBottom: '6px' }}>
          <Button key='retreatButton' onPointerUp={ showForceRetreats } size='small'>
            <Icon name='share' /> Retreat Forces
          </Button>
        </div>;
    }

    if(game.turn >= BF.TURN_NORMAL) {
      if(details.moveableCount[localPlayerIndex] > 0) {
        moveableText =
          <div>
            <br />
            <b>
              To move forces, select a neighboring<br />
              destination zone.
            </b>
          </div>;
      }
      else if(details.forceCount[localPlayerIndex] > 0) {
        if(details.hasCargo) {
          moveableText =
            <div>
              <br />
              <b>
                None of your forces in this zone can move<br />
                any further. To unload transports, select<br />
                a neighboring land zone.
              </b>
            </div>;
        }
        else {
          moveableText =
            <div>
              <br />
              <b>
                None of your forces in this zone can move<br />
                any further.
              </b>
            </div>;
        }
      }
      else {
        moveableText =
          <div>
            <br />
            <b>
              You do not have any forces here.
            </b>
          </div>;
      }

      if(details.moveableCount[localPlayerIndex] > 0 || details.forceCount[localPlayerIndex] > 0) {
        if(details.blockedAirCount && details.blockedSurfaceCount) {
          blockedText =
            <div style={{ color: 'red' }}>
              <br />
              <b>
                There are opposing forces in this zone that<br />
                might be blocking both air and surface movement.
              </b>
            </div>;
        }
        else if(details.blockedAirCount) {
          blockedText =
            <div style={{ color: 'red' }}>
              <br />
              <b>
                There are opposing forces in this zone that<br />
                might be blocking air movement.
              </b>
            </div>;
        }
        else if(details.blockedSurfaceCount) {
          blockedText =
            <div style={{ color: 'red' }}>
              <br />
              <b>
                There are opposing forces in this zone that<br />
                might be blocking surface movement.
              </b>
            </div>;
        }
      }
    }
  }

  let showCasualties = false;
  const casualties = [];
  if(mainMode === mainModes.GAME_REPLAY && replayStage === BF.REPLAY_STAGE_DESTROY) {
    showCasualties = true;

    const hit = [];   // Keyed by playerIndex and then an object for each with key forceType.
    for(let p = 0; p <= game.players; p++) {
      hit[p] = {};
    }

    if(Array.isArray(working.zoneHits[zoneID])) {
      for(let i = 0; i < working.zoneHits[zoneID].length; i++) {
        const forceID = working.zoneHits[zoneID][i];
        if(forceID) {
          const forceInfo = forceInfoFromForceID(game, forceID);
          if(forceInfo) {
            const key = forceInfo.ft;
            if(!hit[forceInfo.owner][key]) {
              hit[forceInfo.owner][key] = 0;
            }
            hit[forceInfo.owner][key]++;
          }
        }
      }
    }

    for(let p = 0; p <= game.players; p++) {
      const items = [];
      for (var key in hit[p]) {
        const count = hit[p][key];
        const part = key.split(':');
        const forceType = part[0];
        items.push(
          <span key={ p + 'cf:' + key }>
            <GuiForce forceType={ game.forceID[forceType] } />
            <b>&nbsp;{count}</b>
          </span>
        );
      }
      if(items.length) {
        let line =
          <div key={ 'ccf' + p } className='flex flex-row'>
            <div className='mt-1'>
              <Flag name={game.playerFlag[p]} />
            </div>
            { items }
          </div>;
        casualties.push(line);
      }
    }

    if(casualties.length === 0) {
      casualties.push(<p key='isNeeded'>No casualties in this zone.</p>);
    }
  }

  return (
    <Step.Group onPointerDown={ stopPropagation } style={{ opacity: 0.86 }}>
      <Step>
        <Step.Content>
          <Step.Title><center>{ game.zoneName[zoneID] }</center></Step.Title>
          <Step.Description>
            <center>
              { incomeRender }
              { ownerRender }
              <br />
              <div>{ currentForces }</div>
              { purchaseButton }
              { (localPlayerIndex >= 0 && game.turn >= BF.TURN_NORMAL && details.landingCount > 0) &&
                <div style={{ marginBottom: '6px' }}>
                  <Button key='landingCountButton' onPointerUp={ showLanding } size='small'>
                    <Icon name='road' />{ details.landingCount } Aircraft
                    Landing{ details.landingCount > 1 ? 's' : '' } Here
                  </Button>
                </div>
              }
              { (localPlayerIndex >= 0 && game.turn >= BF.TURN_NORMAL && details.aircraftCount > 0) &&
                <div style={{ marginBottom: '6px' }}>
                  <Button key='landAircraftButton' onPointerUp={ landingList } size='small'>
                    <Icon name='fighter jet'/> Landings For Aircraft Here
                  </Button>
                </div>
              }
              { undoButton }
              { retreatButton }
              { moveableText }
              { showCasualties && <div><p><b><br />Casualties</b></p>{ casualties }</div> }
              { blockedText }
            </center>
          </Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}
