/*
  map-generate.js
  (c) 2016-present Human Cube Inc.
*/

import { getHitmapFromCache } from './hitmapCache';
import { forceTypeCache } from '../helper/forceType';
var BF = require('../bfcore/bfconst1.js');
var bfH = require('../bfcore/bf_helper.js');
var bfDefaultPick = require('../bfcore/bf_defaultpick.js');
var bfHitmap = require('../bfcore/bf_hitmap');

var xor4096 = require('../lib/xor4096');

// function addUniqueToArray (a, v) {
//   if(a.indexOf(v) === -1) {
//     a.push(v);
//   }
// }



const playerColour1 = [ // BGR format since it is from a BMP file. :-)
  0xffffff, 0x536bbf, 0x7c7c7c, 0xb1f9f9, 0x1bcfff, 0x82b081, 0xf0405b, 0x43ffff,
  0xffbfff, 0xffffac, 0x3fd188, 0x3c14dc, 0x943fac, 0x3f3fff, 0xe93fac, 0x94d188,
  0xe9ac88, 0xbfbf7f, 0xfff563, 0xe59ded, 0x2f92ff, 0x5defd9, 0xaceeff, 0xe9f5ac,
  0x94ff63, 0x3fbfbf, 0x3fffff, 0xf0ffff, 0xfff4e4, 0xffc0ff, 0x666666, 0xff3399
];

function playerColor (p) {
  // Return the RGB color integer for the given playerIndex. 0 if out of range.
  if(p < 0 || p >= playerColour1.length ) {
    return 0;
  }
  return ((playerColour1[p] & 0xFF0000) >> 16) |
         (playerColour1[p] & 0xFF00) |
         ((playerColour1[p] & 0xFF) << 16);
}

const playerCountryCode = [
  '', 'us', 'ca', 'gb', 'cn', 'in', 'kr', 'br',
  'de', 'fr', 'za', 'pk', 'es', 'fi', 'gr', 'hu',
  'jm', 'it', 'jp', 'ph', 'nz', 'qa', 'ru', 'se',
  'tw', 'cr', 'pl', 'tr', 'ug', 'ye', 'no', 'sa'
];


function randInt (rng, min, max) {
  return Math.floor(rng.quick() * (max - min + 1)) + min;
  // return Math.floor(Math.random() * (max - min + 1)) + min;
}


export function newXYInZone (game, zone) {
  // returns {x: x, y: y};
  let c = bfHitmap.getNewZoneXY(game, zone);
  return {x: c.x, y: c.y};
}


function addBorder (game, zone, border) {
  // Adds border as appropriate land or water neighbour if applicable.
  // Returns true if a different zone was bordering, else false.
  // Supports the concept of land, water and both a land-water.
  let ret = false;
  if(border && border !== zone) {
    if(game.zoneFlags[border] & BF.ZONE_FLAG_LAND_ZONE) {
      if(game.bordersLand[zone].indexOf(border) === -1) {
  	    game.bordersLand[zone].push(border);
    	}
      ret = true;
    }
    if(game.zoneFlags[border] & BF.ZONE_FLAG_WATER_ZONE) {
      if(game.bordersWater[zone].indexOf(border) === -1) {
  	    game.bordersWater[zone].push(border);
    	}
      ret = true;
    }
	}
  return ret;
}


function assignForceTypesInfo (game) {
  // Assumes game.forceID array is already filled with desired forceTypeIds.
  for(let f = 0; f < game.forceID.length; f++) {
    const fid = game.forceID[f];
    if(fid && forceTypeCache[fid]) {
      game.forceName[f] = forceTypeCache[fid].name;
      game.forceNamePlural[f] = forceTypeCache[fid].namePlural;
      game.forceDescription[f] = forceTypeCache[fid].description;
      game.forceFlags[f] = forceTypeCache[fid].flags;
      game.forceCost[f] = forceTypeCache[fid].cost;
      game.forceRange[f] = forceTypeCache[fid].range;
      game.forceAttack[f] = forceTypeCache[fid].attack;
      game.forceDefense[f] = forceTypeCache[fid].defense;
      game.forceCargoCapacity[f] = forceTypeCache[fid].cargoCapacity;
      game.forceCargoSpace[f] = forceTypeCache[fid].cargoSpace;
      game.forceCarrierCapacity[f] = forceTypeCache[fid].carrierCapacity;
      game.forceCarrierSpace[f] = forceTypeCache[fid].carrierSpace;
      game.forceLiftCapacity[f] = forceTypeCache[fid].liftCapacity;
      game.forceLiftSpace[f] = forceTypeCache[fid].liftSpace;
      game.forceProduction[f] = forceTypeCache[fid].production;
      game.forceZ[f] = 1;
      game.forceScale[f] = 1;
      game.forceSfxMake[f] = '';
      game.forceSfxMove[f] = '';
      game.forceSfxDie[f] = '';
      game.forceVersion[f] = forceTypeCache[fid].version;
    }
  }
}


function createInitialForces (game) {
  // Create all the initial forces:
  game.nextForceID = 1;

  for(let i = 1; i <= game.zones; i++) {
    if(bfH.isLand(game, i)) {
      let owner = game.zoneOwner[i];
      if(!owner) {
        owner = 0;
      }
      for(let j = 0; j < game.startingForces[i].length; j++) {
        if(game.forceID[j]) {  // If the forceType is valid.
          const count = game.startingForces[i][j];
          if(count) {
            if(game.forceFlags[j] & BF.FORCE_FLAG_STARTS_ON_LAND) {
              for(let k = 0; k < count; k++) {  // Add the force to land.
                const pos = newXYInZone(game, i);
                bfH.createNewForce(game, owner, j, i, i, pos.x, pos.y);
              }
            }
            else if(game.forceFlags[j] & BF.FORCE_FLAG_STARTS_ON_WATER) {
              if(game.navyZone[i]) {
                for(let k = 0; k < count; k++) {  // Add the force to water.
                  const pos = newXYInZone(game, game.navyZone[i]);
                  bfH.createNewForce(game, owner, j, game.navyZone[i],
                                     game.navyZone[i], pos.x, pos.y);
                }
              }
            }
          }
        }
      }
    }
  }
}


export function mapGenerate (zones,
                             boardYSize, boardXSize,
                             zoneBoxYSize, zoneBoxXSize,
                             mapXScale, mapYScale,
                             srand) {
	// returns map object which is actually a complete standard bf_game object.

  const rng = new xor4096(srand);
  const now = new Date();

  const mapID = 1000000000 + randInt(rng, 0, 999999999);

  const game = {
    _id: 0,
    state: BF.GAME_STATE_STARTING,
  	name: 'Solo Game',  // Game name, up to 47 characters.
    uid: 0,      // Creator uid.
    uName: '',  // Creator name.
  	scenarioID: 0,  // Source scenario id.
    scenarioName: '',
    scenarioUID: 0,
    scenarioUName: '',

    factories: 0,   // Quick reference: Total number of initial production forces (factories).

  	mapID: mapID,  // Source map id (provided by scenario but stored here for convenience).
    mapName: '',
    mapUID: 0,
    mapUName: '',
    mapFlags: BF.MAP_FLAG_LOCKED | BF.MAP_FLAG_CAN_SHOW_AS_BOARD | BF.MAP_FLAG_RAWMAP,
		zones: zones,
    zonesLand: zones,
    zonesWater: 0,

    mapXS: boardXSize,
    mapYS: boardYSize,
    mapData: '',
    zoneKey: [0],
    zoneFlags: [0],

    zoneName: [''],
    bordersLand: [[]],
		bordersWater: [[]],
    zoneIncome: [0],
    zoneBonus: [],

    players: 4,  // Total player count (2 to 30).
    humanPlayers: 1,
    computerPlayers: 3,

    flags: BF.GAME_FLAG_CLIENT_CALCULATED,

    pickingOrder: 1,  // PICKING_ORDER_* constants.
    maxDuration: 0,  // Max duration of game in seconds.  0 for unlimited.
    maxTurns: 0,     // Max number of turns, 0 for unlimited.

  	regScore: [0, 1000000],  // Minimum and Maximum score to register.
  	regMembership: [0, 10],  // Minimum and maximum membership to register.

  	playTimes: [],  // Empty array for any time, or 7 integers with bits set for each hour of
  					        // enabled play.

  	// TODO: timePrestart: 0,  // TODO:  Maybe have a setting for the amount of time to delay before
    							             //        starting after the registration has filled up.
    timeZonePicking: 0,     // Seconds for each zone picking turn, 0 for no limit.
    timeCapitalPicking: 0,  // Seconds for capital picking turn, 0 for no limit.
    timeTurn: 0,            // Seconds for each spring turn, 0 for no limit.
  	// timeEnd: 0,          // Maybe need some time to show the end, so the result surprise is
    						            // not given away too early.

    victoryCapitals: 0,  // 0 for not a victory condition, else number of capitals to own to win.
    victoryLandZones: 0, // 0 for not a victory condition, else number of land zones to own to win.
    victoryIncome: 0,    // 0 for not a victory condition, else income to have to win.
    victoryCash: 0,      // 0 for not a victory condition, else total cash to have to win.

  	sourceGameID: 1342,       // Game id of first parent, if it is an auto-respawn.

  	privatePIN: 0,   // PIN number needed to register or 0 if not a private game.

  	//====== Game State Data Starts ======:

  	turn: 0,	// Turn Index.
              // (000000000) = pre-pre-registration (not-displayed)
              // (000000001+) = pre-registration (displayed)
    				  // (100000000+) = registering
    				  // (200000000+) = pick land zone
    				  // (300000000)  = pick capital
    				  // (400000000+) = normal turns

  	timeLimit: '',  // Date time of time limit for current turn index, '' for none.

    required: 0,   // Turn required bits.
    noWait: 0,     // No wait for player turn bits.

    submitted: 0,  // Player turns already submitted bits.

    autoPlay: 0,  // Bit set for each player whether their auto-play is on.
                  // Option controlled by player but will be automatically set if they
                  // miss game.missedAllowed (2) turns.

    autoSpend: [0],  // AutoPlay percentage of cash to spend 0..100.  Default 100.
    autoMove: [0],   // AutoPlay percentage of movement 0..100.  Default 100.

    teammatesSubmit: 0,  // Bit set for each player whether they allow their teammates
                         // to submit their turn for them.  Option controlled by player
                         // but will be automatically set if they miss a turn in team play.

    //====== All player data also includes a 0 player. ===========
    playerID: [0],
  	playerName: [''],
    playerFlags: [0], // Player flags for each player.
                      // PLAYER_FLAG_* constant bits.
                      // Includes 0 player.
    playerFlag: [''],	// Player flag display, two digit code.
    playerColor: [playerColor(0)],   // Player colors as integers.
    playerScore: [0],  // Starting score for the player (used for elo calculations).
    slotName: [''],
    slotFlag: [''],
    showBuy: [0],  // Bits set for each force type for each player for which they can see in buy window.
    canBuy: [0],  // Bits set for each force type for each player for which they can buy.

  	finished: [[]], // [rank,turnIndex of finish] (0 for not yet eliminated or finished).
                    // Extends to [rank,turnIndex,scoreDelta,xpDelta] once the game ends.
                    // Note, some older games never have the last last two fields.

    playerAutoTotal: [0],  // Total number of turns auto-played for the player.
                           // New field, so may be missing from some games.
    playerAutoCurrent: [0],  // Number of turns auto-played in current streak.
                             // New field, so may be missing from some games.

    playerMissedTotal: [0],  // Total number of turns missed for the player (not auto-played).
                             // New field, so may be missing from some games.
    playerMissedCurrent: [0],  // Number of turns missed in current streak (not auto-played).
                               // New field, so may be missing from some games.

    capital: [0],   // Capital zone id for each player, 0 for unchosen.
    capitalX: [0],  // Capital zone flag X position for each player.
    capitalY: [0],  // Capital zone flag Y position for each player.
    cash: [0],      // Current cash for each player.

    team: [0],  // Team player is in.  0 for no team.  1 for team A, 2 for team B, etc.
    fixedAlly: [0],  // Allies fixed for the entire game, for example team game team members.
                           // Bit mask for each player.
    declaredAlly: [0],  // Allies that are optionally declared, and can be later undeclared as well.
                              // Bit mask for each player.  (not fully implemented)
    // TODO:  nap: [0],   // Player declared NAP, or something.  Work on this.
    force: [[]],
        // An array of a single array for each player (including 0 player) for their forces.
        // BF.FORCE_INDEX_* constants define the indexes below.
        // In that array is then an integer array for each force in the format:
        // [force id,       // [0]
        //  type index,     // [1]
        //  zoneID,         // [2]
        //  zoneIDPrev,     // [3]
        //  retreatZoneID,  // [4]
        //  movementPoints, // [5]
        //  loadPoints,     // [6]
        //  x, y,           // [7] [8]
        //  xPrev, yPrev]   // [9] [10]

    // Force Type Info (all here, we could do dynamic value changes during game as a future feature):
    forceID: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],  // [1, 2, 10],
    forceName: [],
    forceNamePlural: [],
    forceDescription: [],
    forceFlags: [],
    forceCost: [], // Array of force prices.  Allow for dynamic force pricing in the future.
    forceRange: [],
    forceAttack: [],
    forceDefense: [],
    forceCargoCapacity: [],
    forceCargoSpace: [],
    forceCarrierCapacity: [],
    forceCarrierSpace: [],
    forceLiftCapacity: [],
    forceLiftSpace: [],
    forceProduction: [],
    forceZ: [],
    forceScale: [],
    forceSfxMake: [],
    forceSfxMove: [],
    forceSfxDie: [],
    forceVersion: [],

    losses: [[0, 0, 0], [0, 0, 0], [0, 0, 0], [0, 0, 0]],  // Losses to a player from other players
                                        // (+1 per unit, +100 per nation, +10000 per capital).
                                        // TODO: maybe adjust per nation to (+1000 * income).
                                        // Used by AI for enragement and retaliation.

    year: 2000,     // For display, year to display for first turn.  Range of 0 to 8000.

    missedAllowed: 2,   // Number of turns allowed to be missed before put on auto-play.

    autoTotal: 0,    // # of turns over the whole game have been autoplayed by all humans.
    autoCurrent: 0,  // # of turns in current streak have been autoplayed by all humans.
    autoAllowed: 2,  // # of turns of all humans on autoplay to allow before auto ending the game.

    teams: 0,   // 0 for not a team game, else 2 or 3.
    teamName: [],  // Team slot name array.  Max length 24 each for up to 8 teams.
                   // Includes the non-team entry.

    zoneOwner: [0],  // Current turn zone owner, 0 for no owner.  Includes first 0.
    zoneOwnerPrev: [0],  // Previous turn zone owner, 0 for no owner.  Includes first 0.

    nextForceID: 1,   // ID incrementer for creating the next ID for a force.

    maxBuy: [0],  // Maximum number of forces each player may purchase this turn.

    replay: {},   // Dictionary of replay arrays for the previous turn.  This includes movement,
                  // casualties, take overs, picks, eliminations and victories.

  	//====================================

  	created: now,  // time game was created.
  	started: now,  // time game was started.
    ended: '',

    //====================================

    // New SOLO only fields:
		zoneBoxXSize: zoneBoxXSize,
		zoneBoxYSize: zoneBoxYSize,
    mapXScale: mapXScale,
    mapYScale: mapYScale,
		zoneBoxX: [],
		zoneBoxY: [],
    navyZone: [0],

    startingForces: [[]],  // Traditionally in the scenario, but needed for solo.
	};

  assignForceTypesInfo(game);

  let board = [];

  for(let y = 0; y < boardYSize; y++) {
    board[y] = [];
    for(let x = 0; x < boardXSize; x++) {
      board[y].push(0);
    }
  }

  for(let zone = 1; zone <= zones; zone++) {
    game.zoneKey[zone] = zone;
    if(randInt(rng, 0, 99) > 30) {
      game.zoneFlags[zone] = BF.ZONE_FLAG_LAND_ZONE;
      game.zonesLand++;
      game.zoneName[zone] = 'Land Zone ' + zone;
      game.zoneIncome[zone] = randInt(rng, 0, 9);
      // Maybe Move these later:  TODO:
      game.zoneOwner[zone] = randInt(rng, 1, game.players);
      game.startingForces[zone] = [2, 1];
    }
    else {
      game.zoneFlags[zone] = BF.ZONE_FLAG_WATER_ZONE;
      game.zonesLand++;
      game.zoneName[zone] = 'Water Zone ' + zone;
      game.zoneIncome[zone] = 0;
      game.zoneOwner[zone] = 0;
      game.startingForces[zone] = [];
    }
    game.navyZone[zone] = 0;   // TODO: later this needs to be filled properly.
    game.zoneOwnerPrev[zone] = game.zoneOwner[zone];
    game.bordersLand[zone] = [];
 		game.bordersWater[zone] = [];

    let done;
    do {
      done = true;
      // Allow one pixel border around box and an additional one distance from the sides.
      let y = randInt(rng, 2, boardYSize - zoneBoxYSize - 2);
      let x = randInt(rng, 2, boardXSize - zoneBoxXSize - 2);

      for(let yc = -1; yc < zoneBoxYSize + 1; yc++) {
        for(let xc = -1; xc < zoneBoxXSize + 1; xc++) {
          if(board[y + yc][x + xc]) {
            done = false;
            break;
          }
        }
        if(!done) {
        	break;
        }
	    }

	    if(done) {
        for(let yc = -1; yc < zoneBoxYSize + 1; yc++) {
	        for(let xc = -1; xc < zoneBoxXSize + 1; xc++) {
            board[y + yc][x + xc] = zone;
          }
        }
        game.zoneBoxX[zone] = x + Math.floor(game.zoneBoxXSize / 2);
	    	game.zoneBoxY[zone] = y + Math.floor(game.zoneBoxYSize / 2);
	    }

    } while(!done);
  }

  let more;
  do {
  	more = false;
      for(var y = 0; y < boardYSize; y++) {
          for(var x = 0; x < boardXSize; x++) {
              if(!board[y][x]) {
                  switch(randInt(rng, 0, 3)) {
                      case 0:
                          if(y > 0 && board[y - 1][x]) {
                             board[y][x] = board[y - 1][x];
                          }
                          else {
                              more = true;
                          }
                          break;
                      case 1:
                          if(x < boardXSize - 1 && board[y][x + 1]) {
                             board[y][x] = board[y][x + 1];
                          }
                          else {
                              more = true;
                          }
                          break;
                      case 2:
                          if(y < boardYSize - 1 && board[y + 1][x]) {
                             board[y][x] = board[y + 1][x];
                          }
                          else {
                              more = true;
                          }
                          break;
                      case 3:
                          if(x > 0 && board[y][x - 1]) {
                             board[y][x] = board[y][x - 1];
                          }
                          else {
                              more = true;
                          }
                          break;
                      default:
                        // should never happen.
                        more = false;
                        break;
                  }
              }
          }
      }
  } while(more);


  // Calculate borders:
  for(let y = 0; y < boardYSize; y++) {
    for(let x = 0; x < boardXSize; x++) {
    	let zone = board[y][x];
    	if(zone) {
    		if(y > 0) {
    		  addBorder(game, zone, board[y - 1][x]);
    		}
    		if(x > 0) {
    		  addBorder(game, zone, board[y][x - 1]);
    		}
    		if(y < boardYSize - 1) {
    		  addBorder(game, zone, board[y + 1][x]);
    		}
    		if(x < boardXSize - 1) {
    		  addBorder(game, zone, board[y][x + 1]);
    		}
    	}
  	}
	}

  // Finalize mapData string:
  for(let y = 0; y < boardYSize; y++) {
    for(let x = 0; x < boardXSize; x++) {
      game.mapData += String.fromCharCode(board[y][x]);
    }
  }

  // Assign Player Data:
  for(let p = 1; p <= game.players; p++) {
    game.autoSpend[p] = 100;
    game.autoMove[p] = 100;

    game.playerID[p] = p;
    game.playerName[p] = 'Player' + p;
    game.playerFlags[p] = (p === 1) ? BF.PLAYER_FLAG_HUMAN : BF.PLAYER_FLAG_COMPUTER;
    game.playerFlag[p] = playerCountryCode[p];
    game.playerColor[p] = playerColor(p);
    game.playerScore[p] = 0;
    game.slotName[p] = '';
    game.slotFlag[p] = playerCountryCode[p];
    game.showBuy[p] = 1023;
    game.canBuy[p] = 1023;
    game.maxBuy[p] = 250;

    game.finished[p] = [0, 0];

    game.playerAutoTotal[p] = 0;
    game.playerAutoCurrent[p] = 0;
    game.playerMissedTotal[p] = 0;
    game.playerMissedCurrent[p] = 0;
    game.capital[p] = 0;
    game.capitalX[p] = 0;
    game.capitalY[p] = 0;
    game.cash[p] = 0;

    game.team[p] = 0;
    game.fixedAlly[p] = (1 << p);  // Just themselves.

    game.declaredAlly[p] = 0;
    game.force[p] = [];
  }

  // Assign a default capital for each player:
  for(let p = 1; p <= game.players; p++) {
    const capitalID = bfDefaultPick.pickCapital(game, p).pick;
    game.capital[p] = capitalID;
    game.capitalX[p] = game.zoneBoxX[capitalID];
    game.capitalY[p] = game.zoneBoxY[capitalID];

    // Give a factory there:
    game.startingForces[capitalID][game.forceID.length - 1] = 1;
  }

  // Assign Initial Forces here for now:  TODO: maybe move:
  createInitialForces(game);

  return game;
}


export function zoneIDFromXY (game, x, y) {
	// returns ZoneID, 0 for none.
	x = Math.floor(x);
	y = Math.floor(y);

  if( game.mapFlags & BF.MAP_FLAG_BITMAP ) {
    const bitmap = getHitmapFromCache(game.mapID);
    if(!bitmap) {
      return 0;
    }
    if(y >= 0 && y < game.mapYS) {
  		if(x >= 0 && x < game.mapXS) {
        const c = bitmap[y * game.mapXS + x];
        for(let z = 1; z <= game.zones; z++) {
          if(game.zoneKey[z] === c) {
            return z;
          }
        }
      }
    }
    return 0;
  }

  if(game.mapFlags & BF.MAP_FLAG_HEXMAP) {
    const bitmap = getHitmapFromCache(game.mapID);
    if(!bitmap) {
      return 0;
    }
    const HEX_SIZE = 8;
    const destXSize = game.mapXS * HEX_SIZE + Math.floor(HEX_SIZE/2);
    const destYSize = game.mapYS * HEX_SIZE;

    if(y >= 0 && y < destYSize) {
  		if(x >= 0 && x < destXSize) {
        const c = bitmap[y * destXSize + x];
        if(!c) {
          return 0;
        }
        return c;
  		}
  	}
    return 0;
  }

  if(game.mapFlags & BF.MAP_FLAG_RAWMAP) {
    if(y >= 0 && y < game.mapYS) {
  		if(x >= 0 && x < game.mapXS) {
        const c = game.mapData.charCodeAt(y * game.mapXS + x);
        if(!c) {
          return 0;
        }
        return c;
  		}
  	}
    return 0;
  }

	return 0;
}
