import React from 'react';
import { Button, Icon, Step } from 'semantic-ui-react';
import actions from '../../reducers/actions';
import { store } from '../../app/store';
import GuiForceMoveLine from './GuiForceMoveLine';
import BF from '../../bfcore/bfconst1';


export default function GuiZoneMoveInfo (props) {
  let game = props.game;
  let details = props.details;
  let zoneID = props.zoneID;
  let zoneID2 = props.zoneID2;
  let localPlayerIndex = props.localPlayerIndex;

  function stopPropagation (e) {
    e.stopPropagation();
  }

  function showLoadTransports (e) {
    store.dispatch({
      type: actions.SHOW_LOAD_TRANSPORTS_WINDOW,
    });
  }

  function showUnloadTransports (e) {
    store.dispatch({
      type: actions.SHOW_UNLOAD_TRANSPORTS_WINDOW,
    });
  }

  let moveableTotal = 0;
  const yourForces = [];

  for(let f = 0; f < game.forceID.length; f++) {
    if(game.forceCargoCapacity[f] === 0) {  // Non-Transports, they are handled later.
      for(let r = 1; r <= BF.FORCE_MAX_MOVES; r++) { // Only those with range.
        const count = details.forcesByRange[localPlayerIndex][r][f];
        if(count <= 0) {
          continue;
        }
        if(((game.forceFlags[f] & BF.FORCE_FLAG_CAN_MOVE_ON_LAND) &&
            (game.zoneFlags[zoneID2] & BF.ZONE_FLAG_LAND_ZONE))
            ||
            ((game.forceFlags[f] & BF.FORCE_FLAG_CAN_MOVE_ON_WATER) &&
            (game.zoneFlags[zoneID2] & BF.ZONE_FLAG_WATER_ZONE))) {
          const line =
            <GuiForceMoveLine
              key={f + 'a:a' + r}
              forceTypeIndex={f}
              forceType={game.forceID[f]}
              transportCargoKey={''}
              range={r}
              count={count}
              flag={game.playerFlag[localPlayerIndex]}
              localPlayerIndex={localPlayerIndex}
              zoneIDFrom={zoneID}
              zoneIDTo={zoneID2}
              game={game}
            />;
          yourForces.push(line);
          moveableTotal += count;
        }
      }
    }
  }

  for (var key in details.transportsByCargo) {
    const count = details.transportsByCargo[key];
    // key is:  transportForceTypeIndex:range:cargotypeindex1:cargotypeindex2 ...
    const parts = key.split(':');
    const f = parseInt(parts[0]);
    const range = parseInt(parts[1]);
    if(range > 0) {
      if(((game.forceFlags[f] & BF.FORCE_FLAG_CAN_MOVE_ON_LAND) &&
          (game.zoneFlags[zoneID2] & BF.ZONE_FLAG_LAND_ZONE))
          ||
          ((game.forceFlags[f] & BF.FORCE_FLAG_CAN_MOVE_ON_WATER) &&
          (game.zoneFlags[zoneID2] & BF.ZONE_FLAG_WATER_ZONE))) {
        const line =
          <GuiForceMoveLine
            key={'ft' + key}
            forceTypeIndex={f}
            forceType={game.forceID[f]}
            transportCargoKey={key}
            range={range}
            count={count}
            flag={game.playerFlag[localPlayerIndex]}
            localPlayerIndex={localPlayerIndex}
            zoneIDFrom={zoneID}
            zoneIDTo={zoneID2}
            game={game}
          />;
        yourForces.push(line);
        moveableTotal += count;
      }
    }
  }

  if(moveableTotal) {
    // yourForces.push(
    //   <div key="end1">
    //     <Button>Move All { moveableTotal } Moveable Forces</Button>
    //   </div>
    // );
  }
  else {
    yourForces.push(
      <div key="end2">
        You do not have any forces in <b>{ game.zoneName[zoneID] }</b><br />
        that can move directly to <b>{ game.zoneName[zoneID2] }</b>.
      </div>
    );
    // TODO: Give a better explanation if they have locked forces here, need full range to load
    // onto transports, etc.
  }

  if(details.zone2Transports > 0 && (game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE)) {
    yourForces.push(
      <div key="transportLoad">
        <br />
        <Button onPointerUp={ showLoadTransports } size="small">
          <Icon name='download' /> Load Transports
        </Button>
      </div>
    );
  }

  if(details.hasCargo && (game.zoneFlags[zoneID2] & BF.ZONE_FLAG_LAND_ZONE)) {
    yourForces.push(
      <div key="transportUnload">
        <br />
        <Button onPointerUp={ showUnloadTransports } size="small">
          <Icon name='upload' /> Unload Transports
        </Button>
      </div>
    );
  }

  return (
    <Step.Group onPointerDown={ stopPropagation }>
      <Step>
        <Step.Content>
          <Step.Description>
            { yourForces }
          </Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}
