/*
  reducer-working.js
  (c) Human Cube Inc.
*/

import actions from './actions'
import cloneDeep from 'clone-deep';
import { newXYInZone } from '../map/map-generate'
import { changeForcesOwnerInZone, createStartingForces, deleteForcesInZone }
  from '../map/scenarioEditUtil';
import { establishRootState } from '../bfworking/workingUtil';
import BF from '../bfcore/bfconst1';
import bfH from '../bfcore/bf_helper.js';
import {
  forceIndexMatchingForceID,
  forceIndexMatchingAttributes, forceIndexMatchingAttributesRetreat,
  forceIndexMatchingTransportCargoKey, forceIndexMatchingTransportCargoKeyRetreat,
} from '../bfworking/workingForceIndex';


// Y position separator for loaded cargo force rendering:
const CARGO_OFFSET = 0.005;


function updateCargoDestinationPostions (working, transportForceID, x, y) {
  // Update just the positions of the cargo for a force.
  const force = working.force;
  let cargoCount = 0;
  const cargoForceZoneID = BF.FORCE_ZONE_CARGO - transportForceID;
  for(let c = 0; c < force.length; c++) {
    if(force[c].z === cargoForceZoneID) {
      force[c].ax = force[c].x;
      force[c].ay = force[c].y;
      force[c].x = x;
      force[c].y = y - CARGO_OFFSET * 0.5 + cargoCount * CARGO_OFFSET;
      cargoCount++;
    }
  }
}

function updateLandingUsageFromLandingChange (game, working, forceIndex, oldLandingZone, newZone) {
  // Modifies working.
  const force = working.force;
  const forceType = force[forceIndex].ft;

  // Adjust any landing or carrier usage values:
  if(game.forceFlags[forceType] & BF.FORCE_FLAG_MUST_LAND_FRIENDLY) {
    const carrierSpace = game.forceCarrierSpace[forceType];

    if(oldLandingZone && oldLandingZone > 0) {
      if(working.landingCapacity[oldLandingZone] - working.landingUsed[oldLandingZone] <= 0 &&
          carrierSpace >= 0) {
        working.carrierUsed[oldLandingZone] -= carrierSpace;
      }
      else {
        working.landingUsed[oldLandingZone]--;
      }
    }

    if(newZone > 0) {
      if(working.landingCapacity[newZone] - working.landingUsed[newZone] <= 0 &&
          carrierSpace >= 0) {
        working.carrierUsed[newZone] += carrierSpace;
      }
      else {
        working.landingUsed[newZone]++;
      }
    }
  }
}

function updateLandingUsageAndCapacityFromMove (game, working, forceIndex, oldZone, newZone) {
  // Modifies working.
  const force = working.force;
  const forceType = force[forceIndex].ft;

  // Adjust any carrier landing capacity values:
  const carrierCapacity = game.forceCarrierCapacity[forceType];
  if(carrierCapacity > 0) {
    if(oldZone > 0) {
      working.carrierCapacity[oldZone] -= carrierCapacity;
    }
    if(newZone > 0) {
      working.carrierCapacity[newZone] += carrierCapacity;
    }
  }

  // Adjust any landing or carrier usage values:
  if(game.forceFlags[forceType] & BF.FORCE_FLAG_MUST_LAND_FRIENDLY) {
    const carrierSpace = game.forceCarrierSpace[forceType];

    const oldLandingZone = working.landing[forceIndex];
    if(oldLandingZone && oldLandingZone > 0) {
      if(working.landingCapacity[oldLandingZone] - working.landingUsed[oldLandingZone] <= 0 &&
          carrierSpace >= 0) {
        working.carrierUsed[oldLandingZone] -= carrierSpace;
      }
      else {
        working.landingUsed[oldLandingZone]--;
      }
    }

    if(newZone > 0) {
      if(working.landingCapacity[newZone] - working.landingUsed[newZone] <= 0 &&
          carrierSpace >= 0) {
        working.carrierUsed[newZone] += carrierSpace;
      }
      else {
        working.landingUsed[newZone]++;
      }
    }

    working.landing[forceIndex] = newZone;
  }
}

function moveForce (game, working, forceIndex, newZone, x, y) {
  // Modifies working.
  const force = working.force;

  const oldZone = force[forceIndex].z;

  force[forceIndex].ax = force[forceIndex].x;
  force[forceIndex].ay = force[forceIndex].y;
  force[forceIndex].az = force[forceIndex].z;

  if(oldZone !== newZone) {
    if(force[forceIndex].r === 0 && force[forceIndex].zr !== newZone) {
      // no range and also unable to retreat
      return;  // silent fail.
    }
    if(force[forceIndex].r > 0) {
      force[forceIndex].r--;
    }

    updateLandingUsageAndCapacityFromMove(game, working, forceIndex, oldZone, newZone);

    force[forceIndex].z = newZone;
    force[forceIndex].zr = 0;  // Any movement invalidates potential retreat.
  }

  force[forceIndex].x = x;
  force[forceIndex].y = y;

  if(force[forceIndex].move.length === 0) {
    force[forceIndex].move.push({x: x, y: y, z: newZone});
  }
  else if(force[forceIndex].move.length === 1 &&
      force[forceIndex].move[0].z === force[forceIndex].sz) {
    // Force has only been rearranged, so replace it with the first move.
    force[forceIndex].move[0] = {x: x, y: y, z: newZone};
  }
  else if(force[forceIndex].move[force[forceIndex].move.length - 1].z === newZone) {
    force[forceIndex].move[force[forceIndex].move.length - 1] = {x: x, y: y, z: newZone};
  }
  else {
    force[forceIndex].move.push({x: x, y: y, z: newZone});
  }

  updateCargoDestinationPostions(working, force[forceIndex].id, x, y);
}

function moveForceByID (state, game, playerIndex, forceID, zoneID, x, y) {
  // Move a specific force determined by forceID.
  // Returns new cloned and updated state.
  const working = cloneDeep(state);

  const forceIndex = forceIndexMatchingForceID(working, forceID);
  if (working.force[forceIndex].owner !== playerIndex) {
    return;  // Not force owner, silent fail.
  }
  moveForce(game, working, forceIndex, zoneID, x / BF.WORLD_WIDTH, y / BF.WORLD_HEIGHT);

  return working;
}

function moveForces (state, game, playerIndex, zoneID1, zoneID2, count, forceTypeIndex, range,
                     transportCargoKey, isRetreat) {
  // Move a given number and type of forces that have the given range.
  // Optional transportCargoKey.
  // Returns new cloned and updated state.
  const working = cloneDeep(state);

  for(let i = 0; i < count; i++) {
    let forceIndex = -1;

    if(isRetreat) {
      if (transportCargoKey) {
        forceIndex = forceIndexMatchingTransportCargoKeyRetreat(
          working, playerIndex, zoneID1, transportCargoKey, zoneID2,
        );
      }
      else {
        forceIndex = forceIndexMatchingAttributesRetreat(
          working, playerIndex, zoneID1, forceTypeIndex, range, zoneID2,
        );
      }
    }
    else {
      if (transportCargoKey) {
        forceIndex = forceIndexMatchingTransportCargoKey(
          working, playerIndex, zoneID1, transportCargoKey
        );
      }
      else {
        forceIndex = forceIndexMatchingAttributes(
          working, playerIndex, zoneID1, forceTypeIndex, range
        );
      }
    }

    if(forceIndex >= 0) {

      // TODO: confirm that they are land or water neighbours, or whatever...

      let pos = newXYInZone(game, zoneID2);
      moveForce(game, working, forceIndex, zoneID2, pos.x / BF.WORLD_WIDTH, pos.y / BF.WORLD_HEIGHT);
    }
    else {
      // silent fail if none match.
      break;
    }
  }
  return working;
}

function undoForce(game, working, forceIndex) {
  // Undoes movement for a given force.  Assumes cargo has been dealt separately.
  // If called twice for the same force, the move animation will be instant.
  // Modifies working.
  const force = working.force;

  const oldZone = force[forceIndex].z;
  const newZone = force[forceIndex].sz;
  if(oldZone !== newZone) {
    updateLandingUsageAndCapacityFromMove(game, working, forceIndex, oldZone, newZone);
  }

  force[forceIndex].move = [];

  force[forceIndex].ax = force[forceIndex].x;
  force[forceIndex].ay = force[forceIndex].y;
  force[forceIndex].az = force[forceIndex].z;

  force[forceIndex].x = force[forceIndex].sx;
  force[forceIndex].y = force[forceIndex].sy;
  force[forceIndex].z = force[forceIndex].sz;
  force[forceIndex].r = force[forceIndex].sr;
  force[forceIndex].lp = force[forceIndex].slp;
  force[forceIndex].zr = force[forceIndex].szr;
}

function unmoveForces(state, game, playerIndex, zoneID) {
  // Undoes all movement for all forces in zone for given playerIndex.
  // Returns new cloned and updated state.
  const working = cloneDeep(state);
  const force = working.force;

  const forceIndexesToUndoMovement = [];
  const alreadyUndid = {};
  for(let f = 0; f < force.length; f++) {
    if(force[f].z === zoneID && force[f].owner === playerIndex && force[f].move.length > 0) {
      // Step through and add any cargo load and unloads to forceID list:
      for(let m = 0; m < force[f].move.length; m++) {
        const e = force[f].move[m].e;
        if(e === -1) {
          // Was a transport load move.
          const id = force[f].move[m].z;
          const forceIndex = forceIndexMatchingForceID(working, id);
          if(forceIndex >= 0) {
            forceIndexesToUndoMovement.push(forceIndex);
            alreadyUndid[forceIndex] = true;
          }
        }
        else if(e > 0) {
          // Was a transport unload move.
          const id = force[f].move[m].e;
          const forceIndex = forceIndexMatchingForceID(working, id);
          if(forceIndex >= 0) {
            if(!alreadyUndid[forceIndex]) {
              forceIndexesToUndoMovement.push(forceIndex);
              alreadyUndid[forceIndex] = true;
            }
          }
        }
      }

      // Undo any current cargo for this force (it might unload it or it might just unmove with it):
      const cargoForceZoneID = BF.FORCE_ZONE_CARGO - force[f].id;
      for(let c = 0; c < force.length; c++) {
        if(force[c].z === cargoForceZoneID) {
          if(!alreadyUndid[c]) {
            forceIndexesToUndoMovement.push(c);
            alreadyUndid[c] = true;
          }
        }
      }

      if(!alreadyUndid[f]) {
        forceIndexesToUndoMovement.push(f);
        alreadyUndid[f] = true;
      }
    }
  }

  for(let i = 0; i < forceIndexesToUndoMovement.length; i++) {
    undoForce(game, working, forceIndexesToUndoMovement[i]);
  }
  return working;
}

function purchaseForces (state, game, playerIndex, zoneID, forceTypeIndex, count) {
  // Count can only be postive.
  // Buys as many as possible up to count, fails silently.
  // Returns new cloned and updated state.
  const working = cloneDeep(state);

  if(forceTypeIndex < 0 && forceTypeIndex >= game.forceCost.length) {
    return working;
  }

  if(!working.buy[zoneID]) {
    working.buy[zoneID] = {};
  }

  let factoryZoneID = zoneID;

  for(let c = 0; c < count; c++) {
    if(!(game.zoneFlags[zoneID] & BF.ZONE_FLAG_LAND_ZONE)) {
      // Not a land zone so find the neighbouring factory with the most production remaining.
      factoryZoneID = -1;
      let bestProduction = 0;
      for(let b = 0; b < game.bordersLand[zoneID].length; b++) {
        const borderID = game.bordersLand[zoneID][b];
        if(working.production[borderID] > bestProduction) {
          bestProduction = working.production[borderID];
          factoryZoneID = borderID;
        }
      }
    }

    if(game.forceFlags[forceTypeIndex] & BF.FORCE_FLAG_PROD_MAX_ONE_PER_ZONE) {
      if(bfH.zoneHasPlayerForce(game, zoneID, playerIndex, forceTypeIndex)) {
        return working;  // already a force of that type here.
      }
      if(working.buy[zoneID][forceTypeIndex] > 0) {
        return working;  // already a force of that type set to build here.
      }
    }

    if(working.cash < game.forceCost[forceTypeIndex]) {
      return working;  // out of cash.
    }

    if(game.forceFlags[forceTypeIndex] & BF.FORCE_FLAG_REQUIRES_PRODUCTION) {
      if(factoryZoneID < 0 || working.production[factoryZoneID] < 1) {
        return working;  // out of production.
      }
      else {
        working.production[factoryZoneID]--;
        working.productionFrom[zoneID].push(factoryZoneID);
      }
    }

    working.cash -= game.forceCost[forceTypeIndex];

    if(!working.buy[zoneID][forceTypeIndex]) {
      working.buy[zoneID][forceTypeIndex] = 0;
    }
    working.buy[zoneID][forceTypeIndex]++;
  }
  return working;
}

function unpurchaseZoneForces (state, game, playerIndex, zoneID) {
  const working = cloneDeep(state);

  // Cancel buy orders and refund cash:
  if(working.buy && working.buy[zoneID]) {
    for(let forceTypeIndex = 0; forceTypeIndex < game.forceID.length; forceTypeIndex++) {
      const count = working.buy[zoneID][forceTypeIndex];
      if(count) {
        working.cash += game.forceCost[forceTypeIndex] * count;
        working.buy[zoneID][forceTypeIndex] = 0;
      }
    }
  }

  // Refund production points:
  if(Array.isArray(working.productionFrom[zoneID])) {
    for(let i = 0; i < working.productionFrom[zoneID].length; i++) {
      working.production[working.productionFrom[zoneID][i]]++;
    }
  }
  working.productionFrom[zoneID] = [];

  return working;
}

function loadForce (working, game, playerIndex, zoneID, transportZoneID, forceTypeIndex,
                    transportCargoKey) {
  // Does the loading of a single force onto a transport.
  // Modifies working, silently fails if needed.

  // TODO: later we could support cargo sizes different than 1.

  const force = working.force;

  const cargoForceIndex = forceIndexMatchingAttributes(working, playerIndex, zoneID, forceTypeIndex,
                                                       game.forceRange[forceTypeIndex]);
  if(cargoForceIndex < 0) {
    return;  // no matching cargo to load
  }

  const transportForceIndex = forceIndexMatchingTransportCargoKey(working, playerIndex,
                                                              transportZoneID, transportCargoKey);
  if(transportForceIndex < 0) {
    return;  // Player has no transports matching transportCargoKey.
  }

  const x = force[transportForceIndex].x;
  const y = force[transportForceIndex].y;
  const transportID = force[transportForceIndex].id;

  // Adjust cargo position data (actual move is the transports):
  force[cargoForceIndex].az = force[cargoForceIndex].z;
  force[cargoForceIndex].r = 0;  // Once loaded cargo cannot move (except for unload).
  force[cargoForceIndex].z = BF.FORCE_ZONE_CARGO - transportID;
  updateCargoDestinationPostions(working, transportID, x, y);

  // Load up transport:
  force[transportForceIndex].lp--;
  force[transportForceIndex].move.push({x: 0, y: 0, z: force[cargoForceIndex].id, e: -1});
}

function loadForceByID (state, game, playerIndex, forceID, cargoForceID) {
  // Loads a count number of forces onto transports.
  // Returns new cloned and updated state.
  const working = cloneDeep(state);
  const force = working.force;

  const forceIndex = forceIndexMatchingForceID(working, forceID);
  if (force[forceIndex].owner !== playerIndex) {
    return;  // Not force owner, silent fail.
  }
  const cargoForceIndex = forceIndexMatchingForceID(working, cargoForceID);
  if (force[cargoForceIndex].owner !== playerIndex) {
    return;  // Not force owner, silent fail.
  }

  // Adjust cargo position data (actual move is the transports):
  force[cargoForceIndex].az = force[cargoForceIndex].z;
  force[cargoForceIndex].r = 0;  // Once loaded cargo cannot move (except for unload).
  force[cargoForceIndex].z = BF.FORCE_ZONE_CARGO - forceID;
  updateCargoDestinationPostions(working, forceID, force[forceIndex].x, force[forceIndex].y);

  // Load up transport:
  force[forceIndex].lp--;
  force[forceIndex].move.push({x: 0, y: 0, z: force[cargoForceIndex].id, e: -1});

  return working;
}

function loadForces (state, game, playerIndex, zoneID, transportZoneID, forceTypeIndex, count,
                     transportCargoKey) {
  // Loads a count number of forces onto transports.
  // Returns new cloned and updated state.
  const working = cloneDeep(state);
  for(let c = 0; c < count; c++) {
    loadForce(working, game, playerIndex, zoneID, transportZoneID, forceTypeIndex,
      transportCargoKey);
  }
  return working;
}

function unloadForce (working, game, playerIndex, zoneID, zoneID2, forceTypeIndex) {
  // Does the unloading of a single force from a transport in zoneID to zoneID2.
  // Modifies working, silently fails if needed.
  const force = working.force;
  const forces = force.length;

  // TODO: could check that zoneID2 is a land neighbour.

  for(let i = 0; i < forces; i++) {
    if(force[i].z === zoneID && force[i].owner === playerIndex) {
      const cargoForceZoneID = BF.FORCE_ZONE_CARGO - force[i].id;
      for(let c = 0; c < forces; c++) {
        if(force[c].z === cargoForceZoneID && force[c].ft === forceTypeIndex) {
          const pos = newXYInZone(game, zoneID2);
          const x = pos.x / BF.WORLD_WIDTH;
          const y = pos.y / BF.WORLD_HEIGHT;

          // Transport move:
          // extra is forceID of cargo to unload (zoneID, x, y is for destination of cargo).
          force[i].move.push({x: x, y: y, z: zoneID2, e: force[c].id});
          force[i].r = 0;   // transport cannot move after unloading.
          force[i].lp = 0;  // transport cannot load after unloading.

          // Cargo Position update:
          force[c].ax = force[c].x;
          force[c].ay = force[c].y;
          force[c].az = force[c].z;  // zoneId as loaded cargo (the previous current value)
          force[c].x = x;
          force[c].y = y;
          force[c].z = zoneID2;
          force[c].r = 0;  // force cannot move after unloading.

          return;
        }
      }
    }
  }
}

function unloadForceByID (state, game, playerIndex, forceID, zoneID, x, y, cargoForceID) {
  // Unloads a force specified by cargoForceID from transport of forceID to zoneID.
  // Returns new cloned and updated state.
  const working = cloneDeep(state);
  const force = working.force;

  console.log('want to unloadForceByID', forceID, zoneID, x, y, cargoForceID);

  const forceIndex = forceIndexMatchingForceID(working, forceID);
  if(force[forceIndex].owner !== playerIndex) {
    return;  // Not force owner, silent fail.
  }
  const cargoForceIndex = forceIndexMatchingForceID(working, cargoForceID);
  if (force[cargoForceIndex].owner !== playerIndex) {
    return;  // Not force owner, silent fail.
  }

  console.log('unloadForceByID', forceIndex, cargoForceIndex);

  // TODO: something is WRONG after here. 

  // Transport move:
  // extra is forceID of cargo to unload (zoneID, x, y is for destination of cargo).
  force[forceIndex].move.push({ x, y, z: zoneID, e: cargoForceID });
  force[forceIndex].r = 0;   // transport cannot move after unloading.
  force[forceIndex].lp = 0;  // transport cannot load after unloading.

  // Cargo Position update:
  force[cargoForceIndex].ax = force[cargoForceIndex].x;
  force[cargoForceIndex].ay = force[cargoForceIndex].y;
  force[cargoForceIndex].az = force[cargoForceIndex].z;  // zoneId as loaded cargo (the previous current value)
  force[cargoForceIndex].x = x / BF.WORLD_WIDTH;
  force[cargoForceIndex].y = y / BF.WORLD_HEIGHT;
  force[cargoForceIndex].z = zoneID;
  force[cargoForceIndex].r = 0;  // force cannot move after unloading.

  return working;
}

function unloadForces (state, game, playerIndex, zoneID, zoneID2, forceTypeIndex, count) {
  // Unloads a count number of forces from transports in zoneID to zoneID2.
  // Returns new cloned and updated state.
  const working = cloneDeep(state);
  for(let c = 0; c < count; c++) {
    unloadForce(working, game, playerIndex, zoneID, zoneID2, forceTypeIndex);
  }
  return working;
}

function landForce (working, game, playerIndex, zoneID, forceTypeIndex,
                    oldLandingZone, newLandingZone, range) {
  // Updates desired landing zone for matching force.
  // Modifies working.
  const force = working.force;
  const landing = working.landing;

  for(const f in landing) {
    if(landing[f] === oldLandingZone &&
        force[f].owner === playerIndex &&  // This actually will always be true.
        force[f].ft === forceTypeIndex &&
        force[f].z === zoneID &&
        force[f].r === range) {
      updateLandingUsageFromLandingChange(game, working, f, oldLandingZone, newLandingZone);
      landing[f] = newLandingZone;
      return;
    }
  }
}

function specifyForcesLanding (state, game, playerIndex, zoneID, forceTypeIndex,
                               oldLandingZone, newLandingZone, range, count) {
  // Returns new cloned and updated state.
  const working = cloneDeep(state);
  for(let c = 0; c < count; c++) {
    landForce(working, game, playerIndex, zoneID, forceTypeIndex, oldLandingZone, newLandingZone, range);
  }
  return working;
}


export default function working(state = {}, action) {
  switch (action.type) {
    case actions.NAVIGATE_TO:
    case actions.NAVIGATE_BACK:
      if(action.where.gameID && state.gameID !== action.where.gameID) {
        return {};
      }
      return state;
    case actions.SET_GAME:
//      if(store.getState().gameID === action.game._id) {
        return establishRootState(action.game, action.localPlayerIndex);
//      }
//      return {};

    case actions.SET_WORKING_AI:
      const newState = Object.assign({}, state);
      newState.ai = action.ai;
      return newState;

    case actions.MOVE_SOME_FORCES:
      return moveForces(state, action.game, action.playerIndex, action.zoneIDFrom, action.zoneIDTo,
        action.count, action.forceTypeIndex, action.range, action.transportCargoKey, action.isRetreat);
    case actions.UNDO_ZONE_FORCE_MOVEMENTS:
      return unmoveForces(state, action.game, action.playerIndex, action.zoneID);

    case actions.MOVE_FORCE_BY_ID:  // Explicitly move a force by forceID, used only by AI play so far.
      return moveForceByID(state, action.game, action.playerIndex,
        action.forceID, action.zoneID, action.x, action.y,
      );
    case actions.LOAD_FORCE_BY_ID:  // Explicitly load a force by forceID, used only by AI play so far.
      return loadForceByID(state, action.game, action.playerIndex,
        action.forceID, action.cargoForceID,
      );
    case actions.UNLOAD_FORCE_BY_ID:  // Explicitly unload a force by forceID, used only by AI play so far.
      return unloadForceByID(state, action.game, action.playerIndex,
        action.forceID, action.zoneID, action.x, action.y, action.cargoForceID,
      );

    case actions.PURCHASE_SOME_FORCES:  // Used by both Human and AI play.
      return purchaseForces(state, action.game, action.playerIndex, action.zoneID,
        action.forceTypeIndex, action.count);
    case actions.UNPURCHASE_ZONE_FORCES:
      return unpurchaseZoneForces(state, action.game, action.playerIndex, action.zoneID);
    case actions.LOAD_SOME_FORCES:
      return loadForces(state, action.game, action.playerIndex, action.zoneID,
        action.transportZoneID, action.forceTypeIndex, action.count, action.transportCargoKey);
    case actions.UNLOAD_SOME_FORCES:
      return unloadForces(state, action.game, action.playerIndex, action.zoneID, action.zoneID2,
        action.forceTypeIndex, action.count);
    case actions.SPECIFY_FORCES_LANDING:
      return specifyForcesLanding(state, action.game, action.playerIndex, action.zoneID,
        action.forceTypeIndex, action.oldLandingZone, action.newLandingZone, action.range,
        action.count);

    case actions.SCENARIO_EDIT_NAVY_ZONE:
      // Remove all existing forces in old navy zone if > 0.
      // Create new forces in the new navy zone if > 0;
      {
        const working = cloneDeep(state);
        const { game, navyZone, scenarioEdit, zoneID } = action;
        const prevNavyZone = scenarioEdit.navyZone[zoneID];
        if(prevNavyZone > 0) {
          deleteForcesInZone(working, prevNavyZone);
        }
        if(navyZone > 0) {
          createStartingForces(working, game, scenarioEdit.forceID,
                               scenarioEdit.startingForces[zoneID],
                               scenarioEdit.owner[zoneID], 0, navyZone);
        }
        return working;
      }
    case actions.SCENARIO_EDIT_STARTING_FORCES:
      // Remove all existing forces in zone and in navy zone, then create the new ones needed in
      // the zone and navy zone.
      {
        const working = cloneDeep(state);
        const { game, scenarioEdit, startingForces, zoneID } = action;
        const navyZone = scenarioEdit.navyZone[zoneID];
        deleteForcesInZone(working, zoneID);
        if(navyZone > 0) {
          deleteForcesInZone(working, navyZone);
        }
        createStartingForces(working, game, scenarioEdit.forceID, startingForces,
                             scenarioEdit.owner[zoneID], zoneID, navyZone);
        return working;
      }
    case actions.SCENARIO_EDIT_ZONE_OWNER:
      // Change the owner of all forces in this zone and in its navy zone.
      {
        const working = cloneDeep(state);
        const { scenarioEdit, zoneID, owner } = action;
        const navyZone = scenarioEdit.navyZone[zoneID];
        changeForcesOwnerInZone(working, zoneID, owner);
        if(navyZone > 0) {
          changeForcesOwnerInZone(working, navyZone, owner);
        }
        return working;
      }
    default:
      return state;
  }
}
