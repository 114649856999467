
const mainModes = {
  START_UP: 100,
  LOGIN: 101,
  HOME: 102,
  FINISHED_GAMES: 103,
  MEMBERSHIP_OPTIONS: 104,
  RANKS: 105,
  LEADERBOARD_SCORE: 106,
  LEADERBOARD_XP: 107,

  BLOCK_LIST: 108,
  FRIEND_FOE_LIST: 109,

  JOIN_SOLO: 110,
  JOIN_MULTI: 111,
  NEWS: 112,

  RULES_LAND_WAR: 113,
  RULES_STANDARD: 114,
  FORCE_TYPES: 115,
  CONDUCT: 116,

  MAP_DETAIL: 197,
  SCENARIO_DETAIL: 198,
  PLAYER_DETAIL: 199,
  GAME_DETAIL: 200,

  GAME_LOADING: 201,
  GAME_LOADING_ERROR: 202,
  GAME_REPLAY: 203,
  GAME_DOING_TURN: 204,
  GAME_SUBMITTING_TURN: 205,

  SCENARIO_PREVIEW: 300,
  SCENARIO_EDIT: 301,

  MY_MAPS: 500,
  MY_SCENARIOS: 501,

  MAP_EDIT: 600,

};

export default mainModes;
