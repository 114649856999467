/*
  BaseGameDetail.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Button, Grid, Table } from 'semantic-ui-react';

import BaseAdSense from './BaseAdSense';
import BaseBackScroll from './BaseBackScroll';
import BaseGameDetailAutoPlay from './BaseGameDetailAutoPlay';
import BaseGameDetailDetails from './BaseGameDetailDetails';
import BaseGameDetailPlayer from './BaseGameDetailPlayer';
import BaseMenuBasic from './BaseMenuBasic';
import GuiCountdown from '../GameGui/GuiCountdown';
import actions from '../../reducers/actions';
import { store } from '../../app/store';
import mainModes from '../../reducers/main-modes'
import BF from '../../bfcore/bfconst1';
import bfH from '../../bfcore/bf_helper.js';
import { sendPacket } from '../../network/network';
import { calculateIncomeAndZones } from '../../helper/game';
import { userOnFirstGame } from '../../helper/user';
import backgroundDefault from './img/background/camotile.jpg';
import background1 from './img/background/world.jpg';
import background3 from './img/background/sahara.jpg';
import background4 from './img/background/normandy.jpg';
import background5 from './img/background/tankcamel.jpg';
import background6 from './img/background/warships.jpg';

function renderTurnRequiredNamesString (game, required, submitted) {
  // Returns a string of required turn player names as well as the suffix of 's' or ''.
  const requiredNames = [];

  for(let p = 1; p <= game.players; p++) {
    if( (required & (1<<p)) && !(submitted & (1<<p)) ) {
      requiredNames.push(game.playerName[p]);
    }
  }

  let renderedNames = '';
  if(requiredNames.length === 1) {
    renderedNames += requiredNames[0];
  }
  else {
    for(let r = 0; r < requiredNames.length; r++) {
      if(r === requiredNames.length - 1) {
        renderedNames += ' and ' + requiredNames[r];
      }
      else {
        renderedNames += ' ' + requiredNames[r] + ((r < requiredNames.length - 2) ? ',' : '');
      }
    }
  }
  return [renderedNames, requiredNames.length > 1 ? 's' : ''];
}

function PlayForButton ({gameID, game, uid, uName}) {

  const gotoGame = (e) => {
    // TODO: updated this to navigate to new URL of play...
    // if(game._id === gameID) {
    //   store.dispatch({type: actions.NAVIGATE_TO, where: {mode: mainModes.GAME_REPLAY,
    //                                                      gameID,
    //                                                      playFor: {uid, uName},
    //                                                     }});
    // }
    // else {
    // Force this to always be GAME_LOADING as it is the only way to make playFor work in the
    // working reducers. GAME_LOADING will force the game core to be fetched and assign the
    // localPlayerIndex properly.
    store.dispatch({
      type: actions.NAVIGATE_TO, where: {
        mode: mainModes.GAME_LOADING,
        gameID,
        playFor: { uid, uName },
      }
    });
    // }
  }
  return (
    <Button size='small' color='olive' onPointerUp={gotoGame} style={{marginBottom: '3px'}} >
      Play For Teammate { uName }
    </Button>
  );
}


export default class BaseGameDetail extends React.Component {

  state = {
    store: {},
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.
    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store !== this.state.store) {
      this.setState({store: nextProps.store});
    }
  }

  gotoGame = (e) => {
    // TODO: updated this to navigate to new URL of play...
    e.stopPropagation();
    const { game, gameID } = this.state.store;
    if(game._id === gameID) {
      store.dispatch({type: actions.NAVIGATE_TO, where: {mode: mainModes.GAME_REPLAY, gameID}});
    }
    else {
      store.dispatch({type: actions.NAVIGATE_TO, where: {mode: mainModes.GAME_LOADING, gameID}});
    }
  };

  joinGame = (e) => {
    e.stopPropagation();
    const gameID = this.state.store.gameID;
    sendPacket('bf_gameJoin', { gameID });
  };

  unjoinGame = (e) => {
    e.stopPropagation();
    const gameID = this.state.store.gameID;
    sendPacket('bf_gameUnjoin', { gameID });
  };

  cancelGame = (e) => {
    e.stopPropagation();
    const gameID = this.state.store.gameID;
    sendPacket('bf_gameCancel', { gameID });
  };

  toggleAllowTeammatePlay = (e) => {
    e.stopPropagation();
    const { gameID, game, gameStatus, userSession } = this.state.store;
    if(gameID && gameStatus[gameID]) {
      const { playerID } = gameStatus[gameID];
      const localUserID = userSession.uid;
      let localPlayerIndex = 0;
      for(let p = 1; p < playerID.length; p++) {
        if(playerID[p] === localUserID) {
          localPlayerIndex = p;
          break;
        }
      }
      if(localPlayerIndex) {
        let allow = 1;
        if(game.teammatesSubmit & (1 << localPlayerIndex)) {
          allow = 0;
        }
        sendPacket('bf_in', {e: 'allowTeammatesSubmit', gameID, allow});
      }
    }
  };

  render() {
    const { game, gameID, gameStatus, server, userSession } = this.state.store;
    const localUserID = userSession.uid;
    const { uName } = userSession.bf;

    const mapID = game.mapID;  // Note might be undefined until the game loads.

    const serverTimeOffsetMS = server.serverSecondsOffset * 1000;

    const { playerIncome, playerZones } = calculateIncomeAndZones(game);

    let name = '';
    let players = 0;
    let playerID = [];
    let turn = 0;
    let required = 0;
    let submitted = 0;
    let timeLimit = null;
    let year = 2000;

    let showRegister = false;
    let showUnregister = false;
    let showCancel = false;
    let inGame = false;
    let localPlayerIndex = 0;
    const playerRows = [];
    let statusString = null;
    let gameInProgress = false;
    let viewGameButtonText = 'View Game';
    let viewGameButtonColor = 'olive';

    if(gameStatus[gameID]) {
      ({name, players, playerID, turn, timeLimit, required, submitted, year} = gameStatus[gameID]);
      let openSlots = 0;

      for(let p = 1; p <= players; p++) {
        if(playerID[p] === localUserID) {
          localPlayerIndex = p;
          inGame = true;
        }
        if(playerID[p] === 0) {
          openSlots++;
        }
        playerRows.push(
          <BaseGameDetailPlayer
            key={ 'p' + p }
            store={ this.state.store }
            playerIndex={ p }
            playerIncome={ playerIncome[p] }
            playerZones={ playerZones[p] }
          />
        );
      }

      if(game.state === BF.GAME_STATE_STARTED) {
        gameInProgress = true;
      }

      if(uName === 'GameAdmin') {
        if(game.state === BF.GAME_STATE_REGISTERING || game.state === BF.GAME_STATE_STARTING ||
            game.state === BF.GAME_STATE_STARTED)
          showCancel = true;
      }
      else if(game.uid === localUserID) {
        if(game.state === BF.GAME_STATE_REGISTERING ||
            (game.humanPlayers === 1 && (game.state === BF.GAME_STATE_STARTING ||
            game.state === BF.GAME_STATE_STARTED)))
          showCancel = true;
      }
      else {
        if(game.state === BF.GAME_STATE_REGISTERING) {
          if(inGame)
            showUnregister = true;
          else showRegister = true;
        }
      }

      if(game.state === BF.GAME_STATE_UNKNOWN) {
        statusString = 'Game State Unknown.';
      }
      else if(game.state === BF.GAME_STATE_PREREGISTERING) {
        statusString = 'Player registration has not begun.';
      }
      else if(game.state === BF.GAME_STATE_REGISTERING) {
        if(showRegister) {
          let extraText = null;
          if(game.flags & BF.GAME_FLAG_PRIVATE) {
            extraText = 'This is a private game. A PIN is required to join.';
          }
          if(game.flags & BF.GAME_FLAG_PREMIUM_MEMBERS_ONLY) {
            extraText = 'Premium or Extreme Membership required to join this game.';
          }
          else if(game.flags & BF.GAME_FLAG_EXTREME_MEMBERS_ONLY) {
            extraText = 'Extreme Membership required to join this game.';
          }

          statusString =
            <div>
              Waiting for { openSlots === 1 ? '1 final player' : openSlots + ' players' } to join.
              <br />
              { extraText }{ extraText && <br /> }
              <Button size='large' color='olive'  onPointerUp={ this.joinGame } >
                Join Game
              </Button>
            </div>;

        }
        else {
          statusString =
            <div>
              Waiting for { openSlots === 1 ? '1 other player' : openSlots + ' other players' } to
              join.
            </div>;
        }
      }
      else if(game.state === BF.GAME_STATE_STARTING) {
        statusString = 'Game is starting.';
      }
      else if(game.state === BF.GAME_STATE_STARTED) {
        if(inGame && (required & (1<<localPlayerIndex)) &&
            (submitted & (1<<localPlayerIndex)) === 0) {
          statusString = 'It is your turn!';
          viewGameButtonText = 'Play Game';
          viewGameButtonColor = 'green';
        }
        else if(required && required !== submitted) {
          statusString = [];
          if(game.teams > 1 && localPlayerIndex > 0) {
            // If team game, show 'Play For' buttons if applicable.
            for(let p = 1; p <= game.players; p++) {
              if(p !== localPlayerIndex && game.team[p] === game.team[localPlayerIndex] &&
                 ((required & (1<<p)) && (submitted & (1<<p)) === 0) &&
                 (game.teammatesSubmit & (1<<p))
                  ) {
                statusString.push(<PlayForButton key={ 'pf' + p }
                                                 gameID={ gameID }
                                                 game={ game }
                                                 uid={ game.playerID[p] }
                                                 uName={ game.playerName[p] } />);
              }
            }
          }
          if(statusString.length === 0) {
            const [string, suffix] = renderTurnRequiredNamesString(game, required, submitted);
            statusString =
              <div>
                Waiting for<br />
                { string }<br />
                to complete their turn{ suffix }.
              </div>;
          }
        }
        else {
          statusString = 'Server is calculating turn...';
        }
      }
      else if(game.state === BF.GAME_STATE_FINISHED) {
        statusString = 'Game has finished.';
      }
      else if(game.state === BF.GAME_STATE_ERROR) {
        statusString = 'Game Error.';
      }
      else if(game.state === BF.GAME_STATE_CANCELLED) {
        statusString = 'Game has been canceled.';
      }
      else if(game.state === BF.GAME_STATE_EXPIRED) {
        statusString = 'Game has expired.';
      }
      else {
        statusString = '';
      }
    }

    let background = backgroundDefault;
    if(mapID === 1) {  // Standard World
      background = background1;
    }
    else if(mapID === 3) {  // Africa
      background = background3;
    }
    else if(mapID === 4) {  // WW2
      background = background4;
    }
    else if(mapID === 5) {  // Indian Ocean
      background = background5;
    }
    else if(mapID === 6) {  // Hex Armada
      background = background6;
    }

    const gridStyle = { padding: '6px 36px 6px 36px', background:'#FFFFFF', opacity: 0.86 };

    const gameOptions =
      <Grid.Column style={ gridStyle }>
        <Table attached='top' basic verticalAlign='top'>
          <Table.Header>
            <Table.Row>
              { (localPlayerIndex > 0 && game.state === BF.GAME_STATE_STARTED) &&
                <Table.HeaderCell textAlign='center'>
                  <BaseGameDetailAutoPlay game={ game } localPlayerIndex={ localPlayerIndex } />
                </Table.HeaderCell>
              }
              { (localPlayerIndex > 0 && game.teams > 1 &&
                  game.state === BF.GAME_STATE_STARTED) &&
                <Table.HeaderCell textAlign='center'>
                  <Button size='medium' color='grey' onClick={ this.toggleAllowTeammatePlay }>
                    Allow Teammates To Submit<br />My Turn&nbsp;&nbsp;&nbsp;
                    { (game.teammatesSubmit & (1 << localPlayerIndex)) ?
                       <b style={{color: 'green'}}> (ON)</b> : ' (OFF)' }
                  </Button>
                </Table.HeaderCell>
              }
              { showUnregister &&
                <Table.HeaderCell textAlign='center'>
                  <Button size='large' color='olive' onPointerUp={ this.unjoinGame } >
                    Unjoin Game
                  </Button>
                </Table.HeaderCell>
              }
              { showCancel &&
                <Table.HeaderCell textAlign='center'>
                  <Button size='large' color='olive' onPointerUp={ this.cancelGame } >
                    Cancel Game
                  </Button>
                </Table.HeaderCell>
              }
            </Table.Row>
          </Table.Header>
        </Table>
      </Grid.Column>;

    return (
      <BaseBackScroll background={ background } >
        <BaseMenuBasic pageName={ name } leftText={'#' + gameID} />
        <center>
          <br /><br /><br />
          <BaseAdSense />
        </center>
        <br />
        <Grid centered columns={ 2 } style={{ padding: '6px' }}>
          <Grid.Column style={ gridStyle }>
            <h3>{ name }</h3>
            <Table attached='top' basic verticalAlign='top'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign='center'>
                    <Button size='massive'
                            color={ viewGameButtonColor }
                            onPointerUp={ this.gotoGame } >
                      { viewGameButtonText }
                    </Button>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>
                    <div>
                      { bfH.turnIndexAsString(turn, year) }<br />
                      { gameInProgress &&
                        <GuiCountdown date={ timeLimit } offsetMS={ serverTimeOffsetMS } />
                      }
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>
                    { statusString }
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table>
          </Grid.Column>
          { game.state === BF.GAME_STATE_REGISTERING &&
            <Grid.Column style={ gridStyle }>
              <h5>Game Details</h5>
              <BaseGameDetailDetails game={ game } map={ this.state.store.map } />
            </Grid.Column>
          }
          <Grid.Column style={ gridStyle }>
            <h5>Players in this Game</h5>
            <Table striped attached='top' basic verticalAlign='top'>
              <Table.Header>
                <Table.Row>
                  { (game.state !== BF.GAME_STATE_FINISHED &&
                    game.state !== BF.GAME_STATE_REGISTERING) &&
                    <Table.HeaderCell textAlign='center'>Turn</Table.HeaderCell>
                  }
                  { game.teams > 1 &&
                    <Table.HeaderCell textAlign='center'>Team</Table.HeaderCell>
                  }
                  <Table.HeaderCell>Player</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Finish</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Income</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Cash</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Forces</Table.HeaderCell>
                  { game.state !== BF.GAME_STATE_FINISHED &&
                    <Table.HeaderCell textAlign='center'>MaxBuy</Table.HeaderCell>
                  }
                  <Table.HeaderCell textAlign='center'>Zones</Table.HeaderCell>
                  { game.state === BF.GAME_STATE_FINISHED &&
                    <Table.HeaderCell textAlign='center'>Points</Table.HeaderCell>
                  }
                  { game.state === BF.GAME_STATE_FINISHED &&
                    <Table.HeaderCell textAlign='center'>XPs</Table.HeaderCell>
                  }
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { playerRows }
              </Table.Body>
            </Table>
            { (game.state === BF.GAME_STATE_REGISTERING &&
              !(game.flags & BF.GAME_FLAG_CAN_CHOOSE_PLAYER_INDEX)) &&
              <div><b>Player Slots will be randomly reordered once the game starts.</b></div>
            }
          </Grid.Column>

          { !userOnFirstGame() && gameOptions }
          { game.state !== BF.GAME_STATE_REGISTERING &&
            <Grid.Column style={ gridStyle }>
              <h5>Game Details</h5>
              <BaseGameDetailDetails game={ game } map={ this.state.store.map } />
            </Grid.Column>
          }
          { userOnFirstGame() && gameOptions }

        </Grid>
        <br /><br /><br />
        <br /><br /><br />
      </BaseBackScroll>
    );
  }
}
