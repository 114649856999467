/*
  Force.js
*/

import React from "react";
import { Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";

import fi1 from './img/forcetype/fi1.png';
import fi2 from './img/forcetype/fi2.png';
import fi3 from './img/forcetype/fi3.png';
import fi4 from './img/forcetype/fi4.png';
import fi5 from './img/forcetype/fi5.png';
import fi6 from './img/forcetype/fi6.png';
import fi7 from './img/forcetype/fi7.png';
import fi8 from './img/forcetype/fi8.png';
import fi9 from './img/forcetype/fi9.png';
import fi10 from './img/forcetype/fi10.png';


const centerAnchor = new PIXI.Point(0.5, 0.5);

export default function Force(props) {
  let texture;
  switch(props.type) {
    case 1: texture = fi1; break;
    case 2: texture = fi2; break;
    case 3: texture = fi3; break;
    case 4: texture = fi4; break;
    case 5: texture = fi5; break;
    case 6: texture = fi6; break;
    case 7: texture = fi7; break;
    case 8: texture = fi8; break;
    case 9: texture = fi9; break;
    case 10: texture = fi10; break;
    default: texture = ''; break;
  }
  const coreProps = (({ type, ...o }) => o)(props);  // Exclude type field.
  return (
    <Sprite
      anchor={ centerAnchor }
      texture={ PIXI.Texture.from(texture) }
      { ...coreProps }
    />
  );
}
