/*
  BaseMapCreate.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Button, Checkbox, Form, Modal, Segment } from 'semantic-ui-react';

import actions from '../../reducers/actions';
import { store } from '../../app/store';
import mainModes from '../../reducers/main-modes'
import BF from '../../bfcore/bfconst1';
import { randMap } from '../../helper/map';


export default class BaseMapCreate extends React.Component {
  state = {
    closeHandle: null,
    store: {},
    mapFlags: 0,
    width: 20,
    height: 20,
    randomizer: 25,
    waterPercent: 25,
    zones: 30,
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.
    this.state.closeHandle = props.closeHandle;
    this.state.store = props.store;
    this.state.mapFlags = 0;
    this.state.width = 20;
    this.state.height = 20;
    this.state.randomizer = 25;
    this.state.waterPercent = 25;
    this.state.zones = 30;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.closeHandle !== this.state.closeHandle || nextProps.store !== this.state.store) {
      this.setState({
        closeHandle: nextProps.closeHandle,
        store: nextProps.store,
        // mapFlags: 0,
        // width: 20,
        // height: 20,
        // randomizer: 25,
        // waterPercent: 25,
        // zones: 30,
      });
    }
  }

  cancelClose = (e) => {
    e.stopPropagation();

    if(this.state.closeHandle) {
      this.state.closeHandle();
    }
  }

  createNewMapClose = (e) => {
    e.stopPropagation();

    const { mapFlags, width, height, randomizer, waterPercent, zones } = this.state;
    const { uid, uName } = this.state.store.userSession;

    if(this.state.closeHandle) {
      this.state.closeHandle();
    }

    const mapEdit = randMap(randomizer, width, height, zones, waterPercent, mapFlags, uid, uName);
    store.dispatch({type: actions.MAP_EDIT_SET_MAP, mapEdit});
    store.dispatch({type: actions.NAVIGATE_TO, where: {mode: mainModes.MAP_EDIT}});
  };

  mapFlagsChange = (e, { value }) => {
    this.setState({ mapFlags: this.state.mapFlags ^ value });
  };

  heightChange = (e, { value }) => this.setState({ height: value });

  widthChange = (e, { value }) => this.setState({ width: value });

  zonesChange = (e, { value }) => this.setState({ zones: value });

  randomizerChange = (e, { value }) => this.setState({ randomizer: value });

  waterPercentChange = (e, { value }) => this.setState({ waterPercent: value });

  render() {
    const { mapFlags, width, height, randomizer, waterPercent, zones } = this.state;

    return (
      <Modal
          closeOnDimmerClick={ false }
          closeOnEscape={ false }
          dimmer='inverted'
          open={ true }
          size='small'>
        <Modal.Header>Create New Map</Modal.Header>
        <Modal.Description>
          <div>
            <Segment key='zones' compact style={{ float:'left' }} >
              <Form>
                <Form.Field>
                  <Form.Input
                    label={`${ zones } Zones`}
                    min={ 2 }
                    max={ 250 }
                    name='zones'
                    onChange={ this.zonesChange }
                    step={ 1 }
                    type='range'
                    value={ zones }
                  />
                </Form.Field>
              </Form>

              <Form>
                <Form.Field>
                  <Form.Input
                    label={`${ waterPercent }% Water`}
                    min={ 0 }
                    max={ 99 }
                    name='waterPercent'
                    onChange={ this.waterPercentChange }
                    step={ 1 }
                    type='range'
                    value={ waterPercent }
                  />
                </Form.Field>
              </Form>

              <Form>
                <Form.Field>
                  <Form.Input
                    label={`Randomizer: ${ randomizer }`}
                    min={ 7 }
                    max={ 100 }
                    name='randomizer'
                    onChange={ this.randomizerChange }
                    step={ 1 }
                    type='range'
                    value={ randomizer }
                  />
                </Form.Field>
              </Form>
            </Segment>

            <Segment key='size' compact style={{ float:'left' }} >
              <Form>
                <Form.Field>
                  <Form.Input
                    label={`Width: ${ width }`}
                    min={ 10 }
                    max={ 100 }
                    name='width'
                    onChange={ this.widthChange }
                    step={ 2 }
                    type='range'
                    value={ width }
                  />
                </Form.Field>
              </Form>
              <Form>
                <Form.Field>
                  <Form.Input
                    label={`Height: ${ height }`}
                    min={ 10 }
                    max={ 100 }
                    name='height'
                    onChange={ this.heightChange }
                    step={ 2 }
                    type='range'
                    value={ height }
                  />
                </Form.Field>
              </Form>
            </Segment>

            <Segment key='wrapping' compact style={{ float:'left' }} >
              <Form>
                <Form.Field>
                  <Checkbox name='wrapX'
                            label={ <label>Map Wraps Left to Right</label> }
                            value={ BF.MAP_FLAG_CAN_X_PAN_BOARD }
                            checked={ (mapFlags & BF.MAP_FLAG_CAN_X_PAN_BOARD) ? true : false }
                            onChange={ this.mapFlagsChange } />
                </Form.Field>
              </Form>
              <Form>
                <Form.Field>
                  <Checkbox name='wrapY'
                            label={ <label>Map Wraps Top to Bottom</label> }
                            value={ BF.MAP_FLAG_CAN_Y_PAN_BOARD }
                            checked={ (mapFlags & BF.MAP_FLAG_CAN_Y_PAN_BOARD) ? true : false }
                            onChange={ this.mapFlagsChange } />
                </Form.Field>
              </Form>
            </Segment>

            <Segment key='cancelnext' compact style={{ float:'left' }} >
              &nbsp;&nbsp;&nbsp;
              <Button onClick={ this.cancelClose } negative >
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={ this.createNewMapClose } positive >
                Next&nbsp;&nbsp;&nbsp;&gt;
              </Button>
            </Segment>

          </div>
        </Modal.Description>
      </Modal>
    );
  }
}
