/*
  BaseMembershipOptions.js
  (c) Human Cube Inc.
*/

import BaseCamoBackScroll from './BaseCamoBackScroll';
import BaseMenuBasic from './BaseMenuBasic';
import bfH from '../../bfcore/bf_helper.js';

import visa from './img/payment/visa.gif';
import mastercard from './img/payment/mastercard.gif';
import amex from './img/payment/amex.gif';
import discover from './img/payment/discover.gif';
import paypal from './img/payment/paypal.gif';


export default function BaseMembershipOptions ({ store }) {

  const { uid } = store.userSession;
  const { serverSecondsOffset } = store.server;
  const { membershipLevel, membershipExpire } = store.userSession.bf;

  const membershipString = bfH.membershipLevelAsString(membershipLevel);

  let daysExpire = 0;
  if(membershipExpire) {
    let timeLimit = new Date(membershipExpire);
    if( timeLimit.getTime() ) {
      const now = new Date();
      daysExpire = Math.floor(
        (timeLimit.getTime() - now.getTime() + (serverSecondsOffset * 1000)) / 1000
      );
    }
  }

  const membershipBrief1 = <p>Your current membership is <b>{ membershipString }</b>.</p>;

  let membershipBrief2 = null;
  if(membershipString === 'Basic') {
    membershipBrief2 = <p><b>Upgrade for more features, action and fun!</b></p>;
  }
  else {
    membershipBrief2 += '<p>Your membership expires ';
    if( daysExpire < 1 ) {
      membershipBrief2 = <p>Your membership expires <b>NOW!</b></p>;
    }
    else if( daysExpire === 1 ) {
      membershipBrief2 = <p>Your membership expires in <b>1 DAY!</b></p>;
    }
    else {
      membershipBrief2 = <p>Your membership expires in <b>{ daysExpire } days</b>.</p>;
    }
  }

  let basicContent = null;
  if(membershipLevel <= 1) {
    basicContent = <div>Free</div>;
  }

  let premiumContent = null;
  if(membershipLevel <= 2) {
    premiumContent =
      <div>
        &nbsp;&nbsp;Only $19.95 per Year&nbsp;&nbsp;<br />
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
          <input type="hidden" name="cmd" value="_xclick" />
          <input type="hidden" name="business" value="paypal@humancube.com" />
          <input type="hidden" name="item_name" value="One Year Premium Membership" />
          <input type="hidden" name="item_number" value={ '100002-' + uid } />
          <input type="hidden" name="amount" value="19.95" />
          <input type="hidden" name="currency_code" value="USD" />
          <input type="hidden" name="image_url" value="https://battleforces.com/img/paypal/bf_paypal_header_750x90.jpg" />
          <input type="hidden" name="notify_url" value="https://bf3.battleforces.com/ppipn" />
          <input type="hidden" name="cpp_header_image" value="https://battleforces.com/img/paypal/bf_paypal_header_750x90.jpg" />
          <input type="hidden" name="no_note" value="1" />
          <input type="hidden" name="no_shipping" value="1" />
          <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif" border="0" name="submit" alt="Purchase Premium Membership Now!" />
          <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
      </div>
  }

  const extremeContent =
    <div>
      &nbsp;&nbsp;Only $29.95 per Year&nbsp;&nbsp;<br />
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
        <input type="hidden" name="cmd" value="_xclick" />
        <input type="hidden" name="business" value="paypal@humancube.com" />
        <input type="hidden" name="item_name" value="One Year Extreme Membership" />
        <input type="hidden" name="item_number" value={ '200002-' + uid } />
        <input type="hidden" name="amount" value="29.95" />
        <input type="hidden" name="currency_code" value="USD" />
        <input type="hidden" name="image_url" value="https://battleforces.com/img/paypal/bf_paypal_header_750x90.jpg" />
        <input type="hidden" name="notify_url" value="https://bf3.battleforces.com/ppipn" />
        <input type="hidden" name="cpp_header_image" value="https://battleforces.com/img/paypal/bf_paypal_header_750x90.jpg" />
        <input type="hidden" name="no_note" value="1" />
        <input type="hidden" name="no_shipping" value="1" />
        <input type="image"
          src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif" border="0"
          name="submit" alt="Purchase Extreme Membership Now!" />
        <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
      </form>
    </div>;

  let extremeContent2 = null;
  if(membershipLevel === 2) {
    extremeContent2 =
      <div>
        &nbsp;&nbsp;or upgrade your { daysExpire } remaining&nbsp;&nbsp;<br />&nbsp;&nbsp;Premium days to Extreme for $10&nbsp;&nbsp;<br />
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
          <input type="hidden" name="cmd" value="_xclick" />
          <input type="hidden" name="business" value="paypal@humancube.com" />
          <input type="hidden" name="item_name" value="Premium to Extreme Upgrade" />
          <input type="hidden" name="item_number" value={ '300002-' + uid } />
          <input type="hidden" name="amount" value="10.00" />
          <input type="hidden" name="currency_code" value="USD" />
          <input type="hidden" name="image_url" value="https://battleforces.com/img/paypal/bf_paypal_header_750x90.jpg" />
          <input type="hidden" name="notify_url" value="https://bf3.battleforces.com/ppipn" />
          <input type="hidden" name="cpp_header_image" value="https://battleforces.com/img/paypal/bf_paypal_header_750x90.jpg" />
          <input type="hidden" name="no_note" value="1" />
          <input type="hidden" name="no_shipping" value="1" />
          <input type="image"
            src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif" border="0"
            name="submit" alt="Purchase Membership Upgrade Now!" />
          <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
      </div>;
  }

  return (
    <BaseCamoBackScroll>
      <BaseMenuBasic pageName='BATTLE FORCES Membership' />

      <center>
        <br /><br /><br />
        { membershipBrief1 }
        { membershipBrief2 }

        <table border="2">
          <thead>
            <tr bgcolor="#E1E4EF">
              <th>Available Features</th>
              <th align="center"><center>Basic</center></th>
              <th align="center"><center>Premium</center></th>
              <th align="center"><center>Extreme</center></th>
            </tr>
          </thead>
          <tbody>
            <tr bgcolor="#EEE">
              <td>&nbsp;&nbsp;Maximum Simultaineous Games&nbsp;&nbsp;</td>
              <td align="center">3</td>
              <td align="center">10</td>
              <td align="center">30</td>
            </tr>
            <tr bgcolor="#EEE">
              <td>&nbsp;&nbsp;Play Solo Games&nbsp;&nbsp;</td>
              <td align="center">YES</td>
              <td align="center">YES</td>
              <td align="center">YES</td>
            </tr>
            <tr bgcolor="#EEE">
              <td>&nbsp;&nbsp;Join Multiplayer Games&nbsp;&nbsp;</td>
              <td align="center">YES</td>
              <td align="center">YES</td>
              <td align="center">YES</td>
            </tr>
            <tr bgcolor="#EEE">
              <td>&nbsp;&nbsp;Create Multiplayer Games&nbsp;&nbsp;</td>
              <td align="center">no</td>
              <td align="center">YES</td>
              <td align="center">YES</td>
            </tr>
            <tr bgcolor="#EEE">
              <td>&nbsp;&nbsp;Create Maps and Scenarios&nbsp;&nbsp;</td>
              <td align="center">no</td>
              <td align="center">YES</td>
              <td align="center">YES</td>
            </tr>
            <tr bgcolor="#EEE">
              <td>&nbsp;&nbsp;Play Premium Only Games&nbsp;&nbsp;</td>
              <td align="center">no</td>
              <td align="center">YES</td>
              <td align="center">YES</td>
            </tr>
            <tr bgcolor="#EEE">
              <td>&nbsp;&nbsp;Play Extreme Only Games&nbsp;&nbsp;</td>
              <td align="center">no</td>
              <td align="center">no</td>
              <td align="center">YES</td>
            </tr>
            <tr bgcolor="#EEE">
              <td>&nbsp;&nbsp;Rank Progression&nbsp;&nbsp;</td>
              <td align="center">&nbsp;&nbsp;Enlisted Man&nbsp;&nbsp;</td>
              <td align="center">&nbsp;&nbsp;Enlisted Man&nbsp;&nbsp;</td>
              <td align="center">&nbsp;&nbsp;Commissioned Officer&nbsp;&nbsp;</td>
            </tr>
            <tr bgcolor="#EEE">
              <td>&nbsp;&nbsp;Membership Fee (in US dollars)&nbsp;&nbsp;</td>
              <td align="center">{ basicContent }</td>
              <td align="center">{ premiumContent }</td>
              <td align="center">{ extremeContent }{ extremeContent2 }</td>
            </tr>
          </tbody>
        </table>
        <br />
        <table>
          <tbody>
            <tr>
              <td>
                <div className='flex flex-row'>
                  Supported Payment Options:&nbsp;&nbsp;&nbsp;
                  <img border='0' src={ visa } alt='Visa' />
                  <img border='0' src={ mastercard } alt='MasterCard' />
                  <img border='0' src={ amex } alt='American Express' />
                  <img border='0' src={ discover } alt='Discover' />
                  <img border='0' src={ paypal } alt='PayPal' />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          <b>LIMITED TIME SPECIAL<br />
            To reward early adopters and to compensate for any hickups during the beta period,<br />
            all 1 year membership purchases will automatically include a bonus year.<br />
            That is 2 years for the price of 1. Fantastic!!! Grab it while you can!
          </b>
        </p>
        <br /><br /><br />
        <br /><br /><br />
      </center>
    </BaseCamoBackScroll>
  );
}

// TODO: later can make this supported link to the rank page:
// <td>&nbsp;&nbsp;<a href="#" onclick="changePage('ranks');">Rank Progression</a>&nbsp;&nbsp;</td>
