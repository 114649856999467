/*
  GuiGame.js
  (c) Human Cube Inc.
*/

import cloneDeep from 'clone-deep';
import React from 'react';
import { store } from '../../app/store';
import actions from '../../reducers/actions';
import mainModes from '../../reducers/main-modes';
import { Icon, Label, Message, Menu, Button } from 'semantic-ui-react';
import { zoneIDFromXY } from '../../map/map-generate'
import GuiCountdown from './GuiCountdown';
import GuiChat from './GuiChat';
import GuiForceTypes from './GuiForceTypes';
import GuiModal from './GuiModal';
import GuiPlayers from './GuiPlayers';
import GuiPurchasesSummary from './GuiPurchasesSummary';
import GuiScenarioEditPlayers from './GuiScenarioEditPlayers';
import GuiScenarioEditSave from './GuiScenarioEditSave';
import GuiScenarioEditStats from './GuiScenarioEditStats';
import GuiScenarioEditZone from './GuiScenarioEditZone';
import GuiVictory from './GuiVictory';
import GuiZone from './GuiZone';
import { sendPacket } from '../../network/network';
import { goBack } from '../../reducers/commands';
import BF from '../../bfcore/bfconst1';
import bfH from '../../bfcore/bf_helper.js';
import bfDefaultTurn from '../../bfcore/bf_defaultturn';
import bfTurn from '../../bfcore/bf_turn';
import { generateTurnFromWorking, didMovement, didPurchase } from '../../helper/turn';
import { isBetaTester } from '../../helper/user';
import { log } from '../../helper/log';

import { aiMyTurn } from '../../ai';


const MAP_RENDER_WIDTH = 1024;
const MAP_RENDER_HEIGHT = 1024;

function screenToMapCoord(camera, screenX, screenY, mapXScale, mapYScale) {
   return {
     x: ((screenX - MAP_RENDER_WIDTH / 2) + camera.x) /
        (camera.zoom * mapXScale) + MAP_RENDER_WIDTH / 2,
     y: ((screenY - MAP_RENDER_HEIGHT / 2) + camera.y) /
        (camera.zoom * mapYScale) + MAP_RENDER_HEIGHT / 2,
  };
}

const infoWidgetStyle = {
  float: 'right',
  opacity: 0.9,
  marginTop: 0,
};

function renderTurnRequiredNames (game, required, submitted) {
  // returns an array of JSX objects for representing each player who's turn is still needed
  // rendered one per line.
  const requiredNames = [];

  for(let p = 1; p <= game.players; p++) {
    if( (required & (1<<p)) && !(submitted & (1<<p)) ) {
      requiredNames.push(game.playerName[p]);
    }
  }

  const renderedNames = [];
  if(requiredNames.length === 1) {
    renderedNames.push(<div><b>{ requiredNames[0] }</b><br /></div>);
  }
  else {
    for(let r = 0; r < requiredNames.length; r++) {
      if(r === requiredNames.length - 1) {
        renderedNames.push(
          <div>
            and <b>{ requiredNames[r] }</b>
            <br />
          </div>
        );
      }
      else {
        renderedNames.push(
          <div>
            <b>{ requiredNames[r] }</b>
            { (r < requiredNames.length - 2) ? ',' : '' }
            <br />
          </div>
        );
      }
    }
  }
  return renderedNames;
}

export default class GuiGame extends React.Component {
  isMouseDown = false;
  mouseDownX = 0;
  mouseDownY = 0;
  mouseLastX = 0;
  mouseLastY = 0;
  mouseMoved = false;

  state = {
    backModalMessage: '',
    submitModalMessage: '',
    showForceTypes: false,
    showPlayers: false,
    showPurchases: false,
    showScenarioEditSave: false,
    showScenarioEditStats: false,

    camera: {},
    game: {},

    store: {},
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.

    this.state.camera = props.store.camera;
    this.state.game = props.store.game;

    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { backModalMessage, submitModalMessage, showScenarioEditSave, showScenarioEditStats,
          showForceTypes, showPlayers, showPurchases } = this.state;
    if( nextProps.store.game._id !== this.state.game._id ||
        nextProps.store.mainMode !== this.state.store.mainMode ) {
      backModalMessage = '';
      submitModalMessage = '';
      showForceTypes= false;
      showPlayers = false;
      showPurchases = false;
      showScenarioEditSave = false;
      showScenarioEditStats = false;
    }
    if(nextProps.store.camera !== this.state.camera ||
        nextProps.store.game !== this.state.game ||
        nextProps.store !== this.state.store) {
      this.setState({
        camera: nextProps.store.camera,
        game: nextProps.store.game,
        store: nextProps.store,
        backModalMessage,
        submitModalMessage,
        showForceTypes,
        showPlayers,
        showPurchases,
        showScenarioEditSave,
        showScenarioEditStats,
      });
    }
  }

  handleZoneTap = (zoneID) => {
    // A zone, or no zone has been tapped.
    if(zoneID < 0) {
      zoneID = 0;
    }

    if(this.state.store.mainMode === mainModes.SCENARIO_PREVIEW ||
        this.state.store.mainMode === mainModes.SCENARIO_EDIT ) {
      if(zoneID &&
          (this.state.store.selectedZoneIDs.length !== 1 ||
          this.state.store.selectedZoneIDs[0] !== zoneID)) {
        store.dispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: [zoneID]});
      }
      else {
        store.dispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: []});
      }
      return;
    }

    if(this.state.store.showLandingSpecifyWindow.show) {
      // In aircraft landing mode, use the selection to choose a potential landing location.
      if(this.state.store.showLandingSpecifyWindow.destination !== zoneID) {
        store.dispatch({type: actions.SET_LANDING_SPECIFY_ZONE, zoneID});
      }
      return;
    }

    if(zoneID <= 0) {
      store.dispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: []});
      return;
    }

    if(this.state.store.mainMode === mainModes.GAME_REPLAY) {
      if(this.state.store.selectedZoneIDs.length === 1 &&
          this.state.store.selectedZoneIDs[0] === zoneID) {
        store.dispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: []});
      }
      else {
        store.dispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: [zoneID]});
      }
    }
    else if(this.state.game.turn === BF.TURN_PICK_CAPITAL ||
        this.state.store.selectedZoneIDs.length === 0 ||
        this.state.store.selectedZoneIDs.length === 2) {
      store.dispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: [zoneID]});
    }
    else if(this.state.store.selectedZoneIDs[0] === zoneID) {
      store.dispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: []});
    }
    else if(bfH.isNeighbour(this.state.store.game, this.state.store.selectedZoneIDs[0],
        zoneID)) {
      store.dispatch({
        type: actions.SET_SELECTED_ZONEIDS,
        selectedZoneIDs: [this.state.store.selectedZoneIDs[0], zoneID],
      });
    }
    else {
      store.dispatch({type: actions.SET_SELECTED_ZONEIDS, selectedZoneIDs: [zoneID]});
    }
  }

  doActualSubmitTurn (game, working, localPlayerIndex) {
    store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.GAME_SUBMITTING_TURN});

    if(game.flags & BF.GAME_FLAG_CLIENT_CALCULATED) {
      // Game advancement is calculated on the client.

      const turns = [];
      for(let p = 1; p <= game.players; p++) {
          if(game.required & (1<<p)) {
              if(game.playerFlags[p] & BF.PLAYER_FLAG_COMPUTER) {
                  turns[p] = bfDefaultTurn.turn(game, p);
              }
              else {
                  turns[p] = generateTurnFromWorking(game.zones, game.forceID.length, working, p);
                  log('The turns for this user:');
                  log(turns[p]);
              }
          }
      }

      const nextGame = cloneDeep(game);
      bfTurn.execute(nextGame, turns);
      store.dispatch({type: actions.SET_GAME, game: nextGame, localPlayerIndex});
      store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.GAME_REPLAY});
    }
    else {
      // Game advancement is calculated on the server.
      const playersTurn =
        generateTurnFromWorking(game.zones, game.forceID.length, working, localPlayerIndex);

      const { playFor } = this.state.store;
      if(playFor.uid) {  // Playing for a teammate.
        sendPacket(
          'bf_submitTurn',
          {gameID: game._id, turn: game.turn, data: playersTurn, playForUID: playFor.uid}
        );
      }
      else {
        sendPacket(
          'bf_submitTurn',
          {gameID: game._id, turn: game.turn, data: playersTurn}
        );
      }
    }
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  clickBack = (e) => {
    e.stopPropagation();
    if(this.state.store.mainMode === mainModes.GAME_DOING_TURN) {
      this.setState({ backModalMessage:
        'You have not submitted your turn. Tap [SUBMIT TURN] before exiting to send your turn to the server.'
      });
      return;
    }

    if(this.state.store.mainMode === mainModes.SCENARIO_EDIT &&
        !this.state.store.scenarioEditSaved) {
      this.setState({ backModalMessage:
        'You have not saved your changes to the scenario. Tap [SAVE SCENARIO] before exiting to save your changes.'
      });
      return;
    }

    goBack();
  };

  clickBackModalCancel = (e) => {
    this.setState({ backModalMessage: '' });
  }

  clickBackModalOK = (e) => {
    goBack();
  }

  clickStartTurn = (e) => {
    e.stopPropagation();
    store.dispatch({type: actions.SET_MAIN_MODE, mode: mainModes.GAME_DOING_TURN});
  };

  clickSubmitTurnModalCancel = (e) => {
    this.setState({ submitModalMessage: '' });
  }

  clickSubmitTurnModalOK = (e) => {
    this.setState({ submitModalMessage: '' });
    const game = this.state.game;
    const working = this.state.store.working;
    const localPlayerIndex = working.localPlayerIndex;
    this.doActualSubmitTurn(game, working, localPlayerIndex);
  }

  clickSubmitTurn = (e) => {
    e.stopPropagation();

    const game = this.state.game;
    const working = this.state.store.working;
    const localPlayerIndex = working.localPlayerIndex;

    if(!didMovement(game.zones, game.forceID.length, working, localPlayerIndex)) {
      this.setState({ submitModalMessage: 'You did not move any of your forces.' });
      return;
    }
    if(!didPurchase(game.zones, game.forceID.length, working, localPlayerIndex)) {
      this.setState({ submitModalMessage: 'You did not purchase any new forces.' });
      return;
    }

    this.doActualSubmitTurn(game, working, localPlayerIndex);
  };

  toggleMusic = (e) => {
    e.stopPropagation();
    let on = true;
    if(this.state.store.musicOn) {
      on = false;
    }
    if(on) {
      localStorage.setItem('musicOn', '1');
    }
    else {
      localStorage.setItem('musicOn', '0');
    }
    store.dispatch({type: actions.SET_MUSIC_ON_OFF, on});
  };

  toggleSound = (e) => {
    e.stopPropagation();
    let on = true;
    if(this.state.store.soundOn) {
      on = false;
    }
    if(on) {
      localStorage.setItem('soundOn', '1');
    }
    else {
      localStorage.setItem('soundOn', '0');
    }
    store.dispatch({type: actions.SET_SOUND_ON_OFF, on});
  };

  closeScenarioEditSave = () => {
    this.setState({showScenarioEditSave: false});
  };

  closeForceTypes = () => {
    this.setState({showForceTypes: false});
  };

  closePlayers = () => {
    this.setState({showPlayers: false});
  };

  closePurchases = () => {
    this.setState({showPurchases: false});
  };

  toggleForceTypes = (e) => {
    e.stopPropagation();
    if(this.state.showForceTypes) {
      this.setState({showForceTypes: false});
      return;
    }
    this.setState({
      showForceTypes: true,
      showPlayers: false,
      showPurchases: false,
      showScenarioEditStats: false,
    });
  };

  togglePlayers = (e) => {
    e.stopPropagation();
    if(this.state.showPlayers) {
      this.setState({showPlayers: false});
      return;
    }
    this.setState({
      showForceTypes: false,
      showPlayers: true,
      showPurchases: false,
      showScenarioEditStats: false,
    });
  };

  togglePurchases = (e) => {
    e.stopPropagation();
    if(this.state.showPurchases) {
      this.setState({showPurchases: false});
      return;
    }
    this.setState({
      showForceTypes: false,
      showPlayers: false,
      showPurchases: true,
      showScenarioEditStats: false,
    });
  };

  closeScenarioEditStats = (e) => {
    this.setState({showScenarioEditStats: false});
  };

  toggleScenarioEditStats = (e) => {
    e.stopPropagation();
    if(this.state.showScenarioEditStats) {
      this.setState({showScenarioEditStats: false});
      return;
    }
    this.setState({
      showForceTypes: false,
      showPlayers: false,
      showPurchases: false,
      showScenarioEditStats: true,
    });
  };

  clickReplayPlay = (e) => {
    e.stopPropagation();
    store.dispatch({type: actions.SET_REPLAY_PLAY, play: true});
  };

  clickReplayPause = (e) => {
    e.stopPropagation();
    store.dispatch({type: actions.SET_REPLAY_PLAY, play: false});
  };

  clickReplayStage = stage => e => {
    e.stopPropagation();
    store.dispatch({type: actions.SET_REPLAY_STAGE, stage: stage});
  };

  scenarioSave = (e) => {
    e.stopPropagation();
    this.setState({showScenarioEditSave: true});
  }

  onMouseDown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if(!this.state.submitModalMessage) {
      this.isMouseDown = true;
      this.mouseLastX = e.clientX;
      this.mouseLastY = e.clientY;
      this.mouseDownX = e.clientX;
      this.mouseDownY = e.clientY;
      this.mouseMoved = false;
    }
    else {
      this.isMouseDown = false;
    }
  };

  onMouseMove = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if(!this.state.submitModalMessage && this.isMouseDown) {
      let dx = e.clientX - this.mouseLastX;
      let dy = e.clientY - this.mouseLastY;
      if(this.mouseMoved || Math.abs(dx) >= 5 || Math.abs(dy) >= 5) {
        store.dispatch({
          type: actions.MAP_PAN,
          dx,
          dy,
        });
        this.mouseLastX = e.clientX;
        this.mouseLastY = e.clientY;
        this.mouseMoved = true;
      }
    }
    else {
      this.isMouseDown = false;
    }
  };

  onMouseUp = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if(!this.state.submitModalMessage && this.isMouseDown && !this.mouseMoved) {
      if(this.state.store.mainMode === mainModes.GAME_REPLAY ||
          this.state.store.mainMode === mainModes.GAME_DOING_TURN ||
          this.state.store.mainMode === mainModes.SCENARIO_PREVIEW ||
          this.state.store.mainMode === mainModes.SCENARIO_EDIT ) {
        let mouseX = e.clientX;
        let mouseY = e.clientY;

        let {mapFlags, mapXS, mapYS, mapXScale, mapYScale} = this.state.game;
        if(!mapXScale) {
          mapXScale = 1;
        }
        if(!mapYScale) {
          mapYScale = 1;
        }

        if(Math.abs(this.mouseDownX - mouseX) < 5 || Math.abs(this.mouseDownY - mouseY) < 5) {
          if(mapFlags & BF.MAP_FLAG_HEXMAP) {
            mapXS = 512;
            mapYS = 512;
          }

          let {x, y} = screenToMapCoord(this.state.camera, mouseX, mouseY, mapXScale, mapYScale);
          let zoneID = zoneIDFromXY(
            this.state.game,
            x / MAP_RENDER_WIDTH * mapXS,
            y / MAP_RENDER_HEIGHT * mapYS,
          );
          this.handleZoneTap(zoneID);
        }
      }
    }
    this.isMouseDown = false;
  };

  zoomIn = (e) => {
    e.stopPropagation();
    store.dispatch({type: actions.MAP_ZOOM, delta: 5});
  }

  zoomOut = (e) => {
    e.stopPropagation();
    store.dispatch({type: actions.MAP_ZOOM, delta: -5});
  }

  render() {
    const { gameID, game, gameStatus, mainMode, musicOn, playFor, replayPlay,
            replayStage, scenarioEditSaved, selectedZoneIDs, server, soundOn, working,
          } = this.state.store;
    const serverTimeOffsetMS = server.serverSecondsOffset;

    const localPlayerIndex = working.localPlayerIndex;
    const inGame = localPlayerIndex ? true : false;

    let modeText = null;
    let infoWidget = null;
    let aiButton = null;
    let replayStuff = [];
    let soloGame = true;
    let showVictory = false;

    if(game._id === gameID && gameStatus[gameID]) {
      const { /* name, players, playerID, turn, */ state, timeLimit, required, submitted, year } =
         gameStatus[gameID];

      if(game.humanPlayers > 1) {
        soloGame = false;
      }

      if(mainMode === mainModes.GAME_REPLAY) {
        if(game.turn > BF.TURN_NORMAL) {
          if(replayPlay) {
            replayStuff.push(
              <Button key={'butt-1'} icon labelPosition='right' onPointerUp={this.clickReplayPause}>
                <Icon name='pause' />
                R e p l a y
              </Button>
            );
          }
          else {
            replayStuff.push(
              <Button key={'butt-1'} icon labelPosition='right' onPointerUp={this.clickReplayPlay}>
                <Icon name='play' />
                R e p l a y
              </Button>
            );
          }

          for(let i = 0; i < working.replayStage.length; i++) {
            const r = working.replayStage[i];
            let stageName = BF.REPLAY_STAGE_STRING[r];
            if(r === 0) {
              stageName = 'Commence';
            }
            if(r === replayStage) {
              replayStuff.push(
                <Button key={ 'butt' + r } inverted onPointerUp={ this.clickReplayStage(r) } >
                  { stageName.toUpperCase() }
                </Button>
              );
            }
            else {
              replayStuff.push(
                <Button key={ 'butt' + r } onPointerUp={ this.clickReplayStage(r) } >
                  { stageName }
                </Button>
              );
            }
          }

          if(replayStage === BF.MAX_REPLAY_STAGES - 1 && state === BF.GAME_STATE_FINISHED) {
            showVictory = true;
          }
        }

        if(state === BF.GAME_STATE_STARTED) {
          if(inGame && (required & (1<<localPlayerIndex)) &&
              (submitted & (1<<localPlayerIndex)) === 0) {
            if(game.turn === BF.TURN_PICK_CAPITAL) {
              infoWidget =
                <Message compact info floating style={ infoWidgetStyle } onPointerDown={ this.stopPropagation }>
                  It is your turn<br />
                  to select a zone<br />
                  to be your Capital.<br />
                  <br />
                  <Button onPointerUp={ this.clickStartTurn }>
                    Start Turn
                  </Button>
                </Message>;
            }
            else if(game.turn >= BF.TURN_NORMAL) {
              infoWidget =
                <Message compact info floating style={ infoWidgetStyle } onPointerDown={ this.stopPropagation }>
                  <b>It is your turn.</b><br />
                  <br />
                  <Button onPointerUp={ this.clickStartTurn }>
                    Start Turn
                  </Button><br />
                  <br />
                  After you have<br />
                  completed your<br />
                  turn, tap the<br />
                  <b>SUBMIT TURN</b><br />
                  command.
                </Message>;
            }
          }
          else if(required && required !== submitted) {
            const renderedNames = renderTurnRequiredNames(game, required, submitted);
            infoWidget =
              <Message compact info floating style={ infoWidgetStyle } onPointerDown={ this.stopPropagation }>
                Waiting for<br />
                { renderedNames }
                to complete<br />
                their turn{ renderedNames.length > 1 ? 's' : '' }.<br />
                <br />
                Previous turn<br />
                replay shown.
              </Message>;
          }
          else {
            infoWidget =
              <Message compact info floating style={ infoWidgetStyle } onPointerDown={ this.stopPropagation }>
                Server is calculating<br />
                turn. Please wait.<br />
                <br />
                Previous turn<br />
                replay shown.
              </Message>;
          }
        }

      }
      else if(mainMode === mainModes.GAME_DOING_TURN) {
        if(game && game.turn === BF.TURN_PICK_CAPITAL) {
          infoWidget =
            <Message compact info floating style={ infoWidgetStyle }>
              Tap a zone you<br />
              own to select as<br />
              your capital.
            </Message>;
        }
      }

      else if(mainMode === mainModes.GAME_SUBMITTING_TURN) {
        if(inGame && (required & (1<<localPlayerIndex)) &&
            (submitted & (1<<localPlayerIndex)) === 0) {
          infoWidget =
            <Message compact info floating style={ infoWidgetStyle } onPointerDown={ this.stopPropagation }>
              Submitting Turn To Server...
            </Message>;
        }
        else if(required && required !== submitted) {
          const renderedNames = renderTurnRequiredNames(game, required, submitted);
          infoWidget =
            <Message compact info floating style={ infoWidgetStyle } onPointerDown={ this.stopPropagation }>
              Waiting for<br />
              { renderedNames }
              to complete<br />
              their turn{ renderedNames.length > 1 ? 's' : '' }.<br />
            </Message>;
        }
        else {
          infoWidget =
            <Message compact info floating style={ infoWidgetStyle } onPointerDown={ this.stopPropagation }>
              Server is calculating turn.
            </Message>;
        }
      }

      if(mainMode === mainModes.SCENARIO_PREVIEW) {
        modeText = <Menu.Item>PREVIEW MODE</Menu.Item>;
      }
      else if(mainMode === mainModes.SCENARIO_EDIT) {
        modeText = <Menu.Item>EDIT MODE</Menu.Item>;
      }
      else {
        let countDown = null;
        if(state === BF.GAME_STATE_STARTED) {
          countDown = <GuiCountdown date={ timeLimit } offsetMS={ serverTimeOffsetMS } />;
        }
        modeText = <Menu.Item>
                     { bfH.turnIndexAsString(game.turn, year) }
                     { countDown }
                   </Menu.Item>;
      }

    }

    let modeCommand = null;
    if(mainMode === mainModes.GAME_DOING_TURN && game.turn >= BF.TURN_NORMAL) {
      modeCommand = <Menu.Item onPointerUp={ this.clickSubmitTurn }>
                      <Label color ='green'>SUBMIT TURN</Label>
                    </Menu.Item>;
      if(isBetaTester()) {
        if (working.ai.moveStage >= 0 && working.ai.moveStage < BF.FORCE_MAX_MOVES) {
          aiButton =
            <div style={{ position: 'absolute', bottom: 3, right: 0 }}>
              <Button
                onPointerDown={this.stopPropagation}
                onPointerUp={(e) => {
                  e.stopPropagation();
                  aiMyTurn(store.dispatch, working, game);
                }
                }>
                <small>
                  Use AI for Your<br />
                  Move Phase {working.ai.moveStage + 1}
                </small>
              </Button>
            </div>;
        }
        else {
          aiButton =
            <div
              className='bg-slate-300 py-1 px-3'
              style={{ position: 'absolute', bottom: 3, right: 3 }}
            >
              <small>
                Tap SUBMIT TURN to End Your Turn
              </small>
            </div>;
        }
      }
    }
    else if(mainMode === mainModes.SCENARIO_EDIT) {
      modeCommand = <Menu.Item onPointerUp={ this.scenarioSave }>
                      <Label color ='green'>SAVE SCENARIO{ scenarioEditSaved ? '' : '*' }</Label>
                    </Menu.Item>;
    }
    else {
      modeCommand = <Menu.Item />;
    }

    let productionCommand = <Menu.Item />;
    if(mainMode === mainModes.GAME_DOING_TURN) {
      productionCommand = <Menu.Item onPointerUp={ this.togglePurchases }>
                            <Icon.Group>
                              <Icon name='factory' />
                            </Icon.Group>
                          </Menu.Item>;
    } else if(mainMode === mainModes.SCENARIO_EDIT) {
      productionCommand = <Menu.Item onPointerUp={ this.toggleScenarioEditStats }>
                            STATS
                          </Menu.Item>;
    }

    let zoneComponent = null;
    if(mainMode !== mainModes.SCENARIO_EDIT) {
      zoneComponent = <GuiZone store={ this.state.store } />;
    }
    else if(selectedZoneIDs[0]) {
      zoneComponent = <GuiScenarioEditZone game={ this.state.store.game }
                                           scenarioEdit={ this.state.store.scenarioEdit }
                                           zoneID={ selectedZoneIDs[0] } />
    }

    let playersComponent = null;
    if(this.state.showPlayers) {
      if(mainMode !== mainModes.SCENARIO_EDIT) {
        playersComponent = <GuiPlayers store={ this.state.store }
                                       close={ this.closePlayers } />;
      }
      else {
        playersComponent = <GuiScenarioEditPlayers store={ this.state.store } />;
      }
    }

    let soundMenuItem = <Menu.Item name='sound'>&nbsp;</Menu.Item>;
    if(mainMode !== mainModes.SCENARIO_EDIT && mainMode !== mainModes.SCENARIO_PREVIEW) {
      soundMenuItem = <Menu.Item name='sound'>
                        { soundOn ?
                            <Icon.Group onPointerUp={ this.toggleSound } >
                              <Icon name='volume up' />
                            </Icon.Group>
                          :
                            <Icon.Group onPointerUp={ this.toggleSound } >
                              <Icon name='volume off' />
                            </Icon.Group>
                        }
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        { musicOn ?
                            <Icon.Group onPointerUp={ this.toggleMusic } >
                              <Icon name='music' />
                            </Icon.Group>
                          :
                            <Icon.Group onPointerUp={ this.toggleMusic } >
                              <Icon name='music' />
                              <Icon color='red' name='dont' />
                            </Icon.Group>
                        }
                      </Menu.Item>;
    }

    return (
      <div style={{height: '100%', width: '100%', border: 0, padding: 0}}
           onPointerDown={ this.onMouseDown }
           onPointerMove={ this.onMouseMove }
           onPointerUp={ this.onMouseUp }
        >
        {/* <GuiMusic on={ musicOn }
                  mainMode={ mainMode }
                  turnIndex={ game.turn }
                  volume={ musicVolume }
        /> */}

        <Menu inverted fluid widths={ 8 }
              style={{opacity: 0.75}}
              onPointerDown={ this.stopPropagation } >
          <Menu.Item name='BACK' onPointerUp={ this.clickBack } />
          { soundMenuItem }

          <Menu.Item name='zoom'>
            <Icon.Group onPointerUp={ this.zoomOut }>
              <Icon name='zoom out' />
            </Icon.Group>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Icon.Group onPointerUp={ this.zoomIn }>
              <Icon name='zoom in' />
            </Icon.Group>
          </Menu.Item>;

          <Menu.Item onPointerUp={ this.toggleForceTypes }>
            <Icon.Group>
              <Icon name='list alternate' />
            </Icon.Group>
          </Menu.Item>

          <Menu.Item onPointerUp={ this.togglePlayers } >
            <Icon.Group>
              <Icon name='user' />
            </Icon.Group>
          </Menu.Item>

          { productionCommand }
          { modeCommand }
          { modeText }
        </Menu>

        { replayStuff.length > 0 &&
          <Button.Group vertical compact color='black' style={{float: 'right', opacity: 0.666}}
              onPointerDown={ this.stopPropagation }>
            { replayStuff }
          </Button.Group>
        }
        { showVictory && <GuiVictory game={ game } /> }
        { infoWidget }
        { zoneComponent }
        { playersComponent }

        { aiButton }

        { playFor.uid
           ? <Message style={{ position: 'absolute', bottom: 0, left: 0 }} color='red'>
               You are Playing<br />for { playFor.uName }
             </Message>
           : (!soloGame && <GuiChat store={ this.state.store } />)
        }

        { this.state.showPurchases &&
            <GuiPurchasesSummary store={ this.state.store } close={ this.closePurchases } />
        }

        { this.state.showForceTypes &&
            <GuiForceTypes store={ this.state.store } close={ this.closeForceTypes } />
        }

        { this.state.showScenarioEditStats &&
          <GuiScenarioEditStats store={ this.state.store }
                                closeHandle={ this.closeScenarioEditStats } />
        }

        { this.state.showScenarioEditSave &&
          <GuiScenarioEditSave store={ this.state.store }
                               closeHandle={ this.closeScenarioEditSave } />
        }

        { this.state.backModalMessage &&
          <GuiModal
            title='Exit Warning'
            body={ this.state.backModalMessage }
            cancelCallback={ this.clickBackModalCancel }
            okCallback={ this.clickBackModalOK }
            okText= {mainMode === mainModes.SCENARIO_EDIT ?
                    'Exit Without Saving' : 'Exit Without Submitting Turn' }
          />
        }
        { this.state.submitModalMessage &&
          <GuiModal
            title='Submit Turn Warning'
            body={ this.state.submitModalMessage }
            cancelCallback={ this.clickSubmitTurnModalCancel }
            okCallback={ this.clickSubmitTurnModalOK }
            okText='Submit Turn Anyways'
          />
        }
      </div>
    );
  }
}
