import React from 'react';
import { Button, Dropdown, Input, Modal, Segment } from 'semantic-ui-react';
import actions from '../../reducers/actions';
import { store } from '../../app/store';
import { sendPacket } from '../../network/network';
import { sanitizeScenarioName, sanitizeScenarioDescription } from '../../helper/scenario';


const turnTimeOptions = [
  // { key: 600, text: 'Speed (Max 10 Minutes/Turn)', value: 600 },
  // { key: 1800, text: 'Speed (Max 30 Minutes/Turn)', value: 1800 },
  // { key: 3600, text: 'Fast (Max 1 Hour/Turn)', value: 3600 },
  // { key: 7200, text: 'Fast (Max 2 Hours/Turn)', value: 7200 },
  // { key: 10800, text: 'Fast (Max 3 Hours/Turn)', value: 10800 },
  // { key: 14400, text: 'Fast (Max 4 Hours/Turn)', value: 14400 },
  // { key: 21600, text: 'Fast (Max 6 Hours/Turn)', value: 21600 },
  // { key: 43200, text: 'Fast (Max 12 Hours/Turn)', value: 43200 },
  { key: 90000, text: 'Normal (Max 25 Hours/Turn)', value: 90000 },  // Default
  { key: 172800, text: 'Slow (Max 48 Hours/Turn)', value: 172800 },
  { key: 259200, text: 'Slower (Max 3 Days/Turn)', value: 259200 },
  { key: 604800, text: 'Slowest (Max 1 Week/Turn)', value: 604800 },
];

const maxTurnsOptions = [
  // { key: 6, text: '6', value: 6 },
  { key: 10, text: '10', value: 10 },
  { key: 15, text: '15', value: 15 },
  { key: 20, text: '20', value: 20 },
  { key: 30, text: '30', value: 30 },
  { key: 40, text: '40', value: 40 },
  { key: 50, text: '50', value: 50 },
  { key: 60, text: '60', value: 60 },
  { key: 100, text: '100', value: 100 },
  { key: 200, text: '200', value: 200 },
  { key: 300, text: '300', value: 300 },
  { key: 500, text: '500', value: 500 },
  { key: 1000, text: '1000', value: 1000 },  // Default
  { key: 2000, text: '2000', value: 2000 },
  { key: 3000, text: '3000', value: 3000 },
  { key: 10000, text: '10000', value: 10000 },
];

const maxDurationOptions = [
  // { key: 10800, text: '3 Hours', value: 10800 },
  // { key: 21600, text: '6 Hours', value: 21600 },
  // { key: 28800, text: '8 Hours', value: 28800 },
  // { key: 43200, text: '12 Hours', value: 43200 },
  // { key: 86400, text: '24 Hours', value: 86400 },
  // { key: 172800, text: '48 Hours', value: 172800 },
  { key: 432000, text: '5 Days', value: 432000 },
  { key: 604800, text: '7 Days', value: 604800 },
  { key: 1209600, text: '14 Days', value: 1209600 },
  { key: 2678400, text: '31 Days', value: 2678400 },
  { key: 5356800, text: '62 Days', value: 5356800 },
  { key: 8640000, text: '100 Days', value: 8640000 },  // Default
  { key: 15552000, text: '6 Months', value: 15552000 },
  { key: 31536000, text: '1 Year', value: 31536000 },
  { key: 94608000, text: '3 Years', value: 94608000 },
];

export default class GuiScenarioEditSave extends React.Component {

  state = {
    closeHandle: null,
    store: {},
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.
    this.state.closeHandle = props.closeHandle;
    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.closeHandle !== this.state.closeHandle ||
        nextProps.store !== this.state.store) {
      this.setState({
        closeHandle: nextProps.closeHandle,
        store: nextProps.store,
      });
    }
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  cancelClose = (e) => {
    e.stopPropagation();
    if(this.state.closeHandle) {
      this.state.closeHandle();
    }
  }

  createNewScenarioClose = (e) => {
    e.stopPropagation();
    if(this.state.closeHandle) {
      this.state.closeHandle();
    }
		const { scenarioEdit } = this.state.store;

		// TODO: could also clean out starting navy forces for zones without a navyZone.

		// TODO: might need to ensure there is at least one human player.

		// still need anything from : scenario = bfSanitize.sanitizeScenario(scenario, map, forces, BF);

		sendPacket('bf_scenarioSave', {scenario: scenarioEdit});
  };

  turnTimeChange = (e, { value }) => {
    const { game, scenarioEdit } = this.state.store;
    store.dispatch({
      type: actions.SCENARIO_EDIT_TURN_TIME,
      game,
      scenarioEdit,
      turnTime: value,
    });
  };

  maxTurnsChange = (e, { value }) => {
    const { game, scenarioEdit } = this.state.store;
    store.dispatch({
      type: actions.SCENARIO_EDIT_MAX_TURNS,
      game,
      scenarioEdit,
      maxTurns: value,
    });
  };

  maxDurationChange = (e, { value }) => {
    const { game, scenarioEdit } = this.state.store;
    store.dispatch({
      type: actions.SCENARIO_EDIT_MAX_DURATION,
      game,
      scenarioEdit,
      maxDuration: value,
    });
  };

  nameChange = e => {
    const { game, scenarioEdit } = this.state.store;
    const name = sanitizeScenarioName(e.target.value);
    if(name) {
      store.dispatch({
        type: actions.SCENARIO_EDIT_NAME,
        game,
        scenarioEdit,
        name,
      });
    }
  };

  descriptionChange = e => {
    const { game, scenarioEdit } = this.state.store;
    const description = sanitizeScenarioDescription(e.target.value);
    if(description) {
      store.dispatch({
        type: actions.SCENARIO_EDIT_DESCRIPTION,
        game,
        scenarioEdit,
        description,
      });
    }
  };

  render() {
    const { scenarioEdit } = this.state.store;

    return (
      <Modal
          onPointerDown={ this.stopPropagation }
          closeOnDimmerClick={ false }
          closeOnEscape={ false }
          dimmer='inverted'
          open={ true }
          size='small'>
        <Modal.Header>Save Scenario</Modal.Header>
        <Modal.Description>
          <div>

            <Segment key='turnTime' compact style={{ float:'left' }} >
              <p>
                Turn Time:
              </p>
              <Dropdown
                onChange={ this.turnTimeChange }
                options={ turnTimeOptions }
                placeholder='Choose an option'
                selection
                value={ scenarioEdit.turnTime }
              />
            </Segment>

            <Segment key='maxTurns' compact style={{ float:'left' }} >
              <p>
                Maximum Turns:
              </p>
              <Dropdown
                onChange={ this.maxTurnsChange }
                options={ maxTurnsOptions }
                placeholder='Choose an option'
                selection
                value={ scenarioEdit.maxTurns }
              />
            </Segment>

            <Segment key='maxDuration' compact style={{ float:'left' }} >
              <p>
                Maximum Duration:
              </p>
              <Dropdown
                onChange={ this.maxDurationChange }
                options={ maxDurationOptions }
                placeholder='Choose an option'
                selection
                value={ scenarioEdit.maxDuration }
              />
            </Segment>

            <Segment key='scenarioName' compact style={{ float:'left' }} >
              <span>
                Scenario Name (maximum 47 characters):<br />
                <Input placeholder='Scenario Name...'
                       defaultValue={ scenarioEdit.name ? scenarioEdit.name : ' ' }
                       onChange={ this.nameChange }
                       style={{ width: '666px' }}
                />
              </span>
            </Segment>

            <Segment key='scenarioDescription' compact style={{ float:'left' }} >
              <span>
                Scenario Description (maximum 999 characters):<br />
                <Input placeholder='Scenario Description...'
                       defaultValue={ scenarioEdit.description ? scenarioEdit.description : ' ' }
                       onChange={ this.descriptionChange }
                       style={{ width: '666px' }}
                />
              </span>
            </Segment>

            <Segment key='cancelok' compact style={{ float:'left' }} >
              &nbsp;&nbsp;&nbsp;
              <Button onClick={ this.cancelClose } negative >
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button onClick={ this.createNewScenarioClose } positive >
                &nbsp;&nbsp;Save&nbsp;&nbsp;
              </Button>
            </Segment>

          </div>
        </Modal.Description>
      </Modal>
    );
  }
}
