import React from 'react';
import actions from '../../reducers/actions';
import { Flag } from 'semantic-ui-react';
import { Button, Step } from 'semantic-ui-react';
import { store } from '../../app/store';
import GuiForce from './GuiForce';
import bfH from '../../bfcore/bf_helper.js';

function GuiLandingLine (props) {
  const { count, forceTypeIndex, game, fromZoneID, zoneID } = props;

  // TODO: low, could make the zone name clickable to auto select and view
  // function retreatOne (e) {
  //
  //   store.dispatch({
  //     type: actions.MOVE_SOME_FORCES,
  //     game: game,
  //     forceTypeIndex: forceTypeIndex,
  //     range: 0,
  //     transportCargoKey: transportCargoKey,
  //     count: 1,
  //     playerIndex: localPlayerIndex,
  //     zoneIDFrom: zoneID,
  //     zoneIDTo: retreatZoneID,
  //   });
  //
  // }
  //
  return (
    <div className='flex flex-row'>
      <div className='mt-1'>
        <b>{count}</b>&nbsp;&nbsp;
      </div>
      <GuiForce forceType={game.forceID[forceTypeIndex]} />&nbsp;&nbsp;&nbsp;
      <div className='mt-1'>
        from {fromZoneID === zoneID ? 'here' : game.zoneName[fromZoneID]}
      </div>
    </div>
  );
}

export default function GuiLandingHereWindow (props) {
  const { game, working, zoneID } = props;
  const force = working.force;

  function stopPropagation (e) {
    e.stopPropagation();
  }

  function hideLandingWindow (e) {
    store.dispatch({
      type: actions.HIDE_LANDING_HERE_WINDOW,
    });
  }

  const landingForceCounts = {};  // Indexed by 'forceType|fromZoneID'
  let landingTotal = 0;
  for(let forceIndex in working.landing) {
    if( working.landing[forceIndex] === zoneID ) {
      const forceType = force[forceIndex].ft;
      const fromZoneID = force[forceIndex].z;
      const key = forceType + '|' + fromZoneID;
      if(!landingForceCounts[key]) {
        landingForceCounts[key] = 0;
      }
      landingForceCounts[key]++;
      landingTotal++;
    };
  }


  // TODO low: could sort landingForceCounts here by forceType.

  let landingLine = [];
  for(const key in landingForceCounts) {
    const f = key.split('|');
    landingLine.push(
      <GuiLandingLine key={ 'f' + key }
                           forceTypeIndex={ parseInt(f[0], 10) }
                           fromZoneID={ parseInt(f[1], 10) }
                           count={ landingForceCounts[key] }
                           game={ game }
                           zoneID={ zoneID }
      />
    );
  }

  if(landingLine.length === 0) {
    landingLine = <div style={{marginBottom: '3px'}}>
                    You do not have any aircraft<br />
                    set to land here.
                  </div>;
  }

  let canLandString = null;
  if(working.landingCapacity[zoneID] >= 10000000) {
    canLandString = <div style={{marginBottom: '3px', marginTop: '3px'}}>
                      You can land an unlimited number of<br />
                      aircraft here as long as you continue<br />
                      to control this zone.
                    </div>;
  }
  else if(working.landingCapacity[zoneID] > 0) {
    canLandString = <div style={{marginBottom: '3px', marginTop: '3px'}}>
                      You can land up to { working.landingCapacity[zoneID] } aircraft here.
                    </div>;
  }
  else if(working.carrierCapacity[zoneID] > 0) {
    canLandString = <div style={{marginBottom: '3px', marginTop: '3px'}}>
                      You can land up to { working.carrierCapacity[zoneID] } carrier <br />
                      compatible aircraft here.
                    </div>;
  }
  else if(bfH.isWater(game, zoneID)) {
    canLandString = <div style={{marginBottom: '3px', marginTop: '3px'}}>
                      You cannot land aircraft here since<br />
                      you have no carriers in this zone.
                    </div>;
  }
  else {
    canLandString = <div style={{marginBottom: '3px', marginTop: '3px'}}>
                      You cannot land aircraft here since<br />
                      you do not control this zone.
                    </div>;
  }

  return (
    <Step.Group onPointerDown={ stopPropagation }>
      <Step>
        <Step.Content>
          <Step.Title>{ game.zoneName[zoneID] }</Step.Title>
          <Step.Description>
            <Flag name={ bfH.zoneOwnerFlag(game, zoneID) } />
            { bfH.zoneOwnerName(game, zoneID) ? bfH.zoneOwnerName(game, zoneID) : <br /> }
            <br />
            { canLandString }
            You have { landingTotal } aircraft set to land here.<br />
            { landingLine }
            ----------------------------------------
            <div>
              <Button key='close' onPointerUp={ hideLandingWindow } size='medium'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Close
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
            </div>
          </Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}
