/*
  GuiForceRetreatWindow.js
  (c) Human Cube Inc.
*/

import React from 'react';
import actions from '../../reducers/actions';
import { Flag } from 'semantic-ui-react';
import { Button, Step } from 'semantic-ui-react';
import { store } from '../../app/store';
import GuiForce from './GuiForce';
import BF from '../../bfcore/bfconst1';
import bfH from '../../bfcore/bf_helper.js';

function GuiForceRetreatLine (props) {
  const { count, forceTypeIndex, game, localPlayerIndex, retreatZoneID, transportCargoKey,
          zoneID } = props;

  function retreatOne (e) {
    store.dispatch({
      type: actions.MOVE_SOME_FORCES,
      game: game,
      forceTypeIndex,
      range: 0,
      transportCargoKey,
      count: 1,
      playerIndex: localPlayerIndex,
      zoneIDFrom: zoneID,
      zoneIDTo: retreatZoneID,
      isRetreat: true,
    });
  }

  const cargo = [];
  if(transportCargoKey) {
    const cargoParts = transportCargoKey.split(':');
    for(let i = 3; i < cargoParts.length; i++) {
      if(cargoParts[i].length > 0) {
        cargo.push(
          <GuiForce key={ 'c' + i } forceType={ game.forceID[parseInt(cargoParts[i])] } half />
        );
      }
    }
  }

  return (
    <div className='flex flex-row'>
      <div className='mt-1'>
        <b>{ count }</b>&nbsp;&nbsp;
      </div>
      <GuiForce forceType={ game.forceID[forceTypeIndex] } />
      { cargo }
      &nbsp;&nbsp;&nbsp;
      <div className='mb-1'>
        <Button key='retreatOne' onPointerUp={ retreatOne } size='small' compact>
          { 'to ' + game.zoneName[retreatZoneID] }
        </Button>
      </div>
    </div>
  );
}

export default function GuiForceRetreatWindow (props) {
  const { game, localPlayerIndex, working, zoneID } = props;
  const force = working.force;

  function stopPropagation (e) {
    e.stopPropagation();
  }

  function hideForceRetreatWindow (e) {
    store.dispatch({ type: actions.HIDE_FORCE_RETREAT_WINDOW });
  }

  const retreatableForceCounts = {};  // Indexed by 'forceType|retreatZone|transportCargoKey'
  for(let f = 0; f < force.length; f++) {
    const forceType = force[f].ft;
    if(game.forceCargoCapacity[forceType] === 0) {  // Non-Transports, as they are handled later.
      if(force[f].z === zoneID && force[f].owner === localPlayerIndex && force[f].zr) {
        // transportCargoKey is:  transportForceTypeIndex:range:cargotypeindex1:cargotypeindex2 ...
        const transportCargoKey = '';
        const key = forceType + '|' + force[f].zr + '|' + transportCargoKey;
        if(!retreatableForceCounts[key]) {
          retreatableForceCounts[key] = 0;
        }
        retreatableForceCounts[key]++;
      }
    }
  }
  for(let f = 0; f < force.length; f++) {
    const forceType = force[f].ft;
    if(game.forceCargoCapacity[forceType] > 0) {  // Only Transports.
      if(force[f].z === zoneID && force[f].owner === localPlayerIndex && force[f].zr) {
        const cargo = [];
        const cargoZoneID = BF.FORCE_ZONE_CARGO - force[f].id;
        for(let c = 0; c < force.length; c++) {
          if(force[c].z === cargoZoneID) {
            cargo.push(force[c].ft);
          }
        }
        // transportCargoKey is:  transportForceTypeIndex:range:cargotypeindex1:cargotypeindex2 ...
        const transportCargoKey = forceType + ':' + force[f].r + ':' + force[f].lp + ':' + cargo.sort().join(':');
        const key = forceType + '|' + force[f].zr + '|' + transportCargoKey;
        if(!retreatableForceCounts[key]) {
          retreatableForceCounts[key] = 0;
        }
        retreatableForceCounts[key]++;
      }
    }
  }

  // TODO low: could sort retreatableForceCounts here by forceType.

  let retreatLine = [];
  for(const key in retreatableForceCounts) {
    const f = key.split('|');
    retreatLine.push(
      <GuiForceRetreatLine key={ 'f' + key }
                           forceTypeIndex={ parseInt(f[0], 10) }
                           retreatZoneID={ parseInt(f[1], 10) }
                           transportCargoKey={ f[2] }
                           count={ retreatableForceCounts[key] }
                           game={ game }
                           zoneID={ zoneID } localPlayerIndex={ localPlayerIndex }
      />
    );
  }

  if(retreatLine.length === 0) {
    retreatLine = <div>
                    You do not have any forces that<br />
                    can retreat from this zone.
                  </div>;
  }

  return (
    <Step.Group onPointerDown={ stopPropagation }>
      <Step>
        <Step.Content>
          <Step.Title><small>Retreat From:</small></Step.Title>
          <Step.Title>{ game.zoneName[zoneID] }</Step.Title>
          <Step.Description>
            <Flag name={ bfH.zoneOwnerFlag(game, zoneID) } />
            {bfH.zoneOwnerName(game, zoneID)}<br />
            { retreatLine }
            ----------------------------------------
            <div>
              <Button key='close' onPointerUp={ hideForceRetreatWindow } size='medium'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Close
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
            </div>
          </Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}
