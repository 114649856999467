import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

export default class GuiModal extends React.Component {
  state = {
    props: {
      title: '',
      body: '',
      cancelText: '',
      cancelCallback: null,
      okCallback: null,
      okText: '',
    }
  };

  constructor(props) {
    super(props);  // Always call the parent class' constructor.
    this.state.props = props;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      props: nextProps,
    });
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  cancelClose = (e) => {
    e.stopPropagation();
    if( this.state.props.cancelCallback ) {
      this.state.props.cancelCallback();
    }
  };

  okClose = (e) => {
    e.stopPropagation();
    if( this.state.props.okCallback ) {
      this.state.props.okCallback();
    }
  };

  render() {
    return (
      <Modal
          closeOnDimmerClick={ false }
          closeOnEscape={ false }
          dimmer='inverted'
          open={ true }
          size='small'>
        <Modal.Content>
          <Modal.Description>
            { this.state.props.title &&
              <p>
                <b>{ this.state.props.title }</b>
              </p>
            }
            { this.state.props.body &&
              <p>
                { this.state.props.body }
              </p>
            }
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ this.cancelClose } positive >
            { this.state.props.cancelText ? this.state.props.cancelText : 'Cancel' }
          </Button>
          <Button
            onClick={ this.okClose }
            negative
            content={ this.state.props.okText ? this.state.props.okText : 'OK' }
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
