/*
  BaseMenuBasic.js
  (c) Human Cube Inc.
*/

import { Menu } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';


export default function BaseMenuBasic ({ pageName, leftText }) {
  const navigate = useNavigate();
  return (
      <Menu fixed='top' fluid widths={ 3 } inverted style={{ opacity: 0.666 }} >
      <Menu.Item position='left' name='BACK' onPointerUp={() => { navigate(-1) } } />
        <Menu.Item position='right' name={ pageName } />
        <Menu.Item position='right' name={ leftText } />
      </Menu>
  );
}
