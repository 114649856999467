import React from 'react';
import { Segment, Table } from 'semantic-ui-react';
import GuiForceTypeRow from './GuiForceTypeRow';
import GuiForceTypeDetail from './GuiForceTypeDetail';


export default class GuiForceTypes extends React.Component {

  state = {
    close: null,
    store: {},
    selectedForceTypeIndex: -1,
  };

  constructor(props) {
    super(props);  // Call the parent class' constructor.

    this.state.close = props.close;
    this.state.store = props.store;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.store !== this.state.store ||
        nextProps.close !== this.state.close) {
      this.setState({
        close: nextProps.close,
        game: nextProps.game,
      });
    }
  }

  hideForceTypes = (e) => {
    e.stopPropagation();
    this.setState({
      selectedForceTypeIndex: -1,
    });
    if(this.state.close) {
      this.state.close();
    }
  };

  selectForceType = (forceTypeIndex) => {
    this.setState({
      selectedForceTypeIndex: forceTypeIndex,
    });
  };

  hideForceType = (e) => {
    e.stopPropagation();
    this.setState({
      selectedForceTypeIndex: -1,
    });
  };

  render () {
    const { selectedForceTypeIndex } = this.state;
    const { gameID, game } = this.state.store;
    if(game._id !== gameID) {
      return null;
    }

    if(selectedForceTypeIndex >= 0) {
      return (
        <Segment compact inverted onPointerDown={ this.hideForceType }
            style={{ position: 'absolute', top: '30px', left: '30px', opacity: '90%' }}>
          <GuiForceTypeDetail
            key={ 'ftd' + selectedForceTypeIndex }
            forceTypeIndex={ selectedForceTypeIndex }
            game={ game }
          />
        </Segment>
      );
    }

    const forceTypeRows = [];
    for(let f = 0; f < game.forceID.length; f++) {
      forceTypeRows.push(
        <GuiForceTypeRow
          key={ 'ft' + f }
          forceTypeIndex={ f }
          game={ game }
          select={ this.selectForceType }
        />
      );
    }

    return (
      <Segment compact inverted onPointerDown={ this.hideForceTypes }
          style={{ position: 'absolute', top: '30px', left: '30px', opacity: '90%' }}>
        <Table inverted attached='top' basic verticalAlign='top'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign='center'>Force Type</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'></Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Cost</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Range</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Attack</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Defend</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Cargo</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>Landing</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { forceTypeRows }
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}
