import React from "react";
import { Sprite } from "@pixi/react";
import * as PIXI from "pixi.js";

const centerAnchor = new PIXI.Point(0, 0);

export default function ZoneSprite(props) {
  return (
    <Sprite
      x={ props.x }
      y={ props.y }
      anchor={ centerAnchor }
      texture={ PIXI.Texture.from(props.canvas) }
      tint={ props.tint }
      scale={ new PIXI.Point(props.xScale, props.yScale) }
    />
  );
}
