/*
  BaseGameDetailAutoPlay.js
  (c) Human Cube Inc.
*/

import React from 'react';
import { Button, Checkbox, Input, Modal, Segment } from 'semantic-ui-react';

import { sendPacket } from '../../network/network';


export default class BaseGameDetailAutoPlay extends React.Component {

  state = {
    localPlayerIndex: 0,
    game: {},
    // The follow state values are calculated from the above two props:
    autoPlayOpen: false,
    autoPlayOn: false,
    autoPlaySpend: 100,
    autoPlayMove: 75,
    originalAutoPlayOn: false,
    originalAutoPlaySpend: 100,
    originalAutoPlayMove: 75,
  };

  calculateStateFromProps(props) {
    const newState = {
      localPlayerIndex: 0,
      game: props.game,

      autoPlayOpen: false,
      autoPlayOn: false,
      autoPlaySpend: 100,
      autoPlayMove: 75,
      originalAutoPlayOn: false,
      originalAutoPlaySpend: 100,
      originalAutoPlayMove: 75,
    };

    const { game, localPlayerIndex } = props;
    newState.localPlayerIndex = localPlayerIndex;

    if(game._id && localPlayerIndex) {
      const { autoPlay, autoSpend, autoMove } = game;

      newState.autoPlayOn = false;
      if(autoPlay & (1 << localPlayerIndex)) {
        newState.autoPlayOn = true;
      }
      newState.autoPlaySpend = autoSpend[localPlayerIndex];
      newState.autoPlayMove = autoMove[localPlayerIndex];

      newState.originalAutoPlayOn = newState.autoPlayOn;
      newState.originalAutoPlaySpend = newState.autoPlaySpend;
      newState.originalAutoPlayMove = newState.autoPlayMove;
    }
    return newState;
  }

  constructor(props) {
    super(props);  // Always call the parent class' constructor.
    this.state = this.calculateStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.game !== this.state.game ||
        nextProps.localPlayerIndex !== this.state.localPlayerIndex) {
      this.setState(this.calculateStateFromProps(nextProps));
    }
  }

  autoPlayShow = () => {this.setState({ autoPlayOpen: true })};

  autoPlayUpdateClose = () => {
    sendPacket('bf_in', {
      e: 'setAutoPlay',
      gameID: this.state.game._id,
      allow: this.state.autoPlayOn ? 1 : 0,
      spend: this.state.autoPlaySpend,
      move: this.state.autoPlayMove,
    });
    this.setState((prevState) => ({
      autoPlayOpen: false,
      // Revert to original values until the server sends a back a success on the set request:
      autoPlayOn: prevState.originalAutoPlayOn,
      autoPlaySpend: prevState.originalAutoPlaySpend,
      autoPlayMove: prevState.originalAutoPlayMove,
    }));
  };

  autoPlayClose = () => {
    // Revert to original values and close popup.
    this.setState((prevState) => ({
      autoPlayOpen: false,
      autoPlayOn: prevState.originalAutoPlayOn,
      autoPlaySpend: prevState.originalAutoPlaySpend,
      autoPlayMove: prevState.originalAutoPlayMove,
    }));
  }

  handleAutoPlayOnToggle = () => {
    this.setState((prevState) => ({ autoPlayOn: !prevState.autoPlayOn }));
  };

  handleAutoPlaySpendChange = (e, { value }) => {
    this.setState({ autoPlaySpend: value });
  };

  handleAutoPlayMoveChange = (e, { value }) => {
    this.setState({ autoPlayMove: value });
  };

  render() {
    const { autoPlayOpen, autoPlayOn, autoPlaySpend, autoPlayMove,
            localPlayerIndex, originalAutoPlayOn } = this.state;
    if(!localPlayerIndex) {
      return null;
    }
    if(!autoPlayOpen) {
      return <Button size='medium' color='grey' onClick={ this.autoPlayShow }>
               Auto-Play Settings (
               { originalAutoPlayOn ? <b style={{color: 'red'}}>ON</b> : 'OFF' })
             </Button>;
    }
    return (
      <Modal
          closeOnDimmerClick={ false }
          closeOnEscape={ false }
          dimmer='inverted'
          open={ autoPlayOpen }
          size='small'>
        <Modal.Content>
          <Modal.Description>
            <p>
              <b>Your Auto-Play Settings For This Game</b>
            </p>
            <p>
              Auto-Play can be enabled if you are unable to complete your turn in time.<br />
              If enabled the server will automatically generated a simple turn for your next and all following turns.<br />
              Adjust the spend and move settings to control the general goal of the generated turns.
            </p>
            <p>
              If you miss a turn, Auto-Play will be automatically enabled for the following turns.
            </p>
            <p>
              To submit your own turn, be certain to disable Auto-Play.
            </p>
            <Segment compact>
              <span>
                Auto-Play&nbsp;&nbsp;&nbsp;
                <Checkbox toggle
                          onChange={ this.handleAutoPlayOnToggle }
                          checked={ autoPlayOn } />
                &nbsp;&nbsp;&nbsp;{ autoPlayOn ? 'Enabled' : 'Disabled' }
              </span>
              <br />
              <span>
                <Input
                  min={ 0 }
                  max={ 100 }
                  onChange={ this.handleAutoPlaySpendChange }
                  type='range'
                  value={ autoPlaySpend }
                />
                &nbsp;&nbsp;&nbsp;{ autoPlaySpend + ' %'  }&nbsp;&nbsp;&nbsp;Spend
              </span>
              <br />
              <span>
                <Input
                  min={ 0 }
                  max={ 100 }
                  onChange={ this.handleAutoPlayMoveChange }
                  type='range'
                  value={ autoPlayMove }
                />
                &nbsp;&nbsp;&nbsp;{ autoPlayMove + ' %' }&nbsp;&nbsp;&nbsp;Move
              </span>
            </Segment>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={ this.autoPlayClose } negative>
            Cancel
          </Button>
          <Button
            onClick={ this.autoPlayUpdateClose }
            positive
            labelPosition='right'
            icon='checkmark'
            content='Save Auto-Play Settings'
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
